import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BidderDetail } from '@project-shared/model/bidder/bidder.model';
import { Observable } from 'rxjs';
import { BidderService } from '../services/bidder.service';

@Injectable()
export class RejectedBiddersResolver {
  constructor(private bidderService: BidderService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<BidderDetail[]> {
    return this.bidderService.getRejectedAndSignedOffBidders(route.params.tenderUid);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ClientAddress } from '@olmero/shared-core';
import { Observable } from 'rxjs';
import { ClientService } from '../services/client.service';

@Injectable()
export class BidderAddressResolver {
  constructor(private clientService: ClientService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ClientAddress> {
    return this.clientService.getClientContactAddress(route.params.bidderUid);
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class TimeInterpretationService {

  private static padTimePart(timePart: number): string {
    return ('00' + timePart).slice(-2);
  }

  interpretInput(input: string): string {
    const possibleHour = input.match(/\d{1,2}/);
    if (possibleHour === null || possibleHour.length === 0) {
      return this.calculateTime();
    }
    let indexOfHour = input.indexOf(possibleHour[0]) + 1;
    if (possibleHour[0].length === 2) {
      indexOfHour++;
    }

    const partTwo = input.substring(indexOfHour, input.length);
    const possibleMinute = partTwo.match(/\d{1,2}/);
    if (possibleMinute === null || possibleMinute.length === 0) {
      return this.calculateTime(parseInt(possibleHour[0], 10));
    }

    return this.calculateTime(parseInt(possibleHour[0], 10), parseInt(possibleMinute[0], 10));
  }

  private calculateTime(hour?: number, minute?: number): string {
    hour = hour || 0;
    minute = minute || 0;

    if (hour > 24) {
      hour = 0;
    }
    if (minute > 60) {
      minute = 0;
    }

    return this.formatTime(hour, minute);
  }

  formatTime(hour: number, minute: number): string {
    return TimeInterpretationService.padTimePart(hour) + ':' + TimeInterpretationService.padTimePart(minute);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { OlmTextModule } from '@olmero/shared-core';
import { FormErrorViewComponent } from './components/form-error-view/form-error-view.component';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { FormComponent } from './components/form/form.component';
import { FormActionsDirective } from './directives/form-actions/form-actions.directive';
import { FormContentDirective } from './directives/form-content/form-content.directive';
import { FormSectionDirective } from './directives/form-section/form-section.directive';
import { DateConfig } from './services/date/date-config.service';
import { DateInterpretationService } from './services/date/date-interpretation.service';
import { TimeInterpretationService } from './services/date/time-interpretation.service';
import { FormAdditionalContentDirective } from './directives/form-additonal-content/form-additional-content.directive';
import { DateTimePickerComponent } from '@project-shared/modules/forms/components/datetime-picker/date-time-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OlmTestClassModule, PipesModule, TimeInputComponent } from '@olmero/shared-ui';
import { StickySidebarModule } from '../sticky-sidebar/sticky-sidebar.module';
import { FloatActionBarComponent } from './components/form/float-action-bar/float-action-bar.component';
import { FormErrorIndicatorComponent } from './components/form/form-error-indicator/form-error-indicator.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';

@NgModule({
  imports: [
    CommonModule,
    OlmTextModule,
    FlexModule,
    StickySidebarModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    OlmTestClassModule,
    TimeInputComponent,
    PipesModule,
    DatepickerComponent,
    DateTimePickerComponent,
  ],
  declarations: [
    FormComponent,
    FormErrorViewComponent,
    FormActionsDirective,
    FormContentDirective,
    FormSectionDirective,
    FormLabelComponent,
    FormAdditionalContentDirective,
    FloatActionBarComponent,
    FormErrorIndicatorComponent,
  ],
  exports: [
    FormComponent,
    FormErrorViewComponent,
    FormActionsDirective,
    FormContentDirective,
    FormSectionDirective,
    FormLabelComponent,
    FormAdditionalContentDirective,
  ],
  providers: [
    DateInterpretationService,
    TimeInterpretationService,
    DateConfig,
    {
      provide: 'TimeInterpretationService',
      useClass: TimeInterpretationService,
    },
  ],
})
export class OlmFormsModule {
}

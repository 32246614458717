import moment, { Moment } from 'moment';

export interface TenderUnavailableApprovalReasonDto {
  date_time: Moment;
  translation_key: string;
}

export class TenderUnavailableApprovalReason implements TenderUnavailableApprovalReasonDto {
  date_time: Moment;
  translation_key: string;

  constructor(json?: TenderUnavailableApprovalReasonDto) {
    if (json) {
      Object.assign(this, json);
    }

    if (this.date_time) {
      this.date_time = moment(this.date_time);
    }
  }
}

import { NgModule } from '@angular/core';
import { OlmTooltipDirective } from './olm-tooltip.directive';
import { OlmTextModule } from '@olmero/shared-core';
import { OlmTooltipComponent } from './olm-tooltip.component';
import { OlmWarningComponent } from './olm-warning/olm-warning.component';
import { OlmWarningAnchorComponent } from './olm-warning/olm-warning-anchor/olm-warning-anchor.component';

@NgModule({
  declarations: [
    OlmTooltipDirective,
    OlmTooltipComponent,
    OlmWarningComponent,
    OlmWarningAnchorComponent,
  ],
  imports: [
    OlmTextModule,
  ],
  exports: [
    OlmTooltipDirective,
  ],
  providers: [],
})
export class OlmTooltipModule {
}

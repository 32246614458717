import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { default as moment } from 'moment';

@Injectable()
export class DateConfig {
  getDate(): Moment {
    return moment();
  }
}

import { default as moment, Moment } from 'moment';
import { LinkDto } from '@olmero/shared-core';
import { BidderIssuer, BidderIssuerDto } from '../../../bidder/model/bidder-issuer.model';
import { BidderProject, BidderProjectDto } from '../../../bidder/model/bidder-project.model';
import { Offer } from '../offer/offer.model';
import { BidderTenderApplicationState } from './bidder-tender-application-state.enum';
import { TenderMode } from '../tender-config/tender-mode.enum';
import { TypeOfWork } from '@olmero/shared-ui';
import { BidderTenderState } from './bidder-tender-state.enum';
import { BidderTenderApplicationTabDto, BidderTenderDeregisterTabDto } from '../../../bidder/model/bidder-tender-tab.model';
import { AppConfig } from '@olmero/shared-core';
import { Bid } from '@project-shared/model/bidding-round/bid.model';
import { TenderContactDataDto } from '@project-shared/model/tender/deprecated-contact.model';
import { ContactPerson, ContactPersonDTO } from '@project-shared/model/contact-person.model';
import { ContactPersonMapper } from '@project-shared/services/contact-person-mapper.service';

export interface BidderTenderSubmission {
  input: boolean;
  department: string;
  street: string;
  zip: number;
  city: string;
  country: string;
  company_name: string;
}

export interface BidderTenderChanges {
  comment: string;
  changed_at: string;
}
export interface BidderTenderDto {
  uid: string;
  name: string;
  is_favorite: boolean;
  questions_allowed: boolean;
  submission_date: Moment;
  application_date: Moment;
  publication_date: Moment;
  feedback_date: Moment;
  offer_date: Moment;
  issuer: BidderIssuerDto;
  state_details: {
    state: BidderTenderState,
    changed_at: Moment,
  };
  project: BidderProjectDto;
  offers: Offer[];
  tender_kind: TenderMode;
  application?: {
    application_text: string,
    state?: BidderTenderApplicationState,
  };
  workstart: string;
  _links?: {
    apply?: LinkDto,
    will_offer?: LinkDto,
    withdraw_application?: LinkDto,
    signed_off?: LinkDto,
    deregister_information?: LinkDto,
    application_information?: LinkDto,
    create_offer?: LinkDto,
    offers?: LinkDto,
    latest_bid?: LinkDto,
    not_interested?: LinkDto,
    edit_bid?: LinkDto,
  };
  thumbnail?: Blob;
  contact_person: TenderContactDataDto | ContactPersonDTO;
  customer_reference_number?: string;
  types_of_work?: TypeOfWork[];
  awarded_by_date?: Moment;
  contacts?: TenderContactDataDto[] | ContactPersonDTO[];
  process?: string;
  phase?: string;
  submission?: BidderTenderSubmission;
  deregisterTab?: BidderTenderDeregisterTabDto;
  applicationTab?: BidderTenderApplicationTabDto;
  offersTab?: Offer[];
  latestBidTab?: Bid;
  id?: number;
  submission_date_expired?: boolean;
  has_draft_offer?: boolean;
  can_select?: boolean;
  is_closed?: boolean;
  external_offers?: boolean;
  new_tender?: boolean;
  tender_changed?: boolean;
  tender_changes?: BidderTenderChanges[];
}

export class BidderTender implements BidderTenderDto {
  uid: string;
  name: string;
  is_favorite: boolean;
  questions_allowed: boolean;
  submission_date: Moment;
  application_date: Moment;
  publication_date: Moment;
  feedback_date: Moment;
  offer_date: Moment;
  state_details: {
    state: BidderTenderState,
    changed_at: Moment,
  };
  issuer: BidderIssuer;
  project: BidderProject;
  offers: Offer[];
  tender_kind: TenderMode;
  application?: {
    application_text: string,
    state: BidderTenderApplicationState,
  };
  workstart: string;
  comment: string;
  _links?: {
    apply?: LinkDto,
    will_offer?: LinkDto,
    withdraw_application?: LinkDto,
    signed_off?: LinkDto,
    deregister_information?: LinkDto,
    application_information?: LinkDto,
    create_offer?: LinkDto,
    offers?: LinkDto,
    not_interested?: LinkDto,
    supplier?: LinkDto,
    create_bid?: LinkDto,
    edit_bid?: LinkDto,
    latest_bid?: LinkDto,
  };
  thumbnail?: Blob;
  opened?: boolean;
  contact_person: ContactPerson;
  customer_reference_number?: string;
  types_of_work?: TypeOfWork[];
  awarded_by_date?: Moment;
  contacts?: ContactPerson[];
  process?: string;
  phase?: string;
  submission?: BidderTenderSubmission;
  deregisterTab?: BidderTenderDeregisterTabDto;
  applicationTab?: BidderTenderApplicationTabDto;
  offersTab?: Offer[];
  latestBidTab?: Bid;
  id?: number;
  submission_date_expired?: boolean;
  has_draft_offer?: boolean;
  can_select?: boolean;
  type_of_work_supported?: boolean;
  is_closed?: boolean;
  external_offers?: boolean;
  new_tender?: boolean;
  tender_changed?: boolean;
  tender_changes?: BidderTenderChanges[];

  constructor(json?: BidderTenderDto) {
    if (json) {
      Object.assign(this, json);

      if (json.issuer) {
        this.issuer = new BidderIssuer(json.issuer);
      }

      if (json.project) {
        this.project = new BidderProject(json.project);
      }

      if (json.offers?.length) {
        json.offers.forEach(off => this.offers.push(new Offer(off)));
      }

      if (json.offersTab?.length) {
        this.offersTab = json.offersTab.map(offer => new Offer(offer));
      }

      if (json.submission_date) {
        this.submission_date = moment(this.submission_date);
      }

      if (json.application_date) {
        this.application_date = moment(this.application_date);
      }

      if (json.publication_date) {
        this.publication_date = moment(this.publication_date);
      }

      if (json.offer_date) {
        this.offer_date = moment(this.offer_date);
      }

      if (json.feedback_date) {
        this.feedback_date = moment(this.feedback_date);
      }

      if (json.contact_person) {
        this.contact_person = ContactPersonMapper.transform(json.contact_person);
      }

      if (json.contacts?.length){
        this.contacts = json.contacts.map(contact => ContactPersonMapper.transform(contact));
      }

      if (json.types_of_work?.length) {
        this.types_of_work = json.types_of_work.map(skill => {
          return new TypeOfWork(skill);
        });
      }

      if (json?.awarded_by_date) {
        this.awarded_by_date = moment(json.awarded_by_date, 'DD.MM.YYYY').isValid() ?
          moment(this.awarded_by_date, 'DD.MM.YYYY') :
          moment(this.awarded_by_date);
      }

      this.opened = false;
    }
  }

  getProjectDetails(): string{
    return `${this.project.zip} ${this.project.city}`;
  }

  getIssuerDetails(): string{
    return `${this.issuer.name ? this.issuer.name + ', ' : ''}
            ${this.issuer.department ? this.issuer.department + ', ' : ''}
            ${this.issuer.zip} ${this.issuer.city}`;
  }

  get latestOffer(): Offer | null {
    return this.offersTab?.length >= 1 ? this.offersTab[this.offersTab.length - 1] : null;
  }
}

export class ModifyHATEOASLinksInDevEnv {
  link: string;

  constructor(link: string, config: AppConfig) {
    const production = (window['tempConfigStorage'] as AppConfig).production;
    const replaceDocu = production ? link : link.replace(config.docuUrl, '');
    const replaceAp = production ? link : link.replace(config.apUrl, '');
    this.link = link.startsWith(config.docuUrl) ? replaceDocu : replaceAp;
  }

  getLink(): string {
    return this.link;
  }
}

export interface BuildingTypeDto {
  uid: string;
  label: string;
  children?: BuildingTypeDto[];
}

export class BuildingType implements BuildingTypeDto {
  public uid: string;
  public label: string;
  public children?: BuildingType[];

  constructor(data?: BuildingTypeDto) {
    if (data) {
      Object.assign(this, data);
      if (data.children) {
        this.children = [];
        data.children.forEach(value => this.children.push(new BuildingType(value)));
      }
    }
  }
}

import { Moment } from 'moment';

export enum BiddingRoundType {
  FULL_PRICE = 'full_price',
  BEST_PRICE = 'best_price',
  NO_PRICE = 'no_price'
}

const translationsForEnum = {
  full_price: 'bidding_round_preview_type_full_price',
  best_price: 'bidding_round_preview_type_best_price',
  no_price: 'bidding_round_preview_type_no_price',
};

export class BaseBiddingRound {
  uuid: string;
  type: BiddingRoundType;
  submission_deadline_expired: boolean;
  submission_deadline: Moment;
  created: Moment;

  getI18nTypeLabel(): string {
    return translationsForEnum[this.type];
  }
}

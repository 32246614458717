import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppConfigService } from '@olmero/shared-core';
import { HttpRequestHelper } from '@project-shared/helpers/http/request.helper';
import { FORCE_REQUEST_HEADER } from '@project-shared/http-interceptors/cache/cache.interceptor';
import { ApiOauthTokenService } from '@olmero/shared-core';
import { LanguageService } from '@olmero/shared-core';
import { Log, LogDto } from '@project-shared/model/log/log.model';
import { OpenTender, OpenTenderDto } from '@project-shared/model/tender/open-tender.model';
import { Tender, TenderDto } from '@project-shared/model/tender/tender.model';
import { BidderDetail, BidderDetailDto } from '@project-shared/model/bidder/bidder.model';
import { ListOptions } from '@project-shared/model/list-options.model';
import { ListDto } from '@project-shared/model/list.model';
import { Page } from '@project-shared/services/pagination/model/page.model';
import { PaginationService } from '@project-shared/services/pagination/pagination.service';
import { TenderMode } from '@project-shared/model/tender-config/tender-mode.enum';
import { ContactPersonMapper } from '@project-shared/services/contact-person-mapper.service';
import { TenderPreview, TenderPreviewDto } from '@project-shared/model/tender/tender-preview.model';

export interface TenderListOptions {
  sort?: Sort[];
  size?: number;
  page?: number;
  filter?: { key: string, value: boolean }[];
  onlyWithApplicants?: boolean;
  search?: string;
}

@Injectable({ providedIn: 'root' })
export class TenderService {
  static canDiscardTenderChanges$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private paginationService: PaginationService,
    private languageService: LanguageService,
    private tokenService: ApiOauthTokenService
  ) {
  }

  createTender(tender: Tender, projectUid: string): Observable<Tender> {
    // Remove this parse once BE has standardized the contact object
    const tenderCopy: any = { ...tender };
    const tenderPayload = this.revertContactTransformation(tenderCopy);

    return this.http.post<TenderDto>(`${this.appConfigService.getConfig().apiUrl}/projects/${projectUid}/tenders`,
                                     JSON.stringify(tenderPayload), HttpRequestHelper.getRequestOptionsJson())
      .pipe(
        map(data => new Tender(data))
      );
  }

  updateTender(tender: Tender): Observable<Tender> {
    if (tender['skills']) {
      delete tender['skills'];
    }

    // Remove this parse once BE has standardized the contact object
    const tenderCopy: any = { ...tender };
    const tenderPayload = this.revertContactTransformation(tenderCopy);

    return this.http.put<Tender>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tender.uid}`,
                                 JSON.stringify(tenderPayload), HttpRequestHelper.getRequestOptionsJson())
      .pipe(
        map(data => new Tender(data))
      );
  }

  private revertContactTransformation(tender: Tender): Tender {
    tender.contacts = tender.contacts
      .map(contact => ContactPersonMapper.transformIntoOldContact(contact)) as any[];

    return tender;
  }

  sortModes(modes: TenderMode[]): TenderMode[] {
    return modes.sort(this.sortIt);
  }

  getTenders(projectUid: string, listOptions?: TenderListOptions): Observable<TenderPreview[]> {
    let params: HttpParams = new HttpParams();
    if (listOptions?.sort && listOptions.sort.length) {
      listOptions.sort.forEach(prop => params = params.append('sort', `${prop.active},${prop.direction}`));
    }
    if (listOptions?.size) {
      params = params.set('size', listOptions.size.toString());
    }
    if (listOptions.search) {
      params = params.set('filter', listOptions.search);
    }
    if (listOptions.onlyWithApplicants) {
      params = params.set('only-with-applicants', 'true');
    }
    if (listOptions.filter && listOptions.filter.length > 0) {
      listOptions.filter.forEach(prop => {
        if (prop.value) {
          params = params.append('tender_state', prop.key);
        } else {
          params = params.delete('tender_state', prop.key);
        }
      });
    }

    return this.http.get<ListDto<TenderPreviewDto>>(`${this.appConfigService.getConfig().apiUrl}/v2/projects/${projectUid}/tenders`, { params })
      .pipe(
        map(data => data._embedded.content.map((item: TenderPreviewDto) => new TenderPreview(item)))
      );
  }

  getTender(tenderUid: string, headers?: HttpHeaders): Observable<Tender> {
    return this.http.get<TenderDto>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}`, { headers })
      .pipe(
        map(data => new Tender(data))
      );
  }

  fetchOfferExport(url: string): Promise<Response> {
    const fetchWithToken = (token): Promise<Response> => fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': `${this.languageService.getLocale().substr(0, 2)}`,
        'authorization': `Bearer ${token}`,
      },
    });

    return this.tokenService.getAccessToken()
      .then(token => {
        return fetchWithToken(token);
      });
  }

  getOpenTenders(projectUid: string, listOptions: ListOptions = { size: 20 }): Observable<Page<OpenTender>> {
    return this.paginationService
      .queryPaginated<OpenTenderDto>(`${this.appConfigService.getConfig().apiUrl}/projects/${projectUid}/open-tender-stats`, listOptions)
      .pipe(
        map(response => {
          response.results = response.results.map(openTender => new OpenTender(openTender));
          return response as Page<OpenTender>;
        }));
  }

  closeTender(tenderUid: string): Observable<Tender> {
    return this.http.post<void>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/complete`,
                                null,
                                HttpRequestHelper.getRequestOptionsJson())
      .pipe(
        mergeMap(() => this.getTender(tenderUid, new HttpHeaders(FORCE_REQUEST_HEADER)))
      );
  }

  deleteTender(tenderUid: string): Observable<any> {
    return this.http.delete<any>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}`, HttpRequestHelper.getRequestOptionsJson());
  }

  getOfferedBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.http.get<BidderDetailDto[]>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/bidders?filter=offered`)
      .pipe(
        map(response => response.map(value => new BidderDetail(value)))
      );
  }

  getLogs(tenderUid: string, listOptions?: ListOptions): Observable<Page<Log>> {
    return this.paginationService.queryPaginated<LogDto>(`${this.appConfigService.getConfig().apiUrl}/v2/tenders/${tenderUid}/logs`, listOptions)
      .pipe(
        map(response => {
          response.results = response.results.map(logDto => new Log(logDto));
          return response as Page<Log>;
        })
      );
  }

  list(projectUid: string, listOptions?: ListOptions, filterOptions?: { key: string, value: any }): Observable<Page<Tender>> {
    let params: HttpParams;

    if (filterOptions) {
      params = new HttpParams();
      params = this.setFilterOptionsParams(params, filterOptions);
    }

    return this.paginationService
      .queryPaginated<TenderDto>(`${this.appConfigService.getConfig().apiUrl}/v2/projects/${projectUid}/tenders`, listOptions, params)
      .pipe(map(response => {
        response.results = response.results.map(value => new Tender(value));
        return response as Page<Tender>;
      }));
  }

  private setFilterOptionsParams(params: HttpParams, filterOptions: { key: string, value: any }): HttpParams {
    params = params.set(filterOptions.key, filterOptions.value);
    return params;
  }

  private sortIt(a: TenderMode, b: TenderMode): number {
    if (a === TenderMode.OPEN && (b === TenderMode.SELECTIVE || b === TenderMode.INVITATION)) {
      return -1;
    } else if (a === TenderMode.SELECTIVE && b === TenderMode.INVITATION) {
      return -1;
    } else if (a === TenderMode.INVITATION && b === TenderMode.SELECTIVE) {
      return 1;
    } else if ((a === TenderMode.SELECTIVE || a === TenderMode.INVITATION) && b === TenderMode.OPEN) {
      return 1;
    }
    return 0;
  }

  static allowChangesDiscard(): void {
    this.canDiscardTenderChanges$.next(true);
  }

  static preventChangesDiscard(): void {
    this.canDiscardTenderChanges$.next(false);
  }

  public getAsXls(url: string): Observable<any> {
    return this.http.get(url, { headers: { 'Accept': 'application/octet-stream' }, responseType: 'blob', observe: 'response' });
  }

  public exportApproval(url: string): Observable<any> {
    return this.http.get(
      url, { headers: { 'Accept': 'application/pdf', ...FORCE_REQUEST_HEADER }, responseType: 'blob', observe: 'response' });
  }
}

import { Injectable } from '@angular/core';
import { ClientAddress } from '@olmero/shared-core';
import { Client } from '@olmero/shared-core';
import { ProjectDetails } from '@project-shared/model/project/project-details.model';
import { Member } from '../../model/member.model';
import { TextService } from '@olmero/shared-core';

@Injectable()
export class FormatterService {

  private textMap: Map<string, string> = new Map<string, string>();

  constructor(private textService: TextService) {
    this.textMap.set('h', this.textService.get('pp_core_list_filter_kind_h'));
    this.textMap.set('n', this.textService.get('pp_core_list_filter_kind_n'));
    this.textMap.set('date', this.textService.get('pp_core_global_format_date'));
    this.textMap.set('datetime', this.textService.get('pp_core_global_format_datetime'));
  }

  companyBreadcrumb(companyMasterData: Client, companyAddress: ClientAddress): string {
    return this.companyBreadcrumbCustom(companyAddress.company, companyAddress.department, companyAddress.city);
  }

  companyBreadcrumbCustom(company: string, department: string, city: string): string {
    let text = '';
    if (company !== null && company.length > 0) {
      text += company;
    }
    if (!!department && department.length > 0) {
      text += ', ' + department;
    }
    if (city !== null && city.length > 0) {
      text += ', ' + city;
    }
    return text;
  }

  companyTitle(companyMasterData: Client, companyAddress: ClientAddress): string {
    return this.companyTitleCustom(companyAddress.company, companyMasterData.details.kind,
                                   companyAddress.department, companyAddress.city);
  }

  companyTitleCustom(company: string, kind?: string, department?: string, city?: string): string {
    let text = '';
    if (!!company && company.length > 0) {
      text += company;
      if (!!kind && kind.toLowerCase() === 'h') {
        text += ' (' + this.textMap.get(kind.toLowerCase()) + ')';
      }
    }
    if (!!department && department.length > 0) {
      text += ', ' + department;
    }
    if (!!city && city.length > 0) {
      text += ', ' + city;
    }
    return text;
  }

  memberBreadcrumb(member: Member, external = false): string {
    let text = '';
    if (member.first_name !== null && member.first_name.length > 0) {
      text += member.first_name;
    }
    if (member.last_name !== null && member.last_name.length > 0) {
      if (text.length > 0) {
        text += ' ';
      }
      text += member.last_name;
    }
    if (external) {
      text += ' (' + this.textService.get('pp_core_member_external') + ')';
    }
    return text;
  }

  projectTitle(project: ProjectDetails): string {
    let text = '';
    if (!!project.number && project.number.length > 0) {
      text += project.number;
    }
    if (!!project.name && project.name.length > 0) {
      if (text.length > 0) {
        text += ': ';
      }
      text += project.name;
    }
    return text;
  }

  tenderTitle(tender: any): string {
    return this.tenderTitleCustom(tender['customer_reference_number'], tender['name']);
  }

  tenderTitleCustom(customReferenceNumber: string, name: string): string { //
    let text = '';
    if (!!customReferenceNumber && customReferenceNumber.length > 0) {
      text += customReferenceNumber;
    }
    if (!!name && name.length > 0) {
      if (text.length > 0) {
        text += ': ';
      }
      text += name;
    }
    return text;
  }

}

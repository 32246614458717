import { Moment } from 'moment';
import { default as moment } from 'moment-timezone';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable()
export class DateConverterService {

  static momentToEuropeZurichISOString(date: Moment): string {
    if (!date) {
      return '';
    }
    return moment(date).tz('Europe/Zurich').format('YYYY-MM-DDTHH:mm');
  }

  static luxonToEuropeZurichISOString(date: DateTime): string {
    if (!date) {
      return '';
    }
    return date.setZone('Europe/Zurich').toFormat('yyyy-MM-dd\'T\'HH:mm');
  }

  static momentToEuropeZurichDateString(date: Moment): string {
    if (!date) {
      return '';
    }
    return moment(date).tz('Europe/Zurich').format('YYYY-MM-DD');
  }

  static luxonToEuropeZurichDateString(date: DateTime): string {
    if (!date) {
      return '';
    }
    return date.setZone('Europe/Zurich').toFormat('yyyy-MM-dd');
  }
}

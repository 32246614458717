import { Injectable } from '@angular/core';
import { AppConfigService, FeatureFlagEnum } from '@olmero/shared-core';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {

  private readonly featureMap = new Map<FeatureFlagEnum, boolean>();

  constructor(private appConfigService: AppConfigService) {
    const featureFlags = this.appConfigService.getConfig()?.featureFlags;
    featureFlags?.forEach(featureFlag => this.featureMap.set(featureFlag.name, featureFlag.enabled));
  }

  isFeatureEnabled(feature: FeatureFlagEnum): boolean {
    return this.featureMap.get(feature);
  }


}


<div
  #olmTooltipWrapper
  class="olm-tooltip"
  [class.tooltip-inside-mat-menu]="insideMatMenu"
  [style.left.px]="leftPosition"
  [style.top.px]="parentTop"
  @tooltip
>
  {{ text }}
</div>

import { DateTime } from 'luxon';

export interface TenderPublicationDto {
  published: boolean;
  publication_scheduled_for: DateTime;
}

export class TenderPublication implements TenderPublicationDto{
  published: boolean;
  publication_scheduled_for: DateTime;

  constructor(data?: TenderPublicationDto) {
    if (data) {
      Object.assign(this, data);
      if (data.publication_scheduled_for) {
        this.publication_scheduled_for = DateTime.fromISO(data.publication_scheduled_for as any);
      }
    }
  }
}

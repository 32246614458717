import { ApiException } from './api-exception';
import { ConstraintViolationCause } from './constraint-violation/constraint-violation-cause';

export class ApiConstraintViolation extends ApiException {

  constructor(message: string, public readonly causes: ConstraintViolationCause[]) {
    super(message);
  }

}

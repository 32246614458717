export interface BidderIssuerDto {
  name: string;
  zip: string;
  city: string;
  department?: string;
  street?: string;
}
export class BidderIssuer implements BidderIssuerDto {
  name: string;
  zip: string;
  city: string;
  department?: string;
  street?: string;

  constructor(json?: BidderIssuerDto) {
    if (json) {
      Object.assign(this, json);
    }
  }

  getFormattedIssuerName(): string {
    return `${this.name} | ${this.zip} ${this.city}`;
  }
}

import { Component } from '@angular/core';
import { TextService } from '@olmero/shared-core';

@Component({
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent {

  private static HOTLINE = '0848 656 376';
  private static INTERNATIONAL_HOTLINE = '+41 44 200 44 96';
  private static MORNING_HOURS = '8.00 - 12.00';
  private static EVENING_HOURS = '13.00 - 17.00';

  constructor(private textService: TextService) {
  }

  getSupport(): string {
    return this.textService.get('pp_core_global_server_error_support', {
      hotline: `<span class="no-wrap">${ServerErrorComponent.HOTLINE}</span>`,
      international_hotline: `<span class="no-wrap">${ServerErrorComponent.INTERNATIONAL_HOTLINE}</span>`,
      morning_hours: `<span class="no-wrap">${ServerErrorComponent.MORNING_HOURS}</span>`,
      evening_hours: `<span class="no-wrap">${ServerErrorComponent.EVENING_HOURS}</span>`,
    });
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ClientAndParent } from '@project-shared/model/api.model';
import { Observable, of } from 'rxjs';
import { ClientService } from '../services/client.service';

@Injectable()
export class ClientAndParentResolver {
  constructor(private clientService: ClientService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ClientAndParent> {
    return route.params.clientUid ? this.clientService.getClientAndParent(route.params.clientUid) : of();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { DateConverterService } from '@project-shared/modules/locale/services/date-converter.service';
import { AppConfigService } from '@olmero/shared-core';
import { HttpRequestHelper } from '@project-shared/helpers/http/request.helper';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { FORCE_REQUEST } from '@project-shared/http-interceptors/cache/cache.interceptor';
import { Log, LogDto } from '@project-shared/model/log/log.model';
import { BidderFilter, PrivateBidder } from '@project-shared/model/bidder/bidder-filter.model';
import { BidderAction } from '@project-shared/model/bidder/bidder-action.enum';
import { BidderClient, BidderClientDto } from '@project-shared/model/bidder/bidder-client.model';
import { BidderStateParam } from '@project-shared/model/bidder/bidder-state-param.enum';
import { BidderDetail, BidderDetailDto } from '@project-shared/model/bidder/bidder.model';
import { RejectApplicationResult } from '@project-shared/model/bidder/reject-application-result.model';
import { ListOptions } from '@project-shared/model/list-options.model';
import { Page } from '@project-shared/services/pagination/model/page.model';
import { PaginationService } from '@project-shared/services/pagination/pagination.service';
import { DateTime } from 'luxon';
import { ClientService } from '@project-shared/modules/api/services/client.service';

@Injectable()
export class BidderService {

  private headers = { [FORCE_REQUEST]: '1' };

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private urlHelper: UrlHelper,
    private paginationService: PaginationService,
    private clientService: ClientService
  ) {
  }

  reject(bidder: BidderDetail, rejectResult: RejectApplicationResult): Observable<BidderDetail> {
    return this.bidderAction(bidder, BidderAction.REJECT, rejectResult);
  }

  rejectContractor(tenderUid: string, clientUid: string, rejectResult: {message: string | null}): Observable<BidderDetail> {
    return this.http.post(`${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders/${clientUid}/reject-offer`, rejectResult)
      .pipe(
        flatMap(() => this.getBidder(tenderUid, clientUid, new HttpHeaders(this.headers)))
      );
  }

  extendSubmissionDate(bidder: BidderDetail, submissionDate: DateTime): Observable<BidderDetail> {
    return this.bidderAction(bidder, BidderAction.EXTEND_SUBMISSION_DATE,
                             { extendedSubmissionDate: DateConverterService.luxonToEuropeZurichISOString(submissionDate) });
  }

  accept(bidder: BidderDetail): Observable<BidderDetail> {
    return this.bidderAction(bidder, BidderAction.ACCEPT);
  }

  reinvite(bidder: BidderDetail): Observable<BidderDetail> {
    return this.bidderAction(bidder, BidderAction.REINVITE);
  }

  withdrawnInvited(tenderUid: string, clientUid: string): Observable<any> {
    return this.http.delete<any>(`${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders/${clientUid}`);
  }

  deleteInspectionsDate(tenderUid: string, bidderUid: string): Observable<void> {
    return this.http.delete<void>(`${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders/${bidderUid}/delete-inspection-date`);
  }

  willOfferInvited(bidder: BidderDetail): Observable<BidderDetail> {
    return this.bidderAction(bidder, BidderAction.WILL_OFFER_INVITED);
  }

  willNotOffer(bidder: BidderDetail): Observable<BidderDetail> {
    return this.bidderAction(bidder, BidderAction.WILL_NOT_OFFER);
  }

  getInvitedAndWillOfferBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, [BidderStateParam.INVITED, BidderStateParam.WILL_OFFER])
      .pipe(
        map(response => response.sort(this.sortBidderByOfferDate()))
      );
  }

  getInvitedBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, [BidderStateParam.INVITED]);
  }

  getWillOfferBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, [BidderStateParam.WILL_OFFER]);
  }

  getOfferedBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, [
      BidderStateParam.OFFERED,
      BidderStateParam.BIDDING_ROUND,
      BidderStateParam.OFFER_REJECTED,
    ]);
  }

  getRejectedAndSignedOffBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, [BidderStateParam.SIGNED_OFF, BidderStateParam.REJECTED]);
  }

  getCandidateBidders(tenderUid: string): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, [BidderStateParam.APPLIED]);
  }

  getBiddersByState(tenderUid: string, bidderStates: any[]): Observable<BidderDetail[]> {
    return this.loadBidders(tenderUid, bidderStates);
  }

  getBidder(tenderUid: string, clientUid: string, headers?: HttpHeaders): Observable<BidderDetail> {
    return this.http.get<BidderDetailDto>(`${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders/${clientUid}`, { headers })
      .pipe(
        map(response => new BidderDetail(response))
      );
  }

  loadBidders(tenderUid: string, bidderStates?: any): Observable<BidderDetail[]> {
    let url = `${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders`;
    if (bidderStates && bidderStates.length) {
      url = this.urlHelper.putParams(url, { filter: bidderStates.join(',') });
    }

    return this.http.get<BidderDetailDto[]>(url)
      .pipe(
        map(response => response.map(value => new BidderDetail(value)))
      );
  }

  inviteBidders(tenderUid: string, bidderUids: { clientUid: string }[]): Observable<BidderDetail[]> {
    const url = `${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders-invited`;
    return this.http.post(url, bidderUids) as Observable<BidderDetail[]>;
  }

  updatePrivateBiddersSkills(tenderUid: string, clientUid: string, bidderUids: string[], skillUids: string[]): Observable<any> {
    const url = `${this.appConfig.getConfig().apiUrl}/clients/${clientUid}/private-clients/bulk-add-skills`;
    return this.http.post(url, { tenderUid, skillUids, privateClientUids: bidderUids });
  }

  savePrivateBidders(tenderUid: string, clientUid: string, list: PrivateBidder[], skillUids: string[]): Observable<string[]> {
    const url = `${this.appConfig.getConfig().apiUrl}/clients/${clientUid}/private-clients`;
    const body = JSON.stringify({ tenderUid, privateClients: list, skillUids });
    return this.http.post<string[]>(url, body, HttpRequestHelper.getRequestOptionsJson());
  }

  private bidderAction(bidder: BidderDetail, action: BidderAction, body?: any): Observable<BidderDetail> {
    return this.actionAndGet(bidder.tenderUid, bidder.client.uid, action, body);
  }

  private actionAndGet(tenderUid: string, clientUid: string, action: BidderAction, body?: any): Observable<BidderDetail> {
    return this.http.post(`${this.appConfig.getConfig().apiUrl}/tenders/${tenderUid}/bidders/${clientUid}/${action}`,
                          body || null,
                          HttpRequestHelper.getRequestOptionsJson())
      .pipe(
        flatMap(() => this.getBidder(tenderUid, clientUid, new HttpHeaders(this.headers)))
      );
  }

  private sortBidderByOfferDate() {
    return (a: BidderDetail, b: BidderDetail) => {
      const offerDateA: number = a.submissionDate ? a.submissionDate.valueOf() : 0;
      const offerDateB: number = b.submissionDate ? b.submissionDate.valueOf() : 0;
      if (offerDateA === offerDateB) {
        const nameA = a.client && a.client.name ? a.client.name : '';
        const nameB = b.client && b.client.name ? b.client.name : '';
        return nameA.localeCompare(nameB);
      } else {
        return offerDateA >= offerDateB ? -1 : 1;
      }
    };
  }

  getLogs(bidder: BidderDetail, listOptions?: ListOptions): Observable<Page<Log>> {
    return this.paginationService
      .queryPaginated<LogDto>(`${this.appConfig.getConfig().apiUrl}/v2/tenders/${bidder.tenderUid}/bidders/${bidder.client.uid}/logs`, listOptions)
      .pipe(
        map(response => {
          response.results = response.results.map(logDto => new Log(logDto));
          return response as Page<Log>;
        })
      );
  }

  getBidderClients(clientUid: string, body: BidderFilter, listOptions?: ListOptions): Observable<Page<BidderClient>> {
    return this.paginationService
      .queryPaginatedPost<BidderClientDto>(`${this.appConfig.getConfig().apiUrl}/v2/clients/${clientUid}/bidders`, body, listOptions)
      .pipe(map(bidderPage => {
        bidderPage.results = bidderPage.results.map(bidder => new BidderClient(bidder));
        return bidderPage as Page<BidderClient>;
      }));
  }

  favor(bidderClientUid: string): Observable<void> {
    return this.clientService
      .favorBidder(`${this.appConfig.getConfig().apiUrl}/client/${bidderClientUid}/favorite`);
  }

  unFavor(bidderClientUid: string): Observable<void> {
    return this.clientService
      .unFavorBidder(`${this.appConfig.getConfig().apiUrl}/client/${bidderClientUid}/favorite`);
  }
}

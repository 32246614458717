import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { TenderCreateData } from './model/tender-create.model';
import { ProjectAndClientResolver } from './project-and-client.resolver';
import { SkillsResolver } from './skills.resolver';

@Injectable()
export class TenderEditResolver {

  constructor(
    private projectAndClientResolver: ProjectAndClientResolver,
    private api: ApiService,
    private skillsResolver: SkillsResolver
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderCreateData> {
    return this.projectAndClientResolver.resolve(route)
      .pipe(
        mergeMap(projectAndClient => forkJoin(
          [
            this.skillsResolver.resolve(),
            this.api.getTenderConfig(projectAndClient.client.details.uid),
          ]
        )
          .pipe(
            map(result => new TenderCreateData(projectAndClient, result[1], result[0]))
          ))
      );
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientAndParent } from '@project-shared/model/api.model';
import {
  AppConfigService,
  Client,
  ClientAddress,
  ClientAddressDto,
  ClientDetails,
  ClientDetailsDto,
  ClientOption,
  ClientOptionDto, ClientOptionEnum,
  ClientTender,
  ClientTenderDto,
  CurrentUserService,
  Duplicate,
  EmailFax, LinkDto
} from '@olmero/shared-core';
import { PagedResponse } from '@project-shared/model/paged-response.model';
import { TenderTemplate, TenderTemplateDto } from '@project-shared/model/tender/tender-template.model';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpParameterEncoderHelper } from '@project-shared/helpers/http/parameter-encoder.helper';
import { FORCE_REQUEST } from '@project-shared/http-interceptors/cache/cache.interceptor';

@Injectable()
export class ClientService {

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService,
              private currentUserService: CurrentUserService) {
  }

  getClientTenderTemplate(clientUid: string): Observable<TenderTemplate[]> {
    return this.http.get<PagedResponse<TenderTemplateDto>>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/tender-templates`).pipe(
      map(response => response.content.map(tenderTemplate => new TenderTemplate(tenderTemplate)))
    );
  }

  getClientTenderTemplateForNewProject(clientUid: string): Observable<TenderTemplate[]> {
    return this.http.get<PagedResponse<TenderTemplateDto>>
    (`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/project-tender-templates`)
      .pipe(
        map(response => response.content.map(tenderTemplate => new TenderTemplate(tenderTemplate)))
      );
  }

  getClientAndParent(clientUid: string): Observable<ClientAndParent> {
    return forkJoin(
      this.getClient(clientUid),
      this.getClientContactAddress(clientUid)
    ).pipe(
      switchMap(result => {
        if (result[0].details.parent_uid != null) {
          return forkJoin(
            of(result),
            this.getClient(result[0].details.parent_uid),
            this.getClientContactAddress(result[0].details.parent_uid)
          ).pipe(
            map(parentResult => {
              const clientResult = parentResult[0];
              return {
                client: clientResult[0],
                clientContactAddress: clientResult[1],
                parent: parentResult[1],
                parentContactAddress: parentResult[2],
              };
            })
          );
        } else {
          return of({
            client: result[0],
            clientContactAddress: result[1],
          });
        }
      })
    );
  }

  getClient(clientUid: string): Observable<Client> {
    return this.http.get<Client>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}`)
      .pipe(
        map(result => new Client(result))
      );
  }

  getBranches(clientUid: string, canViewDisabled: boolean): Observable<Client[]> {
    const url = `${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/branches?size=9999${(!canViewDisabled ?
      '&filter-account-state[]=active' : '')}`;
    return this.http.get<PagedResponse<ClientDetailsDto>>(encodeURI(url)).pipe(
      map(response => {
        return response.content.map(details => new Client({ details }));
      })
    );
  }

  getClientAddressForUser(): Observable<ClientAddress> {
    return this.currentUserService.getCurrentUserData().pipe(
      switchMap(currentUserData => {
        return this.getClientContactAddress(currentUserData?.client_uid);
      })
    );
  }

  getClientContactAddress(clientUid: string): Observable<ClientAddress> {
    return this.internalFetchClientAddress(clientUid, 'contact', false);
  }

  getClientBillingAddress(clientUid: string): Observable<ClientAddress> {
    return this.internalFetchClientAddress(clientUid, 'invoice', true);
  }

  getClientClientOptions(clientUid: string): Observable<ClientOption[]> {
    return this.http.get<ClientOptionDto[]>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/client-options`)
      .pipe(
        map(response => response.map(data => new ClientOption(data)))
      );
  }

  getAssignableClients(clientUid: string): Observable<ClientDetails[]> {
    return this.http.get<PagedResponse<ClientDetailsDto>>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/assignable-clients`)
      .pipe(
        map(response => response.content.map(client => new ClientDetails(client)))
      );
  }

  getClientDuplicates(clientUid: string, medium: EmailFax): Observable<Duplicate> {
    let params: HttpParams = new HttpParams({ encoder: new HttpParameterEncoderHelper() });
    params = params.append(medium.key, medium.value);
    const headers = new HttpHeaders({ [FORCE_REQUEST]: '1' });

    return this.http.get<Duplicate>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/duplicate`, {
      params,
      headers,
    });
  }

  favorBidder(href: string): Observable<void> {
    return this.http.put<void>(href, undefined);
  }

  unFavorBidder(href: string): Observable<void> {
    return this.http.delete<void>(href);
  }

  addToBlackList(clientUid: string): Observable<void> {
    return this.http.put<void>(`${this.appConfigService.getConfig().apiUrl}/client/${clientUid}/blacklist`, undefined);
  }

  removeFromBlackList(clientUid: string): Observable<void> {
    return this.http.delete<void>(`${this.appConfigService.getConfig().apiUrl}/client/${clientUid}/blacklist`);
  }

  private internalFetchClientAddress(clientUid: string, type: string, nullable: boolean): Observable<ClientAddress> {
    return this.http.get<ClientAddressDto>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/${type}-address`).pipe(
      map(
        result => new ClientAddress(result)
      ),
      catchError(error => {
        if (nullable) {
          return of(null);
        }
        return throwError(error);
      })
    );
  }

  deleteClient(clientUid: string): Observable<void> {
    return this.http.put<void>
    (`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/state`, { state: 'deleted' });
  }

  getClientOpenTenders(clientUid: string): Observable<ClientTender[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('filter_open', 'true');

    return this.http.get<ClientTenderDto[]>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/tenders`, { params })
      .pipe(
        map(response => response.map(clientTender => new ClientTender(clientTender)))
      );
  }

  checkIfBidderIsInvolved(clientUid: string): Observable<ClientTender[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('filter_open', 'true');

    return this.http.get<ClientTenderDto[]>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/tenders`, { params })
      .pipe(
        map(response => response.map(clientTender => new ClientTender(clientTender)))
      );
  }

  createClientOption(clientUid: string, body: LinkDto): Observable<any> {
    return this.http.post<any>
    (`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/client-options`, body);
  }

  deleteClientOption(clientUid: string, clientOption: ClientOptionEnum): Observable<void> {
    return this.http.delete<void>
    (`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/client-options/${clientOption}`);
  }
}

export interface MemberAccessDto {
  can_edit: boolean;
  can_activate: boolean;
  can_disable: boolean;
  can_impersonate: boolean;
  can_generate_password: boolean;
  can_choose_password: boolean;
  can_view_client_participation: boolean;
}

export class MemberAccess implements MemberAccessDto {
  can_activate = false;
  can_choose_password = false;
  can_disable = false;
  can_edit = false;
  can_generate_password = false;
  can_impersonate = false;
  can_request_password_reset = false;
  can_edit_client_authorities = false;
  can_view_client_participation: boolean;

  constructor(data?: MemberAccessDto) {
    if (data) {
      Object.assign(this, data);
    }
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { default as urlParse } from 'url-parse';
import { AppConfigService } from '@olmero/shared-core';

@Injectable(
  { providedIn: 'root' }
)
export class UrlHelper {

  constructor(private appConfigService: AppConfigService) {
  }

  public putParams(url: string, params: { [key: string]: string }): string {
    const urlObj = urlParse(url, {}, true);
    urlObj.set('query', { ...urlObj.query, ...params });
    return urlObj.href;
  }

  public isApiUrl(url: string): boolean {
    if (url === 'assets/environment.json') {
      return false;
    }

    // check relative url
    if (url.startsWith(this.appConfigService.getConfig().apiUrl)) {
      return true;
    }
    // check full url
    const parsedUrl = urlParse(url);
    return this.appConfigService.getConfig().apiHost === parsedUrl.host && parsedUrl.pathname.startsWith(this.appConfigService.getConfig().apiUrl);
  }

  public isDocuUrl(url: string): boolean {
    return url.startsWith('/reference-project-details') || url.startsWith('https://docu-connector');
  }

  public setCurrentHostnameAndProtocol(url: string): string {
    return urlParse(url, {}, true)
      .set('protocol', window.location.protocol)
      .set('hostname', window.location.hostname)
      .href;
  }

  public calculateParentUrl(route: ActivatedRouteSnapshot): string {
    let url = '';
    let parent = route.parent;

    while (parent) {
      if (parent.url[0] && (route.routeConfig && route.routeConfig.path.length !== 0)) {
        url = `${parent.url[0].path}/${url}`;
      }
      parent = parent.parent || null;
      route = parent;
    }
    return url;
  }

}

import { ProjectGeometryLevel } from './project-geometry-level';

export class ProjectGeometrySubObject {
  label: string;
  description: string;
  levels: ProjectGeometryLevel[];
  private VISIBLE? = false;

  get visible(): boolean {
    return this.VISIBLE;
  }

  set visible(value: boolean) {
    this.VISIBLE = value;

    this.levels.forEach(lvl => {
      lvl.visible = value;
    });
  }


  constructor(label: string, description: string, levels: ProjectGeometryLevel[]) {
    this.label = label;
    this.description = description;
    this.levels = levels;
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToastType } from '../model/toast-type.enum';
import { ToastInfoComponent } from '@project-shared/modules/toast/toast-info/toast-info.component';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  public open(
    infoMessage: string,
    type: ToastType,
    closable = true,
    duration = 8000
  ): MatSnackBarRef<ToastInfoComponent> {
    return this.snackBar.openFromComponent(ToastInfoComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration,
      panelClass: [`snackbar-${type}`],
      data: {
        infoMessage,
        type,
        closable,
      },
    });
  }

  public openCustomToast(
    component: ComponentType<any>,
    duration = 8000
  ): MatSnackBarRef<ComponentType<any>> {
    return this.snackBar.openFromComponent(component, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration,
    });
  }
}

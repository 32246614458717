import { HttpHeaders } from '@angular/common/http';

export class HttpRequestHelper {

  public static getRequestHeadersJson(): HttpHeaders {
    return new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public static getRequestHeadersJsonPatch(): HttpHeaders {
    return new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json-patch+json' });
  }

  public static getRequestOptionsJson(): {headers: HttpHeaders} {
    return { headers: HttpRequestHelper.getRequestHeadersJson() };
  }

  public static getRequestOptionsJsonPatch(): {headers: HttpHeaders} {
    return { headers: HttpRequestHelper.getRequestHeadersJsonPatch() };
  }
}

<header id="app-frame-header" class="header">
    <div class="header-navigation">
        <olm-navigation tclass="test-navigation" *ngIf="apiRoot"></olm-navigation>
        <a routerLink="/" tclass="test-navigation-logo" class="button-ghost-narrow">
            <img
                class="img-responsive header-navigation-logo"
                alt="app-navbar-logo"
                id="app-navbar-logo"
                ngSrc="assets/logo/logo.svg"
                width="80"
                height="32"
            />
        </a>
    </div>
    <div class="header-buttons">
        <a
            tclass="test-register-link"
            *ngIf="apiRoot?.client_details?.is_private"
            class="button-header header-buttons-register-link"
            (click)="openRegisterPage()"
        >
            <p i18n="@@private_bidder_register_now">Register now</p>
            <span class="icon-external-link"></span>
        </a>
        <olm-language-switch></olm-language-switch>
        <div class="header-buttons-navigation">
            <olm-help-menu-item></olm-help-menu-item>
            <olm-profiles-dropdown
                tclass="test-profiles-dropdown"
                *ngIf="apiRoot"
                [apiRoot]="apiRoot">
            </olm-profiles-dropdown>
            <button
                *ngIf="apiRoot"
                tclass="test-logout"
                class="button-header"
                (click)="logout()"
            >
                <p>Logout</p>
                <span class="icon-logout"></span>
            </button>
        </div>
    </div>
</header>

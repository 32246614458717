import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequestHelper } from '@project-shared/helpers/http/request.helper';
import { Page } from '@project-shared/services/pagination/model/page.model';
import { PaginationService } from '@project-shared/services/pagination/pagination.service';
import { PagedResponse } from '@project-shared/model/paged-response.model';
import { ProjectDetails, ProjectDetailsDto } from '@project-shared/model/project/project-details.model';
import { TenderTemplate } from '@project-shared/model/tender/tender-template.model';
import { FileName, FileDetails } from '@project-shared/model/file.model';
import { ListOptions } from '@project-shared/model/list-options.model';
import { ProjectLog, ProjectLogDto } from '@project-shared/model/project/project-log.model';
import { AppConfigService } from '@olmero/shared-core';
import { Plan } from '../../issuer/+plans/models/plan.model';
import { ProjectGeometry, ProjectGeometryDto } from '../../issuer/project-geometry/model/project-geometry';
import { ContactPersonMapper } from '@project-shared/services/contact-person-mapper.service';

@Injectable()
export class ProjectService {
  private readonly ISSUER_PROJECT_TAB = 'issuer_projects_tab';
  private readonly ISSUER_PROJECT_PAGE = 'issuer_projects_page';

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private paginationService: PaginationService
  ) { }

  createProject(
    projectDetails: ProjectDetails,
    clientUid: string
  ): Observable<ProjectDetails> {
    // Remove this parse once BE has standardized the contact object
    const projectCopy: any = { ...projectDetails.toJSON() };
    const projectPayload = this.revertContactTransformation(projectCopy);

    return this.http
      .post<ProjectDetailsDto>(
      `${this.appConfigService.getConfig().apiUrl
      }/v2/clients/${clientUid}/projects`,
      projectPayload,
      HttpRequestHelper.getRequestOptionsJson()
    )
      .pipe(map(responseJSON => new ProjectDetails(responseJSON)));
  }

  updateProject(
    projectDetails: ProjectDetails,
    projectUid: string
  ): Observable<ProjectDetails> {
    // Remove this parse once BE has standardized the contact object
    const projectCopy: any = { ...projectDetails.toJSON() };
    const projectPayload = this.revertContactTransformation(projectCopy);

    return this.http
      .put<ProjectDetailsDto>(
      `${this.appConfigService.getConfig().apiUrl}/v2/projects/${projectUid}`,
      projectPayload,
      HttpRequestHelper.getRequestOptionsJson()
    )
      .pipe(map(responseJSON => new ProjectDetails(responseJSON)));
  }

  private revertContactTransformation(project: ProjectDetails): ProjectDetails {
    project.contacts = project.contacts
      .map(contact => ContactPersonMapper.transformIntoOldContact(contact)) as any[];

    return project;
  }

  getProject(projectUid: string): Observable<ProjectDetails> {
    return this.http
      .get<ProjectDetailsDto>(
      `${this.appConfigService.getConfig().apiUrl}/v2/projects/${projectUid}`
    )
      .pipe(map(responseJSON => new ProjectDetails(responseJSON)));
  }

  favorProject(projectUid: string): Observable<void> {
    return this.http.put<void>(
      `${this.appConfigService.getConfig().apiUrl
      }/v2/projects/${projectUid}/favorite`,
      {}
    );
  }

  unFavorProject(projectUid: string): Observable<void> {
    return this.http.delete<void>(
      `${this.appConfigService.getConfig().apiUrl
      }/v2/projects/${projectUid}/favorite`,
      {}
    );
  }

  getProjectTenderTemplates(projectUid: string): Observable<TenderTemplate[]> {
    return this.http
      .get<PagedResponse<TenderTemplate>>(
      this.appConfigService.getConfig().apiUrl +
        '/projects/' +
        projectUid +
        '/tender-templates'
    )
      .pipe(
        map(response =>
          response.content.map(
            tenderTemplate => new TenderTemplate(tenderTemplate)
          )
        )
      );
  }

  getLogs(
    projectUid: string,
    listOptions?: ListOptions
  ): Observable<Page<ProjectLog>> {
    return this.paginationService
      .queryPaginated<ProjectLogDto>(
      `${this.appConfigService.getConfig().apiUrl
      }/projects/${projectUid}/logs`,
      listOptions
    )
      .pipe(
        map(response => {
          response.results = response.results.map(
            logDto => new ProjectLog(logDto)
          );
          return response as Page<ProjectLog>;
        })
      );
  }

  validatePlanNumberingScheme(
    projectUid: string,
    fileName: FileName
  ): Observable<ValidationErrors> {
    return this.http.post(
      `${this.appConfigService.getConfig().apiUrl
      }/projects/${projectUid}/plan-numbering-scheme/validate`,
      fileName
    );
  }

  createProjectPlan(
    projectUid: string,
    fileDetails: FileDetails
  ): Observable<Plan> {
    return this.http
      .post<Plan>(
      `${this.appConfigService.getConfig().apiUrl
      }/projects/${projectUid}/plans`,
      fileDetails
    )
      .pipe(map(response => new Plan(response)));
  }

  getProjectGeometry(projectUid: string): Observable<ProjectGeometry> {
    return this.http
      .get<ProjectGeometryDto>(
      `${this.appConfigService.getConfig().apiUrl
      }/projects/${projectUid}/geometry`
    )
      .pipe(map(response => new ProjectGeometry(response)));
  }

  createProjectGeometry(
    projectUid: string,
    projectGeometry: ProjectGeometry
  ): Observable<ProjectGeometry> {
    return this.http
      .put<ProjectGeometryDto>(
      `${this.appConfigService.getConfig().apiUrl
      }/projects/${projectUid}/geometry`,
      projectGeometry
    )
      .pipe(map(response => new ProjectGeometry(response)));
  }

  uploadPicture(file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${this.appConfigService.getConfig().apiUrl}/projects/picture`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getPicture(pictureId: string, projectUid: string): Observable<Blob> {
    return this.http.get(
      `${this.appConfigService.getConfig().apiUrl
      }/projects/${projectUid}/picture/${pictureId}`,
      { responseType: 'blob' }
    );
  }

  setProjectsTab(tab: string): void {
    sessionStorage.setItem(this.ISSUER_PROJECT_TAB, tab);
  }

  clearProjectsTab(): void {
    sessionStorage.removeItem(this.ISSUER_PROJECT_TAB);
  }

  getProjectsTab(): number {
    return Number(sessionStorage.getItem(this.ISSUER_PROJECT_TAB));
  }

  setProjectsPage(page: string): void {
    sessionStorage.setItem(this.ISSUER_PROJECT_PAGE, page);
  }

  clearProjectsPage(): void {
    sessionStorage.removeItem(this.ISSUER_PROJECT_PAGE);
  }

  getProjectsPage(): number {
    return Number(sessionStorage.getItem(this.ISSUER_PROJECT_PAGE));
  }
}

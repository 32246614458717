import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TitleService {

  public title$: Observable<string>;
  private titleSubject = new ReplaySubject<string>(1);
  public showTitle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.title$ = this.titleSubject.asObservable();
  }

  setTitle(title: string): void {
    this.titleSubject.next(title);
  }

  showTitle(show: boolean): void {
    this.showTitle$.next(show);
  }
}

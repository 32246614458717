import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BlacklistService } from '../../services/blacklist.service';
import { takeUntil, map } from 'rxjs/operators';
import { BlacklistHandler } from '../../services/blacklist-handler';
import { TextService } from '@olmero/shared-core';

@Component({
  selector: 'olm-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss'],
})
export class BlacklistComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  public blacklisted$: Observable<BlacklistHandler>;
  public blacklistTooltip$: Observable<string>;


  constructor(private blacklistService: BlacklistService,
              private textService: TextService) {
    this.blacklisted$ = this.blacklistService.blacklist$;
  }

  ngOnInit(): void {
    this.blacklistTooltip$ = this.blacklistService.blacklist$.pipe(
      takeUntil(this.unsubscribe$),
      map(blacklist => blacklist.blacklistedTooltip ? blacklist.blacklistedTooltip : this.textService.get('favorite_tooltip_remove_as_favorite'))
    );
  }

}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class ScrollingService {

  private scrollingSubject: Subject<any>;

  constructor() {
    this.scrollingSubject = new Subject();
  }

  public scrolled(event: any): void {
    this.scrollingSubject.next(event);
  }

  public getScrollingEvents(): Observable<any> {
    return this.scrollingSubject.asObservable().pipe(share());
  }

}

import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateInterpretationService } from '../../services/date/date-interpretation.service';

@Directive({ selector: 'input[olmDateAutocomplete]', standalone: true })
export class DateAutocompleteDirective {

  @Input('useLuxon') useLuxon: boolean;

  constructor(
    private ngControl: NgControl,
    private dateInterpreter: DateInterpretationService) { }

  @HostListener('blur', ['$event'])
  onBlur(event): void {
    if (event.target.value) {
      const interpretedInput = this.dateInterpreter.interpretInput(event.target.value, this.useLuxon);
      this.ngControl.control.setValue(interpretedInput);
    }
  }
}

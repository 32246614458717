import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { ApiOauthTokenService } from '@olmero/shared-core';

@Injectable()
export class ApiOauthInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: ApiOauthTokenService,
    private urlHelper: UrlHelper
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.urlHelper.isApiUrl(req.url) && !this.urlHelper.isDocuUrl(req.url)) {
      // ignore non api requests
      return next.handle(req);
    }
    return this.addAuthToken(req)
      .pipe(
        switchMap(authorizedReq => next.handle(authorizedReq)),
        catchError(error => {
          return throwError(error);
        }));
  }
  private addAuthToken(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return from(this.tokenService.getAccessToken().then(token =>{
      return req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + token
        ),
      });
    }));

  }
}

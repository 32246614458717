import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { BlacklistHandler } from './blacklist-handler';

@Injectable({ providedIn: 'root' })
export class BlacklistService {

  public blacklist$: Observable<BlacklistHandler>;
  private blacklistSubject = new ReplaySubject<BlacklistHandler>(1);

  constructor() {
    this.blacklist$ = this.blacklistSubject.asObservable();
  }

  setBlacklist(handler: BlacklistHandler): void {
    this.blacklistSubject.next(handler);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureFlagDirective } from './directive/feature-flag.directive';
import { FeatureFlagGuard } from './guard/feature-flag.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FeatureFlagDirective,
  ],
  providers: [
    FeatureFlagGuard,
  ],
  exports: [
    FeatureFlagDirective,
  ],
})
export class FeatureFlagModule {

}

import { HttpParameterCodec } from '@angular/common/http';

export class HttpParameterEncoderHelper implements HttpParameterCodec {

  private static standardEncoding(value: string): string {

    // the php default encoding
    // @see http://php.net/manual/de/function.rawurlencode.php
    return encodeURIComponent(value)
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\*/g, '%2A');

    // the angular2 default encoding
    /*return encodeURIComponent(v)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/gi, '$')
      .replace(/%2C/gi, ',')
      .replace(/%3B/gi, ';')
      .replace(/%2B/gi, '+')
      .replace(/%3D/gi, ';')
      .replace(/%3F/gi, '?')
      .replace(/%2F/gi, '/'); */
  }

  encodeKey(key: string): string {
    return HttpParameterEncoderHelper.standardEncoding(key);
  }

  encodeValue(value: string): string {
    return HttpParameterEncoderHelper.standardEncoding(value);
  }

  decodeKey(key: string): string {
    return key;
  }

  decodeValue(value: string): string {
    return value;
  }

}

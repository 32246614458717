import { Component } from '@angular/core';
import { ToastType } from '@project-shared/modules/toast/model/toast-type.enum';
import { ToastService } from '@project-shared/modules/toast/services/toast.service';
import { AppConfigService } from '@olmero/shared-core';
import { LanguageService } from '@olmero/shared-core';

@Component({
  selector: 'olm-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent {
  constructor(private languageService: LanguageService, private toastService: ToastService, private appConfigService: AppConfigService) {
  }

  switchLanguage(langCode: string): void {
    if (this.appConfigService.getConfig().production) {
      this.languageService.switchToLocale(langCode);
    } else {
      this.toastService.open('Cannot switch language in dev environment!', ToastType.INFO);
    }
  }

  isLanguageActive(langCode: string): boolean {
    return this.languageService.getLocale().startsWith(langCode);
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrentUserService } from '@olmero/shared-core';
import { CurrentUserDataResolver } from './resolver/current-user-data.resolver';
import { ClientAddressResolver } from '@project-shared/modules/api/resolver/client-address.resolver';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    CurrentUserService,
    CurrentUserDataResolver,
    ClientAddressResolver,
  ],
})
export class OlmUserModule {}

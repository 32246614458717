import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Offers } from '@project-shared/model/offer/offers.model';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class OffersResolver {
  constructor(private api: ApiService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Offers> {
    if (route.data.showRejectedOffers) {
      return this.api.getOffersForTenderUid(route.params.tenderUid, '?rejected=true');
    } else {
      return this.api.getOffersForTenderUid(route.params.tenderUid, '');
    }
  }
}

import { TypeOfWork } from '@olmero/shared-ui';
import { DateConverterService } from '@project-shared/modules/locale/services/date-converter.service';
import { Deduction } from '@project-shared/model/deduction/deduction.model';
import { Attachment } from '../attachment/attachment.model';
import { TenderAccess, TenderAccessDto } from './tender-access.model';
import { TenderPublication, TenderPublicationDto } from './tender-publication.model';
import {
  TenderUnavailableApprovalReason,
  TenderUnavailableApprovalReasonDto
} from '@project-shared/model/tender/tender-unavailable-approval-reasons.model';
import { TenderState } from './tender-state.enum';
import { DateTime } from 'luxon';
import { ContactPerson, ContactPersonDTO } from '@project-shared/model/contact-person.model';
import { ContactDto } from '@project-shared/model/tender/deprecated-contact.model';
import { ContactPersonMapper } from '@project-shared/services/contact-person-mapper.service';

export interface TenderDto {
  uid: string;
  name: string;
  immediate_inspection_allowed: boolean;
  customer_reference_number: string;
  tender_kind: string;
  tender_state: TenderState;
  types_of_work: TypeOfWork[];
  phase: string;
  creation_date?: DateTime;
  estimated_budget?: number;
  submission_date: DateTime;
  application_date: DateTime;
  bidding_round_deadline_date: DateTime;
  number_of_bidding_rounds: number;
  feedback_date: DateTime;
  accepting_date: string;
  workstart: string;
  offer_date: DateTime;
  reminder_time: string;
  comment: string;
  questions_allowed: boolean;
  submission_input: boolean;
  submission_company: string;
  submission_department: string;
  submission_street: string;
  submission_zip: string;
  submission_city: string;
  submission_country: string;
  vat: number;
  discount: number;
  publication_date: DateTime;
  modification_date: DateTime;
  deductions: Deduction[];
  attachments: Attachment[];
  contacts: ContactDto[] | ContactPersonDTO[];
  access: TenderAccessDto;
  export_contractors_link?: string;
  export_all_link?: string;
  devis_link?: string;
  offer_data_visible?: boolean;
  number_of_applicants?: number;
  publication?: TenderPublicationDto;
  approval_unavailable_reasons?: TenderUnavailableApprovalReasonDto[];
  approval_created_date?: DateTime;
  completion_date?: DateTime;
  extended_submission_date?: DateTime;
  stats?: {
    number_of_applicants: number,
    number_of_invited: number,
    number_of_offers: number,
    number_of_unsubscribed: number,
    number_of_will_offer: number,
  };
  lowest_offer?: number;
  _links?: {
    create_work_description?: {
      href: string,
    },
    create_bidding_round?: {
      href: string,
    },
    create_approval?: {
      href: string,
    },
    create_unlicensed_work_description?: {
      href: string,
    },
  };
}

export class Tender implements TenderDto {
  uid: string;
  name: string;
  immediate_inspection_allowed: boolean;
  customer_reference_number: string;
  tender_kind: string;
  tender_state: TenderState;
  types_of_work: TypeOfWork[];
  phase: string;
  creation_date?: DateTime;
  estimated_budget: number;
  submission_date: DateTime;
  application_date: DateTime;
  feedback_date: DateTime;
  bidding_round_deadline_date: DateTime;
  number_of_bidding_rounds: number;
  accepting_date: string;
  workstart: string;
  offer_date: DateTime;
  reminder_time: string;
  comment: string;
  questions_allowed: boolean;
  submission_input: boolean;
  submission_company: string;
  submission_department: string;
  submission_street: string;
  submission_zip: string;
  submission_city: string;
  submission_country: string;
  vat: number;
  discount: number;
  publication_date: DateTime;
  modification_date: DateTime;
  deductions: Deduction[];
  attachments: Attachment[];
  contacts: ContactPerson[];
  access: TenderAccess;
  changeMessage?: string;
  offer_data_visible?: boolean;
  number_of_applicants?: number;
  publication?: TenderPublication;
  approval_unavailable_reasons?: TenderUnavailableApprovalReason[];
  approval_created_date?: DateTime;
  completion_date?: DateTime;
  extended_submission_date?: DateTime;
  stats?: {
    number_of_applicants: number,
    number_of_invited: number,
    number_of_offers: number,
    number_of_unsubscribed: number,
    number_of_will_offer: number,
  };
  lowest_offer?: number;
  _links?: {
    create_work_description?: {
      href: string,
    },
    create_bidding_round?: {
      href: string,
    },
    edit_tender?: {
      href: string,
    },
    create_approval?: {
      href: string,
    },
    get_approval?: {
      href: string,
    },
    delete_approval?: {
      href: string,
    },
    invite_contractors ?: {
      href: string,
    },
    export_total ?: {
      href: string,
    },
    export_contractors?: {
      href: string,
    },
    export_offers?: {
      href: string,
    },
    upload_work_description?: {
      href: string,
    },

  };

  constructor(json?: TenderDto) {
    if (json) {
      Object.assign(this, json);
      this.deserializePublication(json);
      this.deserializeDates(json);
      this.deserializeCollections(json);
      this.deserializeAccess(json);
      this.deserializeApprovalUnavailableReasons(json);
    }
  }

  toJSON(): any {
    const overrides = { publication: {}};
    if (this.submission_date) {
      overrides['submission_date'] = DateConverterService.luxonToEuropeZurichISOString(this.submission_date);
    }
    if (this.application_date) {
      overrides['application_date'] = DateConverterService.luxonToEuropeZurichISOString(this.application_date);
    }
    if (this.feedback_date) {
      overrides['feedback_date'] = DateConverterService.luxonToEuropeZurichISOString(this.feedback_date);
    }
    if (this.offer_date) {
      overrides['offer_date'] = DateConverterService.luxonToEuropeZurichISOString(this.offer_date);
    }
    if (this.publication_date) {
      overrides['publication_date'] = DateConverterService.luxonToEuropeZurichISOString(this.publication_date);
    }
    if (this.bidding_round_deadline_date && this.bidding_round_deadline_date) {
      overrides['bidding_round_deadline_date'] =
        DateConverterService.luxonToEuropeZurichISOString(this.bidding_round_deadline_date);
    }

    if (this.publication) {
      overrides['publication']['published'] = this.publication.published;
    }

    if (this.publication && this.publication.publication_scheduled_for) {
      overrides['publication']['publication_scheduled_for'] =
        DateConverterService.luxonToEuropeZurichISOString(this.publication.publication_scheduled_for);
    }

    return Object.assign({}, this, overrides);
  }

  private deserializePublication(json: TenderDto): void {
    if (json.publication) {
      this.publication = new TenderPublication(json.publication);
    }
  }

  private deserializeAccess(json: TenderDto): void {
    if (json.access) {
      this.access = new TenderAccess(json.access);
    }
  }

  private deserializeApprovalUnavailableReasons(json: TenderDto): void {
    if (json.approval_unavailable_reasons?.length > 0) {
      this.approval_unavailable_reasons = json.approval_unavailable_reasons.map(reason => {
        return new TenderUnavailableApprovalReason(reason);
      });
    }
  }

  private deserializeCollections(json: TenderDto): void {
    if (json.types_of_work && json.types_of_work.length) {
      this.types_of_work = json.types_of_work.map(skill => {
        return new TypeOfWork(skill);
      });
    }

    if (json.deductions && json.deductions.length) {
      this.deductions = json.deductions.map(deduction => {
        return new Deduction(deduction);
      });
    }

    if (json.contacts && json.contacts.length) {
      this.contacts = json.contacts.map(contact => {
        return ContactPersonMapper.transform(contact);
      });
    }

    if (json.attachments && json.attachments.length) {
      this.attachments = json.attachments.map(attachment => {
        return new Attachment(attachment);
      });
    }
  }

  private deserializeDates(json: TenderDto): void {
    if (json.submission_date) {
      this.submission_date = DateTime.fromISO(json.submission_date as any);
    }
    if (json.application_date) {
      this.application_date = DateTime.fromISO(json.application_date as any);
    }
    if (json.feedback_date) {
      this.feedback_date = DateTime.fromISO(json.feedback_date as any);
    }
    if (json.offer_date) {
      this.offer_date = DateTime.fromISO(json.offer_date as any);
    }
    if (json.publication_date) {
      this.publication_date = DateTime.fromISO(json.publication_date as any);
    }
    if (json.modification_date) {
      this.modification_date = DateTime.fromISO(json.modification_date as any);
    }
    if (json.bidding_round_deadline_date) {
      this.bidding_round_deadline_date = DateTime.fromISO(json.bidding_round_deadline_date as any);
    }
    if (this.approval_created_date) {
      this.approval_created_date = DateTime.fromISO(this.approval_created_date as any);
    }
    if (this.completion_date) {
      this.completion_date = DateTime.fromISO(this.completion_date as any);
    }
    if (this.extended_submission_date) {
      this.extended_submission_date = DateTime.fromISO(this.extended_submission_date as any);
    }
  }

  isTenderOpen(): boolean {
    return this.tender_state === TenderState.open;
  }

  isTenderExpired(): boolean {
    if (this.bidding_round_deadline_date){
      return this.bidding_round_deadline_date < DateTime.local();
    }
    if (this.submission_date){
      return this.submission_date < DateTime.local();
    }
    return false;
  }

  isTenderClosed(): boolean {
    return this.tender_state === TenderState.completed;
  }

  isTenderInDraft(): boolean {
    return this.tender_state === TenderState.draft || this.tender_state === TenderState.saved_to_cache;
  }

  /**
   * Determines if the user can see offers according to immediate_inspection_allowed.
   *
   * This method checks whether the user is eligible to view offers based on certain conditions,
   * When the immediate_inspection_allowed flag is true then show offers, else, it's needed to
   * Check if the submission date of the tender is already expired.
   *
   */
  get canSeeOffers(): boolean {
    if (this.immediate_inspection_allowed) {
      return true;
    }

    return this.submission_date < DateTime.local();
  }

  isInDraft(): boolean {
    return this.tender_state === 'saved_to_cache';
  }

  isInBiddingRound(): boolean {
    return this.bidding_round_deadline_date > DateTime.local();
  }

  isInApproval(): boolean {
    return this.tender_state === 'approval';
  }

  isOngoing(): boolean {
    return !this.isInDraft() && this.submission_date > DateTime.local() && !this.isInBiddingRound();
  }

  isExpired(): boolean {
    return !this.isOngoing() && !this.isInBiddingRound() && !this.isInDraft() && !this.isClosed() && !this.isInApproval();
  }

  isClosed(): boolean {
    return this.tender_state === 'completed';
  }
}

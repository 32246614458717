<div
  class="datepicker-wrapper"
  [class.disabled]="disabled">
  <input
    *ngIf="useAutocomplete"
    olmDateAutocomplete
    [useLuxon]="isLuxonDate"
    [(ngModel)]="value"
    (dateChange)="onTouched(); onChange(value); dateChanged.emit(value)"
    (blur)="onTouched(); onChange(value); onBlur.emit(value)"
    class="form-control view-form-form-control"
    type="text"
    [matDatepicker]="datepicker"
    placeholder="{{translatePlaceholder ? (placeholder | text) : placeholder}}"
    [disabled]="disabled"
    [min]="min"
    [max]="max"
  />
  <input
    *ngIf="!useAutocomplete"
    [(ngModel)]="value"
    (dateChange)="onTouched(); onChange(value); dateChanged.emit(value)"
    (blur)="onTouched(); onChange(value); onBlur.emit(value)"
    class="form-control view-form-form-control"
    type="text"
    [matDatepicker]="datepicker"
    placeholder="{{translatePlaceholder ? (placeholder | text) : placeholder}}"
    [disabled]="disabled"
    [min]="min"
    [max]="max"
  />
  <mat-datepicker-toggle [for]="datepicker"
    ><span
      class="icon-calendar calendar-input-icon"
      matDatepickerToggleIcon
    ></span
  ></mat-datepicker-toggle>
  <mat-datepicker #datepicker></mat-datepicker>
</div>

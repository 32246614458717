import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { default as cloneDeep } from 'lodash.clonedeep';
import { default as Rollbar } from 'rollbar';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppConfigService, CurrentUserService } from '@olmero/shared-core';
import { RollbarService } from './rollbar.service';

declare const require;

@Injectable({ providedIn: 'root' })
export class LoggerService {

  constructor(
    private injector: Injector,
    private appConfigService: AppConfigService,
    private currentUser: CurrentUserService
  ) {
  }

  logInfo(message: string): void {
    if (this.appConfigService.getConfig().consoleLoggingEnabled) {
      console.log(message);
    }
  }

  logError(err: any, req?: HttpRequest<any>): void {
    if (this.appConfigService.getConfig().consoleLoggingEnabled) {
      console.error(err);
    }
    if (!this.appConfigService.getConfig().rollbar.enabled) {
      return;
    }

    let error = err;
    if (err.headers) {
      error = err.headers.keys() && cloneDeep(err);
      error.headers.headers = this.mapHeadersToObj(err.headers);
    }

    const rollbar = this.getRollbar();
    // provide some user details for rollbar if possible
    this.currentUser.getCurrentUserData().pipe(
      tap(currentUserData => {
        if (currentUserData !== null) {
          rollbar.configure({
            payload: {
              person: {
                id: currentUserData.uid,
                username: currentUserData.getFullName(),
                email: currentUserData.email,
              },
            },
          });
        }
      }),
      map(() => this.sendRollbarError(rollbar, error, req)),
      catchError(() => of(this.sendRollbarError(rollbar, error, req)))
    ).subscribe();
  }

  private mapHeadersToObj(httpHeaders: HttpHeaders): Record<string, any> {
    return httpHeaders.keys()
      .reduce((obj, key) => {
        obj[key] = httpHeaders.getAll(key);
        return obj;
      }, {});
  }

  private sendRollbarError(rollbar: Rollbar, error: any, req?: HttpRequest<any>): Rollbar.LogResult {
    if (req) {
      return rollbar.error(this.getErrorMessage(error), error, { requestBody: req.body, requestHeaders: this.mapHeadersToObj(req.headers) });
    } else {
      return rollbar.error(this.getErrorMessage(error), error);
    }
  }

  private getRollbar(): Rollbar {
    try {
      return this.injector.get(RollbarService);
    } catch (e) {
      if (!this.appConfigService.getConfig().production) {
        throw e;
      } else {
        const fallbackConfig = require('config/rollbar/fallback-config.json') as Rollbar.Configuration;
        return new Rollbar({
          enabled: fallbackConfig.enabled,
          environment: fallbackConfig.environment,
          accessToken: fallbackConfig.accessToken,
        });
      }
    }
  }

  private getErrorMessage(error: any): string {
    return this.unwrapError(error).message;
  }

  private unwrapError(error: any): Error {
    return error.rejection ? error.rejection : error;
  }
}

<ng-container *ngIf="blacklisted$ | async as blacklisted">
  <span
    *ngIf="blacklisted.isBlacklisted() && blacklisted.hasBlacklisted()"
    [olm-tooltip]="blacklistTooltip$ | async"
    [olm-tooltip-translate]="false"
    class="filled-star spinner-star"
    tclass="test-blacklist"
  >
    <span class="icon-blocked"></span>
  </span>
</ng-container>

import { BlacklistHandler } from './blacklist-handler';

export class NoopBlacklistHandler implements BlacklistHandler {

  hasBlacklisted(): boolean {
    return false;
  }

  isBlacklisted(): boolean {
    return false;
  }

}

  <div class="navigation">
    <button tclass="test-toggle-button" class="icon-button-primary button-header" [class.is-open]="isOpen" (click)="toggleMenu()">
        <span tclass="test-toggle-button-icon" [ngClass]="isOpen ? 'icon-close' : 'icon-menu'"></span>
    </button>
    <div [class.navigation-dropdown-visible]="isOpen" tclass="test-navigation-dropdown" class="navigation-dropdown">
        <div class="navigation-dropdown-group">
            <a tclass="test-navigation-home" class="navigation-dropdown-group-item" routerLinkActive="active"
                [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleMenu()">
                <p i18n="@@pp_core_menu_home">Home</p>
            </a>
        </div>
        <div class="navigation-dropdown-group" *ngIf="showProjectsLink">
          <a tclass="test-projects-link" class="navigation-dropdown-group-item" routerLinkActive="active" [routerLink]="['/projects']"
            (click)="toggleMenu()">
            <p i18n="@@home_page_projects_menu">Projects</p>
          </a>
        </div>
        <div class="navigation-dropdown-group" *ngIf="showTendersLink">
          <a tclass="test-bidders-link" class="navigation-dropdown-group-item" routerLinkActive="active" [routerLink]="['/bidders']"
            (click)="toggleMenu()">
            <p i18n="@@home_page_tenders_menu">Tenders</p>
          </a>
        </div>
        <div class="navigation-dropdown-group" *ngIf="showTenderLink">
          <ng-container *ngIf="tenderBasicInfo; else skeletonBlock">
            <a tclass="test-private-bidder-link" class="navigation-dropdown-group-item" routerLinkActive="active"
               [routerLink]="['/bidders', 'private', 'projects', tenderBasicInfo.project_uid, 'tenders', tenderBasicInfo.tender_uid]"
               (click)="toggleMenu()">
              <p i18n="@@home_page_tender_menu">Tender</p>
            </a>
          </ng-container>
          <ng-template #skeletonBlock>
            <div [style.width.%]="100" tclass="test-private-bidder-link-skeleton">
              <olm-skeleton-loader
                [objectHeight]="48"
                [type]="SkeletonType.LINE"
              ></olm-skeleton-loader>
            </div>
          </ng-template>
        </div>
        <div class="navigation-dropdown-group" *ngIf="showProjectHandlingLink">
          <a tclass="test-project-handling-link" class="navigation-dropdown-group-item"
            olmApModuleLink="projectHandling" [clientDetails]="clientDetails" (click)="toggleMenu()">
            <p i18n="@@home_page_project_handling_menu">Project Handling</p>
            <span class="icon-external-link"></span>
          </a>
        </div>
        <div tclass="test-tools" class="navigation-dropdown-group" *ngIf="clientDetails?.hasToolModules">
            <a tclass="test-constructor-data-link" *ngIf="clientDetails?.hasConstructorDataModule"
                class="navigation-dropdown-group-item" olmApModuleLink="constructorData" [clientDetails]="clientDetails"
                (click)="toggleMenu()">
                <p i18n="@@pp_core_clients_module_construction_data">
                    Construction Data
                </p>
                <span class="icon-external-link"></span>
            </a>
            <a tclass="test-constructor-data-link" *ngIf="clientDetails?.hasSmartModule"
               class="navigation-dropdown-group-item"
               (click)="SMARTHelper.openOnNewTab()">
              <p i18n="@@pp_core_clients_module_smart">
                SMART
              </p>
              <span class="icon-external-link"></span>
            </a>
            <a tclass="test-project-list-link" *ngIf="clientDetails?.hasProjectListModule"
                class="navigation-dropdown-group-item" olmApModuleLink="projectList" [clientDetails]="clientDetails"
                (click)="toggleMenu()">
                <p i18n="@@pp_core_clients_module_project_list">Project List</p>
                <span class="icon-external-link"></span>
            </a>
            <a tclass="test-public-tenders-link" *ngIf="clientDetails?.hasPublicTendersModule"
                class="navigation-dropdown-group-item" olmApModuleLink="publicTenders" [clientDetails]="clientDetails"
                (click)="toggleMenu()">
                <p i18n="@@pp_core_clients_module_public_tenders">Public Tenders</p>
                <span class="icon-external-link"></span>
            </a>
        </div>
        <div class="navigation-dropdown-group">
            <a tclass="test-private-directory-link" *ngIf="clientDetails?.hasPublisherModule"
                class="navigation-dropdown-group-item" routerLinkActive="active" [routerLink]="['/directory']"
                (click)="toggleMenu()">
                <p i18n="@@home_page_private_directory_link">Private Directory</p>
            </a>
            <a tclass="test-reference-platform-link" class="navigation-dropdown-group-item" [href]="referencePlatFormLink" target="_blank"
                (click)="toggleMenu()">
                <p i18n="@@home_page_reference_platform_link">Reference Platform</p>
                <span class="icon-external-link"></span>
            </a>
        </div>
        <div tclass="test-administration-group" class="navigation-dropdown-group" *ngIf="user.is_administrator">
            <div class="navigation-dropdown-group-label">
                <h2 class="typo-label-s" i18n="@@pp_core_menu_admin">
                    Administration
                </h2>
            </div>
            <a tclass="test-clients-link" class="navigation-dropdown-group-item" routerLinkActive="active"
                [routerLink]="['/clients']" (click)="toggleMenu()">
                <p i18n="@@navigation_search_customers">Search Customers</p>
            </a>
            <a tclass="test-project-admin-dashboard-link" class="navigation-dropdown-group-item"
                routerLinkActive="active" [routerLink]="['/projects/admin/dashboard']" (click)="toggleMenu()">
                <p i18n="@@navigation_search_projects">Search Projects</p>
            </a>
            <a tclass="test-admin-statistics-link" class="navigation-dropdown-group-item" routerLinkActive="active"
                [routerLink]="['/admin/statistics']" (click)="toggleMenu()">
                <p i18n="@@admin_statistics_page_title">Statistics</p>
            </a>
            <a class="navigation-dropdown-group-item" olmApLink="/" (click)="toggleMenu()">
                <p i18n="@@navigation_extended_administration">
                    Extended Administration
                </p>
                <span class="icon-external-link"></span>
            </a>
        </div>
        <div tclass="test-development-group" class="navigation-dropdown-group" *ngIf="user.is_administrator">
            <div class="navigation-dropdown-group-label">
                <h2 class="typo-label-s" i18n="@@navigation_development">
                    Development
                </h2>
            </div>
            <a class="navigation-dropdown-group-item" routerLinkActive="active" [routerLink]="['/elements']"
                (click)="toggleMenu()">
                <p i18n="@@pp_core_menu_dev_styleguide">Styleguide</p>
            </a>
        </div>
    </div>
</div>

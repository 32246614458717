import { default as moment, Moment } from 'moment';
import { DeductionDto } from '@project-shared/model/deduction/deduction.model';
import { DiscountDto } from '../discount.model';
import { Attachment, AttachmentDto } from '../attachment/attachment.model';
import { OfferTermDocument } from './offer-term-documents.model';
import { Link, LinkDto } from '@olmero/shared-core';
import { ContactData, ContactDataDto } from '../contact.model';
import { BehaviorSubject } from 'rxjs';

export enum OfferState {
  OFFERED = 'offered',
  RECEIVED = 'received',
  REPLACED = 'replaced',
  DISABLED = 'disabled',
  OFFER_PUBLISH = 'offer_publish',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DRAFT = 'draft'
}

export enum OfferBiddingState {
  IN_BIDDING = 'IN_BIDDING',
  BID_PLACED = 'BID_PLACED',
  BIDDING_EXPIRED = 'BIDDING_EXPIRED'
}

export interface OfferDto {
  uid: string;
  tender_uid: string;
  bidder_uid: string;
  bidder_name: string;
  bidder_email?: string;
  bid_index: number;
  state: OfferState;
  state_modified_at?: Moment;
  net_incl_vat: number;
  conformity: 'sia451' | 'variant' | 'not_testable';
  created_at: Moment;
  modified_at: Moment;
  link: string;
  links?: Link[];
  subsequent: boolean;
  email_id?: string;
  gross_excl_vat?: number;
  net_fixed_price_excl_vat?: number;
  discount?: DiscountDto;
  early_payment_discount?: DiscountDto;
  deductions_before_vat?: {
    total_deduction: number,
    deductions?: DeductionDto[],
  };
  intermediate_result?: number;
  net_excl_vat?: number;
  net_before_vat?: number;
  vat?: number;
  vat_percentage?: number;
  is_bidder_blacklisted?: boolean;
  description?: string;
  benchmark?: boolean;
  deviation?: number;
  contact?: ContactDataDto;
  attachments?: AttachmentDto[];
  terms_conditions_accepted?: boolean;
  terms?: OfferTermDocument[];
  name?: string;
  last_published_at?: Moment;
  last_inspection_issuer_at?: Moment;
  external?: boolean;
  date_time_received?: Moment;
  bidding_round_status?: OfferBiddingState;
  project_references?: number[];
  is_in_approval?: boolean;
  bidding_round_uid?: string;
  previous_offer_uid?: string;
  _links?: {
    ['reference-project-details-link']?: LinkDto,
    history?: LinkDto,
    ['create-bid-as-issuer']?: LinkDto,
    ['edit-bid-as-issuer']?: LinkDto,
    update?: LinkDto,
  };
}

export class Offer implements OfferDto {
  uid: string;
  tender_uid: string;
  bidder_uid: string;
  bidder_name: string;
  bidder_email?: string;
  bid_index: number;
  state: OfferState;
  state_modified_at?: Moment;
  net_incl_vat: number;
  conformity: 'sia451' | 'variant' | 'not_testable';
  created_at: Moment;
  modified_at: Moment;
  link: string;
  links?: Link[];
  subsequent: boolean;
  email_id?: string;
  gross_excl_vat?: number;
  net_fixed_price_excl_vat?: number;
  discount?: DiscountDto;
  early_payment_discount?: DiscountDto;
  deductions_before_vat?: {
    total_deduction: number,
    deductions?: DeductionDto[],
  };
  intermediate_result?: number;
  net_excl_vat?: number;
  net_before_vat?: number;
  vat?: number;
  vat_percentage?: number;
  is_blacklisted?: boolean;
  description?: string;
  benchmark?: boolean;
  benchmark$: BehaviorSubject<boolean>;
  hide?: boolean;
  deviation?: number;
  contact?: ContactData;
  attachments?: Attachment[];
  terms_conditions_accepted?: boolean;
  terms?: OfferTermDocument[];
  name?: string;
  last_published_at?: Moment;
  last_inspection_issuer_at?: Moment;
  external?: boolean;
  date_time_received?: Moment;
  bidding_round_status?: OfferBiddingState;
  project_references?: number[];
  history?: Offer[];
  historyOpened$: BehaviorSubject<boolean>;
  is_in_approval?: boolean;
  bidding_round_uid?: string;
  previous_offer_uid?: string;
  _links?: {
    ['reference-project-details-link']?: LinkDto,
    history?: LinkDto,
    ['create-bid-as-issuer']?: LinkDto,
    ['edit-bid-as-issuer']?: LinkDto,
    update?: LinkDto,
  };

  constructor(init?: OfferDto) {
    if (init) {
      Object.assign(this, init);
      if (this.state_modified_at) {
        this.state_modified_at = moment(this.state_modified_at);
      }
      if (this.modified_at) {
        this.modified_at = moment(this.modified_at);
      }
      if (this.created_at) {
        this.created_at = moment(this.created_at);
      }
      if (this.last_published_at) {
        this.last_published_at = moment(this.last_published_at);
      }
      if (this.last_inspection_issuer_at) {
        this.last_inspection_issuer_at = moment(this.last_inspection_issuer_at);
      }
      if (init.hasOwnProperty('is_bidder_blacklisted')) {
        this.is_blacklisted = init.is_bidder_blacklisted;
      }
      if (this.date_time_received) {
        this.date_time_received = moment(this.date_time_received);
      }
      if (init.attachments) {
        this.attachments = init.attachments.map(attachment => new Attachment(attachment));
      }
      if (init.contact){
        this.contact = new ContactData(init.contact);
      }
    }
    this.benchmark$ = new BehaviorSubject<boolean>(false);
    this.historyOpened$ = new BehaviorSubject<boolean>(false);
  }

  findProjectReferenceLink(): string | undefined {
    return this.get_link('reference-project-details-link') || this._links?.['reference-project-details-link']?.href;
  }

  get delete_bid_as_issuer_link(): string | undefined {
    return this.get_link('delete-bid-as-issuer');
  }

  get history_link(): string | undefined {
    return this.get_link('history') || this._links?.history?.href;
  }

  get create_bid_as_issuer_link(): string | undefined {
    return this.get_link('create-bid-as-issuer') || this._links?.['create-bid-as-issuer']?.href;
  }

  get edit_bid_as_issuer_link(): string | undefined {
    return this.get_link('edit-bid-as-issuer') || this._links?.['edit-bid-as-issuer']?.href;
  }

  get update_link(): string | undefined {
    return this.get_link('update') || this._links?.update?.href;
  }

  private get_link(rel: string): string | undefined {
    return this.links?.find(link => link.rel === rel)?.href;
  }

  get is_in_bidding_round(): boolean {
    return this.bidding_round_status === OfferBiddingState.IN_BIDDING;
  }
}

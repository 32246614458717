import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { appRoutesNames } from '../../../../app.routes.names';
import { GtmService } from '@olmero/shared-core';
import { ToastType } from '../model/toast-type.enum';
import { AlertType } from '@olmero/shared-ui';

@Component({
  selector: 'olm-toast-info',
  templateUrl: './toast-info.component.html',
  styleUrls: ['./toast-info.component.scss'],
})
export class ToastInfoComponent implements OnInit {

  toastType: ToastType = ToastType.SUCCESS;
  alertType: AlertType;
  message: string;
  closable = false;
  helpLink = appRoutesNames.CODE_500;

  constructor(
    private snackBarRef: MatSnackBarRef<ToastInfoComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private gtm: GtmService
  ) {
  }

  ngOnInit(): void {
    this.message = this.data.infoMessage;
    this.toastType = this.data.type;
    this.closable = this.data.closable;
    this.alertType = this.convertToastTypeToAlertType(this.toastType);
  }

  convertToastTypeToAlertType(toastType: ToastType): AlertType {
    const toastSnackbarMapping = {
      [ToastType.SUCCESS]: AlertType.SUCCESS,
      [ToastType.WARNING]: AlertType.WARNING,
      [ToastType.INFO]: AlertType.INFO,
      [ToastType.DANGER]: AlertType.ERROR,
      [ToastType.DANGER_SUPPORT]: AlertType.ERROR,
    };

    return toastSnackbarMapping[toastType];
  }

  close(): void {
    this.snackBarRef.dismiss();
  }

  openErrorPage(): void {
    this.gtm.pushCustomEvent({
      event: 'customEvent',
      eventCategory: 'error',
      eventAction: 'click error page link',
    });
    this.close();
    this.router.navigate([this.helpLink], { relativeTo: this.route }).then();
  }

  protected readonly AlertType = AlertType;
  protected readonly ToastType = ToastType;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiRoot } from '@olmero/shared-core';
import { ApiOauthTokenService } from '@olmero/shared-core';
import { ImpersonationService } from '@olmero/shared-core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'olm-profiles-dropdown',
  templateUrl: './profiles-dropdown.component.html',
})
export class ProfilesDropdownComponent implements OnInit, OnDestroy {

  @Input() apiRoot: ApiRoot;

  adminFullName: string;

  private unsubscribe = new Subject<void>();

  constructor(private impersonationService: ImpersonationService, private apiOauthTokenService: ApiOauthTokenService){}

  ngOnInit(): void {
    this.apiOauthTokenService
      .getFullProfileName()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(fullName => this.adminFullName = fullName);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get hasImpersonation(): boolean {
    return this.impersonationService.hasImpersonation();
  }
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiExceptionHandlerInterceptor } from './api-exception-handler/api-exception-handler.interceptor';
import { ApiHttpErrorInterceptor } from './api-http-error/api-http-error-interceptor';
import { ApiLanguageInterceptor } from './api-language/api-language-interceptor';
import { ApiOauthInterceptor } from './api-oauth/api-oauth.interceptor';
import { CacheInterceptor } from './cache/cache.interceptor';
import { HateoasInterceptor } from './hateoas-interceptor/hateoas.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HateoasInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiLanguageInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiExceptionHandlerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiHttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiOauthInterceptor, multi: true },
];

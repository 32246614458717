import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FilterParamsService {
  additionalParams: { [key: string]: any[]};

  constructor() {
    this.additionalParams = {};
  }

  private isBlank(str: string): boolean{
    return (!str || str.trim().length === 0);
  }

  append(httpParams: HttpParams, input: string, key = 'filter'): HttpParams {
    if (input && !this.isBlank(input)) {
      // first part of regex [\"].*?[\"] matches everything that start with double quotes and ends with that same character
      // second part [\'].*?[\'] does the same thing as first part with single quotes
      // third part ([^,\s]+) selects all groups that dont include space or comma
      const arr = input.trim().match(/["].*?["]|['].*?[']|([^,\s]+)/g);
      arr.forEach(word => {
        // testing if word starts and ends with single or double quotes
        if (/^['].*[']$|^["].*["]$/.test(word)) {
          word = word.replace(new RegExp(word.charAt(0), 'g'), '');
        }
        httpParams = httpParams.append(key, word);
      });
    }
    return httpParams;
  }
}

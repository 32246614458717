/* eslint-disable max-classes-per-file */

import { Client, ClientAddress, Duplicate } from '@olmero/shared-core';
import { ProjectDetailsWithOptions } from '@project-shared/modules/api/resolver/model/project-details-with-options.model';

export interface ClientPart {
  uid: string;
  name: string;
  department: string;
  city: string;
  state: string;
}

export class Lookups {
  objectTypes: string[];
  tenderTypes: string[];
  contractVolumes: string[];
}

export class ClientAndParent {
  client: Client;
  clientContactAddress: ClientAddress;
  parent?: Client;
  parentContactAddress?: ClientAddress;
}

export class ProjectAndClientAndParent extends ClientAndParent {
  project: ProjectDetailsWithOptions;
}

export class ClientAndDependencies {
  clientAndParent: ClientAndParent;
  clientBillingAddress?: ClientAddress;
  dependsOnClientList: Client[];
  duplicate?: Duplicate;
}

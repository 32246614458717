import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TenderTemplate } from '@project-shared/model/tender/tender-template.model';
import { EMPTY, Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { ProjectResolver } from './project.resolver';

@Injectable()
export class EditProjectTenderTemplateResolver {

  constructor(private projectResolver: ProjectResolver,
              private router: Router,
              private urlHelper: UrlHelper) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderTemplate> {
    return this.projectResolver.resolve(route)
      .pipe(
        flatMap(project => {
          if (!project.template || !project.projectDetails.access.can_edit_project_settings) {
            this.router.navigate([this.urlHelper.calculateParentUrl(route)]);
            return EMPTY;
          }
          return of(project.template);
        })
      );
  }
}

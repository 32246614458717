import { Company, ContactPerson } from '@project-shared/model/contact-person.model';
import { Contact, ContactDto, TenderContactDataDto } from '@project-shared/model/tender/deprecated-contact.model';
import { ContactDataDto } from '@project-shared/model/contact.model';
import { MemberDto } from '@project-shared/model/member.model';
import { ClientParticipantDto } from '@project-shared/model/participants/client-participant.model';
import { ProjectMemberDto } from '@issuer-shared/search-member-dialog/model/project-member.model';

type JsonData = TenderContactDataDto | ContactDataDto | ContactDto | MemberDto | ClientParticipantDto | ProjectMemberDto;

export class ContactPersonMapper {
  static transform(json: JsonData): ContactPerson {
    return new ContactPerson({
      uid: this.normalizeUid(json),
      first_name: this.normalizeFirstName(json),
      last_name: this.normalizeLastName(json),
      department: this.normalizeDepartment(json),
      function: this.normalizeFunction(json),
      phone_number: this.normalizePhoneNumber(json),
      mobile_number: this.normalizeMobileNumber(json),
      fax_number: this.normalizeFaxNumber(json),
      email: this.normalizeEmail(json),
      language_code: this.normalizeLanguageCode(json),
      company: this.normalizeCompany(json),
      main_contact: this.normalizeMainContact(json),
    });
  }

  /**
   * @deprecated This method is deprecated and will be removed in future versions.
   * Only necessary because the API requires a contact with member_ prefix for all properties
   */
  static transformIntoOldContact(contact: ContactPerson): Contact {
    return new Contact({
      member_uid: contact.uid,
      member_firstname: contact.first_name,
      member_lastname: contact.last_name,
      member_function: contact.function,
      member_email: contact.email,
      main_contact: contact.main_contact,
    });
  }

  private static normalizeUid(json: JsonData): string {
    if ('uid' in json) return json.uid;
    if ('member_uid' in json) return json.member_uid;
    return '';
  }

  private static normalizeFirstName(json: JsonData): string {
    if ('firstName' in json) return json.firstName;
    if ('first_name' in json) return json.first_name;
    if ('member_firstname' in json) return json.member_firstname;
    return '';
  }

  private static normalizeLastName(json: JsonData): string {
    if ('lastName' in json) return json.lastName;
    if ('last_name' in json) return json.last_name;
    if ('member_lastname' in json) return json.member_lastname;
    return '';
  }

  private static normalizeDepartment(json: JsonData): string {
    if ('department' in json) return json.department;
    if ('client' in json && 'department' in (json as ContactDto).client) return (json as ContactDto).client.department;
    if ('member_client_department' in json) return json.member_client_department;
    return '';
  }

  private static normalizeFunction(json: JsonData): string {
    if ('function' in json) return json.function;
    if ('member_function' in json) return json.member_function;
    return '';
  }

  private static normalizePhoneNumber(json: JsonData): string {
    if ('phoneNumber' in json) return json.phoneNumber;
    if ('phone_number' in json) return json.phone_number;
    if ('member_phone_number' in json) return json.member_phone_number;
    return '';
  }

  private static normalizeMobileNumber(json: JsonData): string {
    if ('mobile' in json) return json.mobile;
    if ('mobileNumber' in json) return json.mobileNumber;
    if ('mobile_number' in json) return json.mobile_number;
    if ('member_mobile_phone_number' in json) return json.member_mobile_phone_number;
    return '';
  }

  private static normalizeFaxNumber(json: JsonData): string {
    if ('fax' in json) return json.fax;
    if ('fax_number' in json) return json.fax_number;
    if ('member_fax' in json) return json.member_fax;
    return '';
  }

  private static normalizeEmail(json: JsonData): string {
    if ('email' in json) return json.email;
    if ('member_email' in json) return json.member_email;
    return '';
  }

  private static normalizeLanguageCode(json: JsonData): string {
    if ('languageCode' in json) return json.languageCode;
    if ('language_code' in json) return json.language_code;
    return '';
  }

  private static normalizeCompany(json: JsonData): Company {
    let company: any;

    // Some models use company property to represent company name
    if ('company' in json && typeof json.company !== 'string') {
      company = json.company as any;
    }

    if ('client' in json) {
      company = json.client;
    }

    if (company) {
      return new Company({
        uid: company.uid,
        name: company.name,
        department: company.department,
        email: company.email,
        phone_number: company.phone_number || company.phoneNumber || company.phone,
        fax_number: company.fax_number || company.faxNumber || company.fax,
        city: company.city,
        zip: company.zip,
        street: company.street,
        country_code: company.country_code,
        logo_url: company.logo_url || (json as ContactDto).member_logo_url,
        list_logo_url: company.list_logo_url,
      });
    }

    return undefined;
  }

  private static normalizeMainContact(json: JsonData): boolean {
    if ('main_contact' in json) return json.main_contact;
    return false;
  }

  private static normalizeLogoUrl(json: JsonData): string {
    if ('member_logo_url' in json) return json.member_logo_url;
    return '';
  }
}

import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  ApiRoot, ClientDetails, CurrentUserData, CurrentUserService, LangCode, LanguageService
} from '@olmero/shared-core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TenderBasicInfo } from '@project-shared/model/tender-basic-info';
import { SkeletonType } from '@project-shared/modules/ui/components/skeleton-loader/skeleton-loader.enum';
import { PrivateBidderService } from '@project-shared/services/private-bidder.service';
import { SMARTHelper } from '@project-shared/services/smart-link.service';

@Component({
  selector: 'olm-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  clientDetails: ClientDetails;
  user: CurrentUserData;
  isOpen = false;
  langCode: LangCode;
  tenderBasicInfo: TenderBasicInfo;
  SkeletonType = SkeletonType;
  private unsubscribe = new Subject<void>();
  private documentClickListener: () => void;
  protected readonly SMARTHelper = SMARTHelper;

  constructor(private eRef: ElementRef,
              private renderer: Renderer2,
              private languageService: LanguageService,
              private currentUserService: CurrentUserService,
              private privateBidderService: PrivateBidderService
  ) {}


  ngOnInit(): void {
    this.currentUserService
      .getApiRoot()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((apiRoot: ApiRoot) => {
        if (apiRoot !== null){
          this.clientDetails = apiRoot.client_details;
          this.user = apiRoot.member_details;
          if (this.clientDetails.is_private) {
            this.loadTenderBasicInfo();
          }
        }

      });

    this.languageService.detectLanguage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(langCode => this.langCode = langCode);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private loadTenderBasicInfo(): void {
    this.privateBidderService.getTenderBasicInfo()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((tenderBasicInfo: TenderBasicInfo) => {
        this.tenderBasicInfo = tenderBasicInfo;
      });
  }

  get showProjectsLink(): boolean {
    return this.clientDetails.hasPublisherModule || this.user.is_administrator;
  }

  get showTendersLink(): boolean {
    return this.clientDetails.hasContractorOrInfoPackageModule && !this.clientDetails.is_private;
  }

  get showTenderLink(): boolean {
    return this.clientDetails.hasContractorModule && this.clientDetails.is_private;
  }

  get showProjectHandlingLink(): boolean {
    return this.clientDetails.hasProjectHandlingModule;
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.addDocumentClickListener();
    } else {
      this.removeDocumentClickListener();
    }
  }

  private addDocumentClickListener(): void {
    this.documentClickListener = this.renderer.listen('document', 'click', (event: Event) => {
      if (this.clickOut(event)) {
        this.isOpen = false;
        this.removeDocumentClickListener();
      }
    });
  }

  private removeDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

  private clickOut(event: Event): boolean {
    return !this.eRef.nativeElement.contains(event.target);
  }

  get referencePlatFormLink(): string {
    const base_url = 'https://referenzplattform.ch/firmen/seite.html';
    switch (this.langCode) {
      case 'de':
      case 'en':
        return base_url;
      case 'fr':
      case 'it':
        return 'https://referencesplateforme.ch/entreprises/page.html';
      default:
        return base_url;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { OlmTextModule } from '@olmero/shared-core';
import { OlmTestClassModule } from '@olmero/shared-ui';
import { FeatureFlagModule } from '@project-shared/modules/feature-flag/feature-flag.module';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SharedDirectivesModule } from '@olmero/shared-ui';
import { ProfilesDropdownComponent } from './components/profiles-dropdown/profiles-dropdown.component';
import { HelpMenuItemComponent } from './components/help-menu-item/help-menu-item.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { OlmUiModule } from '@project-shared/modules/ui/ui.module';

@NgModule({
  declarations: [
    HeaderComponent,
    HelpMenuItemComponent,
    NavigationComponent,
    ProfilesDropdownComponent,
    LanguageSwitchComponent,
  ],
  exports: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    OlmTextModule,
    OlmTestClassModule,
    FeatureFlagModule,
    MatMenuModule,
    FlexLayoutModule,
    SharedDirectivesModule,
    NgOptimizedImage,
    OlmUiModule,
  ],
})
export class HeaderModule { }

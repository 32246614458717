import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'olm-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  @HostBinding('id') id = 'olm-breadcrumb';
  hideHomeBreadcrumb: boolean;
  unsubscribe$ = new Subject<void>();

  constructor(public breadcrumbService: BreadcrumbService, private router: Router) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.hideHomeBreadcrumb = event.url.includes('?') ? event.url.split('?')[0] === '/' : event.url === '/';
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

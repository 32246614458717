import { NgModule } from '@angular/core';
import { ChDateTimePipe } from './pipes/ch-date-time.locale.pipe';
import { ChDatePipe } from './pipes/ch-date.locale.pipe';
import { ChfPipe } from './pipes/chf.locale.pipe';
import { ChTimePipe } from './pipes/ch-time.locale.pipe';
import { CurrencyNumPipe } from './pipes/currency-num.pipe';

@NgModule({
  imports: [],
  declarations: [
    ChDatePipe,
    ChDateTimePipe,
    ChTimePipe,
    ChfPipe,
    CurrencyNumPipe,
  ],
  exports: [
    ChDatePipe,
    ChDateTimePipe,
    ChTimePipe,
    ChfPipe,
    CurrencyNumPipe,
  ],
  providers: [],
})
export class OlmLocaleModule {}

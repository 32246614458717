export class BidderProject implements BidderProjectDto {
  name: string;
  number: string;
  city: string;
  uid: string;
  zip?: string;

  constructor(json?: BidderProjectDto) {
    if (json) {
      Object.assign(this, json);
    }
  }

  getFormattedProjectName(): string {
    return `${this.number} ${this.name} | ${this.city}`;
  }
}

export interface BidderProjectDto {
  name: string;
  number: string;
  city: string;
  uid: string;
  zip?: string;
}

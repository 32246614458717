import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ClientAddress } from '@olmero/shared-core';
import { ClientService } from '@project-shared/modules/api/services/client.service';

@Injectable()
export class ClientAddressResolver {

  constructor(private clientService: ClientService) {
  }

  resolve(): Observable<ClientAddress> {
    return this.clientService.getClientAddressForUser();
  }
}

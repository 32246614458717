import { default as moment, Moment } from 'moment';
import { BaseBiddingRound, BiddingRoundType } from '@project-shared/model/bidding-round/base-bidding-round.model';

export interface CommonBiddingRoundDto {
  uuid: string;
  type: BiddingRoundType;
  created: Moment;
  submission_deadline: Moment;
  submission_deadline_expired: boolean;
}

export interface IssuerBiddingRoundDto extends CommonBiddingRoundDto{
  bids_by_bidder: object;
}

/**
 * This model is used when a bidding round is *returned* by the API for an issuer client
 *
 *  @see CreateBiddingRound
 */
export class IssuerBiddingRound extends BaseBiddingRound implements IssuerBiddingRoundDto {
  bids_by_bidder: object;

  constructor(dto: IssuerBiddingRoundDto) {
    super();
    Object.assign(this, dto);
    this.submission_deadline = this.submission_deadline ? moment(this.submission_deadline) : null;
    this.created = this.created ? moment(this.created) : null;
  }
}

import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PipesModule } from '@olmero/shared-ui';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { DateAutocompleteDirective } from '@project-shared/modules/forms/directives/date-autocomplete/date-autocomplete.directive';

@Component({
  selector: 'olm-datepicker',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDatepickerModule, FormsModule, DateAutocompleteDirective],
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    }],
})
export class DatepickerComponent implements ControlValueAccessor {

  @Output() dateChanged = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @Input() useAutocomplete = false;
  @Input() isLuxonDate = false;
  @Input() placeholder: string;
  @Input() translatePlaceholder = true;
  @Input() min: DateTime | Moment;
  @Input() max: DateTime | Moment;

  value: any;
  onTouched: () => void;
  onChange: (value: any) => void;
  touched = false;
  disabled = false;

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HelpService {

  getHelpPage(activeLanguage: string): string {
    switch (activeLanguage) {
      case 'de':
        return 'https://www.olmero.ch/hilfe';
      case 'fr':
        return 'https://www.olmero.ch/fr/aide';
      case 'en':
        return 'https://www.olmero.ch/en/help';
      case 'it':
        return 'https://www.olmero.ch/it/aiuto';
      default:
        return '';
    }
  }
}

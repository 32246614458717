import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PpwebService {

  constructor(private http: HttpClient) {}

  touchSession(accessToken: string, refreshToken: string): Observable<null> {
    let params = new HttpParams();
    if (accessToken) {
      params = params.set('at', accessToken);
    }
    if (refreshToken) {
      params = params.set('rt', refreshToken);
    }
    return this.http.get('/session/touch', { params }).pipe(
      map(() => null)
    );
  }

}

import { Injectable } from '@angular/core';

import { CurrentUserData } from '@olmero/shared-core';
import { Observable } from 'rxjs';
import { CurrentUserService } from '@olmero/shared-core';

@Injectable()
export class CurrentUserDataResolver {

  constructor(private currentUserService: CurrentUserService) {
  }

  resolve(): Observable<CurrentUserData> {
    return this.currentUserService.getCurrentUserData();
  }

}

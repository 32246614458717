import { ContactCardInformation } from '@olmero/shared-ui';
import { Contact } from './tender/deprecated-contact.model';
import { LangCode } from '@olmero/shared-core';

/**
 * @deprecated This interface is deprecated and will be removed in future versions.
 * Use ContactPersonDTO instead.
 */
export interface ContactDataDto {
  uid?: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  function?: string;
  email: string;
  fax?: string;
  mobile?: string;
  language_code?: LangCode;
  company?: ContactCompanyDto;
}

/**
 * @deprecated This interface is deprecated and will be removed in future versions.
 * Use CompanyDTO instead.
 */
export interface ContactCompanyDto {
  uid: string;
  name: string;
  department: string;
  city: string;
  zip: number;
  street: string;
  country_code?: string;
  logo_url?: string;
}

export interface CompanyAddressDto {
  zip_code: string;
  street: string;
  city: string;
  number: string;
  country: string;
}

/**
 * @deprecated This interface is deprecated and will be removed in future versions.
 * Use ContactPerson instead.
 */
export class ContactData implements ContactDataDto {
  uid?: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  function?: string;
  email: string;
  fax?: string;
  mobile?: string;
  language_code?: LangCode;
  company?: ContactCompany;

  constructor(json?: ContactDataDto) {
    if (json) {
      Object.assign(this, json);
      if (json.company) {
        this.company = new ContactCompany(json.company);
      }
    }
  }

  getDisplayName(): string {
    return [this.first_name, this.last_name].filter(Boolean).join(' ');
  }

  formatContactCard(): ContactCardInformation {
    return new ContactCardInformation(<ContactCardInformation>{
      first_name: this.first_name,
      last_name: this.last_name,
      company_name: this.company?.name,
      department: this.function,
      phone_number: this.phone_number,
      mobile_number: this.mobile,
      fax_number: this.fax,
      email: this.email,
      logoUrl: this.company.logo_url,
      address: {
        street: this.company?.street,
        zip_code: this.company?.zip.toString(),
        city: this.company?.city,
        number: '',
        country: '',
      },
    });
  }
}

export class ContactCompany implements ContactCompanyDto {
  uid: string;
  name: string;
  department: string;
  city: string;
  zip: number;
  street: string;
  country_code?: string;
  logo_url?: string;

  constructor(json?: Partial<ContactCompanyDto>) {
    if (json) {
      Object.assign(this, json);
    }
  }

  formatAddress(): CompanyAddressDto {
    const address = {
      zip_code: '',
      street: this.street,
      city: this.city,
      number: '',
      country: '',
    };
    if (this.country_code && (this.zip || this.city)){
      address.zip_code += this.country_code.toUpperCase();
      if (this.zip){
        address.zip_code += '-' + this.zip;
      }
    }
    return address;
  }
}

export function formatAsContactCardInfo(contact: ContactData | Contact): ContactCardInformation {
  return new ContactCardInformation({
    ...contact,
    company_name: contact.company?.name,
    department: contact.company?.department,
    mobile_number: contact.mobile,
    fax_number: contact.fax,
    logoUrl: contact.company?.logo_url,
    address: contact.company?.formatAddress(),
  });
}

export interface FileDto {
  createdAt: number;
  uid: string;
  name: string;
  length: number;
  href: string;
  isFreeForm?: boolean;
}

export class File implements FileDto {
  public createdAt: number;
  public uid: string;
  public name: string;
  public length: number;
  public href: string;
  public isFreeForm?: boolean;

  constructor(data?: FileDto) {
    if (data) {
      Object.assign(this, data);
    }
  }

  get icon(): string {
    switch (this.type) {
      case 'xls':
      case 'xlsx':
        return 'icon-file-xls';
      case 'png':
        return 'icon-file-empty';
      case 'zip':
        return 'icon-file-zip';
      case '01s':
        return 'icon-file-empty';
      case 'pdf':
        return 'icon-file-pdf';
      default:
        return 'icon-file-empty';
    }
  }

  get type(): string {
    if (!this.name.includes('.') || this.isFreeForm) {
      return '';
    }
    const lowercased = this.name.toLocaleLowerCase();
    const splitedname = lowercased?.split('.');
    return splitedname[splitedname?.length - 1];
  }

  get size(): string {
    if (!this.length) return '';

    return this.length?.toString().length < 7 ?
      `${(this.length / 1024).toFixed(2)} kB` :
      `${(this.length / 1024 / 1024).toFixed(2)} MB`;
  }

  get isViewable(): boolean {
    return this.type === 'crbx' || this.type === '01s';
  }

}

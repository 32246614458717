import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProjectDetails } from '@project-shared/model/project/project-details.model';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MemberService } from '@project-shared/services/member.service';
import { ProjectService } from '@project-shared/services/project.service';
import { Member } from '@project-shared/model/member.model';

export interface EditProjectData {
  projectDetails: ProjectDetails;
  member: Member[];
}

@Injectable()
export class EditProjectResolver {

  constructor(
    private projectService: ProjectService,
    private memberService: MemberService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EditProjectData> {
    return this.projectService.getProject(route.paramMap.get('projectUid'))
      .pipe(
        mergeMap(project =>
          forkJoin(project.contacts.map(contact =>
            this.memberService.getMember(contact.uid)
              .pipe(
                catchError(ignored => of(undefined))
              )))
            .pipe(
              map(result => ({
                projectDetails: project,
                member: result ? result.filter(Boolean) : [],
              }))
            )
        )
      );
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from '@olmero/shared-core';
import { Offer, OfferDto } from '@project-shared/model/offer/offer.model';
import { Lookups } from '@project-shared/model/api.model';
import { IssuerBiddingRound, IssuerBiddingRoundDto } from '@project-shared/model/bidding-round/bidding-round.model';
import * as HttpStatus from 'http-status-codes';
import { Offers, OffersDto } from '@project-shared/model/offer/offers.model';
import { TenderConfigDto } from '@project-shared/model/tender-config/tender-config.dto';
import { TenderConfig } from '@project-shared/model/tender-config/tender-config.model';
import { TenderMode } from '@project-shared/model/tender-config/tender-mode.enum';
import { TenderModeName } from '@project-shared/model/tender-config/tender-mode.map';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService) {
  }

  getBiddingRoundsForTenderUid(tenderUid: string, filter: string): Observable<IssuerBiddingRound[]> {
    return this.http.get<IssuerBiddingRoundDto[]>(this.appConfigService.getConfig().apiUrl + '/tenders/' + tenderUid + '/bidding-rounds' + filter)
      .pipe(
        map(
          (receivedBiddingRound: IssuerBiddingRoundDto[]) =>
            receivedBiddingRound.map(biddingRoundDto => new IssuerBiddingRound(biddingRoundDto))));
  }

  getLatestBiddingRoundForTenderUid(tenderUid: string): Observable<IssuerBiddingRound> | Observable<undefined> {
    return this.http.get<IssuerBiddingRoundDto>(this.appConfigService.getConfig().apiUrl + '/tenders/' + tenderUid + '/bidding-rounds/latest')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatus.NOT_FOUND) {
            return of(undefined);
          }
          return EMPTY;
        }),
        map((receivedBiddingRound: IssuerBiddingRoundDto) => new IssuerBiddingRound(receivedBiddingRound)));
  }

  getOffersForTenderUid(tenderUid: string, filter: string): Observable<Offers> {
    return this.http.get<OffersDto>(this.appConfigService.getConfig().apiUrl + '/tenders/' + tenderUid + '/offers' + filter)
      .pipe(map((receivedOffers: OffersDto) => new Offers(receivedOffers)));
  }

  getOffersForTenderUidAndBidderUid(tenderUid: string, bidderUid: string): Observable<Offer[]> {
    return this.http.get<OfferDto[]>(this.appConfigService.getConfig().apiUrl + '/tenders/' + tenderUid + '/bidders/' + bidderUid + '/offers').pipe(
      map((value: OfferDto[]) => {
        const offers: Offer[] = [];
        value.forEach(data => {
          offers.push(new Offer(data));
        });
        return offers;
      })
    );
  }

  getOffer(tenderUid: string, bidderUid: string, offerUid: string): Observable<Offer> {
    return this.http.get<OfferDto>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/bidders/${bidderUid}/offers/${offerUid}`).pipe(
      map((value: OfferDto) => new Offer(value))
    );
  }

  getOfferBiddingHistory(link: string): Observable<Offer[]> {
    return this.http.get<OfferDto[]>(link).pipe(
      map((value: OfferDto[]) => {
        const offers: Offer[] = [];
        value.forEach(data => {
          offers.push(new Offer(data));
        });
        return offers;
      })
    );
  }

  getLookups(): Observable<Lookups> {
    return this.http.get(this.appConfigService.getConfig().apiUrl + '/lookups').pipe(
      map(result => {
        return {
          tenderTypes: result['tender_types'],
          objectTypes: result['object_types'],
          contractVolumes: result['contract_volumes'],
        };
      })
    );
  }

  getTenderConfig(clientUid: string): Observable<TenderConfig> {
    return this.http.get<TenderConfigDto>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/tender-config`).pipe(
      map((data: TenderConfigDto) => {
        const modes: TenderMode[] = [];
        if (data.tender_mode.private && data.tender_mode.private.length) {
          for (const modeDto of data.tender_mode.private) {
            modes.push(TenderModeName.get(modeDto));
          }
        }
        return new TenderConfig(modes);
      })
    );
  }

}

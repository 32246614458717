import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { default as Rollbar } from 'rollbar';
import { AppModule } from './app/app.module';
import { AppConfig } from '@olmero/shared-core';
import { LoadConfig } from './load-config';

declare const require;

function bootstrapFailed(error): void {
  const fallbackConfig = require('./config/rollbar/fallback-config.json') as Rollbar.Configuration;
  const rollbar = new Rollbar({
    enabled: fallbackConfig.enabled,
    environment: fallbackConfig.environment,
    accessToken: fallbackConfig.accessToken,
  });
  console.log(error);
  rollbar.error(error);
  document.getElementById('bootstrap-fail').style.display = 'block';
  document.getElementById('spinner').style.display = 'none';
}

new LoadConfig()
  .run()
  .then((config: AppConfig) => {
    if (config.production) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(error => bootstrapFailed(error));
  })
  .catch(error => {
    bootstrapFailed(error);
  });

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OlmLocaleModule } from '@project-shared/modules/locale/locale.module';
import { OlmLocaleServiceModule } from '@project-shared/modules/locale/locale.service.module';
import { OlmTextModule } from '@olmero/shared-core';
import { ForbiddenComponent } from './components/view/403/forbidden.component';
import { ServerErrorComponent } from './components/view/500/server-error.component';
import { NotFoundComponent } from './components/view/not-found/not-found.component';
import { ScrollingService } from './services/scrolling/scrolling.service';

@NgModule({
  imports: [
    CommonModule,
    OlmTextModule,
    OlmLocaleServiceModule,
    OlmLocaleModule,
  ],
  declarations: [
    NotFoundComponent,
    ForbiddenComponent,
    ServerErrorComponent,
  ],
  exports: [
    NotFoundComponent,
    ForbiddenComponent,
  ],
  providers: [
    ScrollingService,
  ],
})
export class OlmFrameModule {
}

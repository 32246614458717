import { InjectionToken } from '@angular/core';
import { default as Rollbar } from 'rollbar';
import { default as VERSION } from '../../../assets/version.json';
import { AppConfigService } from '@olmero/shared-core';

export function rollbarFactory(appConfigService: AppConfigService): Rollbar {
  return new Rollbar({
    ...appConfigService.getConfig().rollbar,
    hostSafeList: ['ap.olmero.ch'],
    payload: {
      client: {
        javascript: {
          code_version: VERSION.hash,
          guess_uncaught_frames: true,
          source_map_enabled: true,
        },
      },
    },
    transform(payload: any) {
      const trace = payload.body.trace;
      const locRegex = new RegExp(`^https?:\\/\\/${window.location.hostname.split('.').join('\\.')}\\/pp\\/web\\/(.*)`);
      if (trace && trace.frames) {
        for (const frame of trace.frames) {
          const filename = frame.filename;
          if (filename) {
            const m = filename.match(locRegex);
            frame.filename = `http://dynamichost/pp/web/${m[1]}`;
          }
        }
      }
    },
  });
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

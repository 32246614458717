import { Sort, SortDirection } from '@angular/material/sort';
import { ListDto } from '@project-shared/model/list.model';

export class Page<T> {
  number: number;
  size: number;
  total_elements: number;
  total_pages: number;
  results: T[];
  sort?: Sort;
  access?: any;

  constructor(data?: ListDto<T>) {
    if (data) {
      this.number = data.page.number;
      this.size = data.page.size;
      this.total_elements = data.page.total_elements;
      this.total_pages = data.page.total_pages;
      this.results = data._embedded.content;
      this.access = data._access;
      this.setSort(data);
    }
  }

  private setSort(data: ListDto<T>): void {
    let evaluatedSort: Sort;
    if (data.sort && data.sort.length > 0) {
      evaluatedSort = {
        active: data.sort.map(s => s.split(',', 1)[0]).join(','),
        direction: data.sort[0].split(',', 2)[1] as SortDirection,
      };
    }
    this.sort = evaluatedSort;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IssuerBiddingRound } from '@project-shared/model/bidding-round/bidding-round.model';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class BiddingRoundResolver {
  constructor(private api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IssuerBiddingRound[]> {
    return this.api.getBiddingRoundsForTenderUid(route.params.tenderUid, '');
  }
}

<div class="language-switch">
  <button
    class="button-primary language-switch-button"
    (click)="switchLanguage('de')"
    [class.activeLanguage]="isLanguageActive('de')"
    title="German"
    i18n-title="@@lang_german">
    DE
  </button>
  <button
    class="button-primary language-switch-button"
    (click)="switchLanguage('fr')"
    [class.activeLanguage]="isLanguageActive('fr')"
    title="French"
    i18n-title="@@lang_french">
    FR
  </button>
  <button
    class="button-primary language-switch-button"
    (click)="switchLanguage('en')"
    [class.activeLanguage]="isLanguageActive('en')"
    title="English"
    i18n-title="@@lang_english">
    EN
  </button>
  <button
    class="button-primary language-switch-button"
    (click)="switchLanguage('it')"
    [class.activeLanguage]="isLanguageActive('it')"
    title="Italian"
    i18n-title="@@lang_italian">
    IT
  </button>
</div>

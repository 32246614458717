import { ContactCardInformation } from '@olmero/shared-ui';

export interface CompanyDTO {
  uid: string;
  name: string;
  department: string;
  city: string;
  zip: number;
  street: string;
  country_code: string;
  logo_url: string;
  list_logo_url: string;
  fax_number: string;
  phone_number: string;
  email: string;
}

export class Company implements CompanyDTO {
  uid: string;
  name: string;
  email: string;
  department: string;
  phone_number: string;
  fax_number: string;
  logo_url: string;
  list_logo_url: string;
  zip: number;
  street: string;
  city: string;
  country_code: string;

  constructor(json?: CompanyDTO) {
    Object.assign(this, json);
  }
}

export interface ContactPersonDTO {
  uid: string;
  first_name: string;
  last_name: string;
  function: string;
  phone_number: string;
  mobile_number: string;
  fax_number: string;
  email: string;
  language_code: string;
  company?: CompanyDTO;
  main_contact?: boolean;
}

export class ContactPerson implements ContactPersonDTO {
  uid: string;
  first_name: string;
  last_name: string;
  department?: string;
  function: string;
  phone_number: string;
  mobile_number: string;
  fax_number: string;
  email: string;
  language_code: string;
  company: Company;
  main_contact?: boolean;
  displayName?: string;

  constructor(json?: ContactPerson) {
    if (json) {
      Object.assign(this, json);

      if (json.company) {
        this.company = new Company(json.company);
      }

      this.displayName = [this.first_name, this.last_name].filter(Boolean).join(' ');
    }
  }
}

export function convertToContactCard(contactPerson: ContactPerson): ContactCardInformation | null {
  if (!contactPerson) {
    return null;
  }
  return new ContactCardInformation(<ContactCardInformation>{
    first_name: contactPerson.first_name,
    last_name: contactPerson.last_name,
    company_name: contactPerson.company?.name,
    department: contactPerson.department,
    function: contactPerson.function,
    phone_number: contactPerson.phone_number,
    mobile_number: contactPerson.mobile_number,
    fax_number: contactPerson.fax_number,
    email: contactPerson.email,
    logoUrl: contactPerson.company?.logo_url,
    address: {
      street: contactPerson.company?.street,
      zip_code: contactPerson.company?.zip?.toString(),
      city: contactPerson.company?.city,
      number: '',
      country: contactPerson.company?.country_code,
    },
  });
}

import { Injectable } from '@angular/core';
import { BidderTender } from '@project-shared/model/bidder/bidder-tender.model';
import { Client, ClientAddress, CurrentUserService } from '@olmero/shared-core';
import { Member } from '@project-shared/model/member.model';
import { ProjectDetails } from '@project-shared/model/project/project-details.model';
import { Tender } from '@project-shared/model/tender/tender.model';
import { ClientService } from '@project-shared/modules/api/services/client.service';
import { TenderService } from '@project-shared/modules/api/services/tender.service';
import { FormatterService } from '@project-shared/modules/formatter/formatter.service';
import { BidderTenderService } from '@project-shared/services/bidder-tender.service';
import { ProjectService } from '@project-shared/services/project.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MemberService } from '@project-shared/services/member.service';

interface BreadcrumbData {
  client: Client | null;
  clientAddress: ClientAddress | null;
  project: ProjectDetails | null;
  tender: Tender | null;
  bidderTender: BidderTender | null;
  bidder: Client | null;
  bidderAddress: ClientAddress | null;
  member: Member | null;
}

@Injectable({ providedIn: 'root' })
export class BreadcrumbDataService {

  private readonly data: BreadcrumbData = {
    client: null,
    clientAddress: null,
    project: null,
    tender: null,
    bidderTender: null,
    bidder: null,
    bidderAddress: null,
    member: null,
  };

  constructor(private formatterService: FormatterService,
              private clientService: ClientService,
              private projectService: ProjectService,
              private tenderService: TenderService,
              private bidderTenderService: BidderTenderService,
              private memberService: MemberService,
              private currentUserService: CurrentUserService) {
  }

  resetData(): void {
    Object.keys(this.data).forEach(propName => {
      this.data[propName] = null;
    });
  }

  getClient(clientUid: string): Observable<string> {
    return combineLatest([this.loadClient(clientUid), this.loadClientAddress(clientUid)]).pipe(
      map(result => this.formatterService.companyBreadcrumb(result[0], result[1]))
    );
  }

  getClientTitle(clientUid: string): Observable<string> {
    return combineLatest([this.loadClient(clientUid), this.loadClientAddress(clientUid)]).pipe(
      map(result => this.formatterService.companyTitle(result[0], result[1]))
    );
  }

  getProject(projectUid: string): Observable<string> {
    return this.loadProject(projectUid).pipe(
      map(project => this.formatterService.projectTitle(project))
    );
  }

  isProjectBlacklisted(projectUid: string): Observable<boolean> {
    return this.loadProject(projectUid).pipe(
      map(project => project.is_blacklisted)
    );
  }

  isBidderBlacklisted(bidderUid: string): Observable<{isBlacklisted: boolean}> {
    return this.loadClient(bidderUid).pipe(
      map(bidder => ({ isBlacklisted: bidder.is_blacklisted }))
    );
  }

  getProjectTitle(projectUid: string): Observable<string> {
    return this.loadProject(projectUid).pipe(
      map(project => this.formatterService.projectTitle(project))
    );
  }

  getTender(tenderUid: string): Observable<string> {
    return this.loadTender(tenderUid).pipe(
      map(tender => this.formatterService.tenderTitle(tender))
    );
  }

  getBidderTender(tenderUid: string): Observable<string> {
    return this.loadBidderTender(tenderUid).pipe(
      map(tender => this.formatterService.tenderTitle(tender))
    );
  }

  getMember(memberUid: string): Observable<string> {
    return this.loadMember(memberUid).pipe(
      map(member => this.formatterService.memberBreadcrumb(member))
    );
  }


  private loadClient(clientUid: string): Observable<Client> {
    return of(this.data.client).pipe(
      switchMap(client => {
        if (client) {
          return of(client);
        }
        return this.clientService.getClient(clientUid).pipe(tap(result => (this.data.client = result)));
      })
    );
  }

  private loadClientAddress(clientUid: string): Observable<ClientAddress> {
    return of(this.data.clientAddress).pipe(
      switchMap(address => {
        if (address) {
          return of(address);
        }
        return this.clientService
          .getClientContactAddress(clientUid)
          .pipe(tap(result => (this.data.clientAddress = result)));
      })
    );
  }

  private loadProject(projectUid: string): Observable<ProjectDetails> {
    return of(this.data.project).pipe(
      switchMap(project => {
        if (project) {
          return of(project);
        }
        return this.projectService.getProject(projectUid).pipe(tap(result => (this.data.project = result)));
      })
    );
  }

  private loadTender(tenderUid: string): Observable<Tender> {
    return of(this.data.tender).pipe(
      switchMap(tender => {
        if (tender) {
          return of(tender);
        }
        return this.tenderService.getTender(tenderUid).pipe(tap(result => (this.data.tender = result)));
      })
    );
  }

  private loadBidderTender(tenderUid: string): Observable<BidderTender> {
    return of(this.data.bidderTender).pipe(
      switchMap(tender => {
        if (tender) {
          return of(tender);
        }
        return this.bidderTenderService.getTenderDetails(tenderUid).pipe(tap(result => (this.data.bidderTender = result)));
      })
    );
  }

  private loadMember(memberUid: string): Observable<Member> {
    return of(this.data.member).pipe(
      switchMap(member => {
        if (member) {
          return of(member);
        }
        return this.memberService.getMember(memberUid).pipe(tap(result => (this.data.member = result)));
      })
    );
  }


  checkPrivateBidder(): Observable<boolean> {
    return this.currentUserService?.getCurrentClientData().pipe(
      map(data => data?.is_private)
    );
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TenderTemplate } from '@project-shared/model/tender/tender-template.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientService } from '../services/client.service';

@Injectable()
export class ClientTenderTemplateResolver {

  constructor(private clientService: ClientService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderTemplate> {
    return this.clientService.getClientTenderTemplate(route.params.clientUid)
      .pipe(
        map(tenderTemplates => tenderTemplates[0])
      );
  }

}

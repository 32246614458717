import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '@olmero/shared-core';
import { TenderBasicInfo } from '@project-shared/model/tender-basic-info';

@Injectable({ providedIn: 'root' })
export class PrivateBidderService {

  constructor(private http: HttpClient, private appConfigService: AppConfigService) { }

  getTenderBasicInfo(): Observable<TenderBasicInfo> {
    return this.http.get<TenderBasicInfo>(`${this.appConfigService.getConfig().apiUrl}/private-bidders/tenders`);
  }
}

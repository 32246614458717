<olm-header></olm-header>
<olm-breadcrumb></olm-breadcrumb>

<table class="olm-main-table">
  <thead>
    <tr>
      <td>
        <div class="printing-header"></div>
      </td>
    </tr>
  </thead>
  <tbody class="olm-print-block olm-print-block--body">
    <tr class="olm-print-block olm-print-block--tr">
      <td class="olm-print-block olm-print-block--td">
        <main
          #appFrameContent
          id="app-frame-content"
        >
          <div class="title-with-sidenav">
            <olm-title *ngIf="hasSidenavWithTitle"></olm-title>
          </div>

          <div
            id="app-frame-custom-content"
            class="olm-container"
            [class.has-side]="hasSidenav"
          >
            <div class="olm-sidenav">
              <ng-template olmSidenav></ng-template>
            </div>
            <div class="right-app-content">
              <olm-title></olm-title>
              <router-outlet></router-outlet>
            </div>
          </div>
        </main>
      </td>
    </tr>
  </tbody>
</table>

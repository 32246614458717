import { Moment } from 'moment';
import { BaseBiddingRound, BiddingRoundType } from '@project-shared/model/bidding-round/base-bidding-round.model';
import { CommonBiddingRoundDto } from '@project-shared/model/bidding-round/bidding-round.model';

export interface BidInfo {
  bidder_name?: string;
  gross_excl_vat: number;
  net_incl_vat: number;
  my_bid: boolean;
}

export interface BidderBiddingRoundDto extends CommonBiddingRoundDto {
  full_price_ranking: BidInfo[];
  best_price: BidInfo;
}

/**
 * This model is used when the bidding round is returned for a bidder client.
 *
 * @see IssuerBiddingRound
 */
export class BidderBiddingRound extends BaseBiddingRound implements BidderBiddingRoundDto {
  uuid: string;
  submission_deadline: Moment;
  created: Moment;
  submission_deadline_expired: boolean;
  type: BiddingRoundType;
  full_price_ranking: BidInfo[];
  best_price: BidInfo;

  constructor(dto: BidderBiddingRoundDto) {
    super();
    Object.assign(this, dto);
  }

}

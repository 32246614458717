import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollService {

  public scrollTo(postion = 0): void {
    const frame = document.getElementById('app-frame-content');
    if (
      frame && frame?.scrollTop !== postion
    ) {
      frame.scrollTo({
        top: postion,
        behavior: 'smooth',
      });
    }
  }
}
import { ElementRef, Injectable } from '@angular/core';
import { ApiOauthTokenService } from '@olmero/shared-core';
import { CurrentUserService } from '@olmero/shared-core';
import { AppConfigService } from '@olmero/shared-core';

@Injectable({ providedIn: 'root' })
export class ApLinkService {

  constructor(private tokenService: ApiOauthTokenService, private config: AppConfigService, private userService: CurrentUserService) { }

  open(link: string, el: ElementRef, event: Event, openOn?:string): void {
    event?.preventDefault();
    this.tokenService.isLoggedInUsingKeycloak()
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.tokenService.getAccessToken().then(token => {
            this.userService.getCurrentUserData().subscribe(value => {
              if (value !== null) {
                const form = document.createElement('form');
                form.setAttribute('method', 'POST');
                form.setAttribute('action', this.config.getConfig().apUrl + '/login?redirect=' + link);
                form.setAttribute('target', openOn ?? '_blank');
                form.appendChild(this.createParam('session_login_name', value.username));
                form.appendChild(this.createParam('session_password', token));
                form.appendChild(this.createParam('olmero_log_action', 'JWT'));
                el.nativeElement.appendChild(form);
                form.submit();
                el.nativeElement.removeChild(form);
              }
            });
          });
        }
      });
  }

  createParam(key: string, value: string) : HTMLElement {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    return input;
  }
}

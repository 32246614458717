import { ActivatedRouteSnapshot } from '@angular/router';
import { FeatureFlagService } from '../service/feature-flag.service';
import { Injectable } from '@angular/core';

@Injectable()
export class FeatureFlagGuard {

  constructor(private featureFlagService: FeatureFlagService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.featureFlagService.isFeatureEnabled(route.data.flags);
  }

}

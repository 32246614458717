import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'awesome-tooltip',
  styleUrls: ['./olm-tooltip.component.scss'],
  templateUrl: './olm-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class OlmTooltipComponent implements AfterViewInit {

  @ViewChild('olmTooltipWrapper') olmTooltipWrapper!: ElementRef;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  text = '';
  parentLeft = 0;
  parentRight = 0;
  parentTop = 0;
  leftPosition = 0;
  insideMatMenu = false;

  ngAfterViewInit(): void {
    const tooltipWidth = this.olmTooltipWrapper.nativeElement.getBoundingClientRect().width;
    this.calculateInitialLeftPosition(tooltipWidth);
    this.adjustPositionIfExceedsWindow(tooltipWidth);
    this.changeDetector.detectChanges();
  }

  private calculateInitialLeftPosition(tooltipWidth: number): void {
    const centerPosition = this.calculateCenterPosition();
    this.leftPosition = centerPosition - tooltipWidth / 2;
  }

  private calculateCenterPosition(): number {
    return (this.parentRight - this.parentLeft) / 2 + this.parentLeft;
  }

  private adjustPositionIfExceedsWindow(tooltipWidth: number): void {
    const windowWidth = window.innerWidth;
    const rightPosition = this.leftPosition + tooltipWidth;
    if (rightPosition > windowWidth) {
      const overflow = rightPosition - windowWidth;
      this.leftPosition -= overflow;
    }

    if (this.leftPosition < 0) {
      this.leftPosition = 0;
    }
  }
}

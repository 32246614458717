import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { OlmTestClassModule, AlertModule } from '@olmero/shared-ui';
import { OlmTextModule } from '@olmero/shared-core';
import { ToastService } from './services/toast.service';
import { ToastInfoComponent } from '@project-shared/modules/toast/toast-info/toast-info.component';
import { OlmLocaleModule } from '@project-shared/modules/locale/locale.module';

@NgModule({
  declarations: [ToastInfoComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    OlmTextModule,
    OlmTestClassModule,
    RouterModule,
    AlertModule,
    OlmLocaleModule,
  ],
  exports: [ToastInfoComponent],
  providers: [ToastService],
})
export class OlmToastModule {
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TenderTemplate } from '@project-shared/model/tender/tender-template.model';
import { EMPTY, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { ProjectService } from '@project-shared/services/project.service';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { ClientService } from '../services/client.service';

@Injectable()
export class CreateProjectTenderTemplateResolver {

  constructor(private projectService: ProjectService,
              private clientService: ClientService,
              private router: Router,
              private urlHelper: UrlHelper) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderTemplate> {
    return this.projectService.getProjectTenderTemplates(route.params.projectUid).pipe(
      flatMap(tenderTemplate => {
        if (tenderTemplate && tenderTemplate.length > 0) {
          this.router.navigate([this.urlHelper.calculateParentUrl(route)]);
          return EMPTY;
        }
        return this.projectService.getProject(route.params.projectUid)
          .pipe(
            flatMap(project => this.clientService.getClientAndParent(project.client_uid)
              .pipe(
                flatMap(
                  result => {
                    if (!result.client.access.can_create_projects_ng) {
                      this.router.navigate([this.urlHelper.calculateParentUrl(route)]);
                      return EMPTY;
                    }
                    return this.clientService.getClientTenderTemplateForNewProject(project.client_uid)
                      .pipe(
                        map(tenderTemplates => tenderTemplates[0])
                      );
                  }
                )
              )
            ));
      }
      )
    );
  }

}

export interface AttachmentDetailDto {
  name: string;
  url: string;
  date: number;
  category: string;
  description: string;
  size: number;
  sia?: string;
  work_description_attachment_id?: number;
}

export class AttachmentDetail implements AttachmentDetailDto {
  public name: string;
  public url: string;
  public date: number;
  public category: string;
  public description: string;
  public size: number;
  public sia?: string;
  public work_description_attachment_id?: number;

  get isCrbx(): boolean {
    return this.name?.toLowerCase().endsWith('.crbx');
  }

  constructor(init?: AttachmentDetailDto) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

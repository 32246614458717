import { TenderFilterEnum } from '../tender-filter.model';
import { Sort, SortDirection } from '@olmero/shared-ui';

export interface TenderBidderDashboardFilter {
  only_favourites: boolean;
  search_terms: string[];
  statuses: TenderFilterEnum[];
  areas_of_work: string[];
  types_of_work: string[];
  matching_any_types_of_work: boolean;
}

export function defaultFilterCriteria(): TenderBidderDashboardFilter {
  return {
    search_terms: [],
    only_favourites: false,
    matching_any_types_of_work: false,
    statuses: [],
    areas_of_work: [],
    types_of_work: [],
  };
}
export class DashboardFilter {
  filterCriteria?: TenderBidderDashboardFilter;
  defaultCriteria?: boolean;
  numberOfResults?: number;
  sortOrder?: Sort;

  constructor(data: DashboardSettingsDto) {
    if (data) {
      this.filterCriteria = data.filter_criteria;
      this.defaultCriteria = data.default_criteria;
      this.numberOfResults = data.number_of_results;
      this.sortOrder = { property: data.sort_order[0].field_name, direction: data.sort_order[0].direction };
    }
  }
}

export interface DashboardSettingsDto {
  uuid: number;
  default_criteria: boolean;
  filter_name: string;
  number_of_results: number;
  filter_criteria: TenderBidderDashboardFilter;
  sort_order: SortOrderDto[];
}

interface SortOrderDto {
  direction: SortDirection;
  field_name: string;
}

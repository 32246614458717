import { DateFormatterService } from '@project-shared/services/date-formatter.service';


export class DateValidationService {

  private static dateRegex = /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/;

  static isDateValid(day: number, month: number, year: number): boolean {
    if (!this.dateRegex.test(DateFormatterService.formatDay(day) +
        '.' + DateFormatterService.formatMonth(month) +
        '.' + year)) {
      return false;
    }
    return this.validateDate(day, month, year);
  }

  private static isNotLeapYear(month: number, day: number, year: number): boolean {
    return month === 2 && day === 29 && !(year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
  }

  private static validateDate(day: number, month: number, year: number): boolean {
    if (day >= 31 && (month === 4 || month === 6 || month === 9 || month === 11)) {
      return false;
    }

    if (day >= 30 && month === 2) {
      return false;
    }

    if (this.isNotLeapYear(month, day, year)) {
      return false;
    }
    return true;
  }
}

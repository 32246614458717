<ng-container *ngIf="showTitle$ | async">
<ng-container *ngIf="title$ | async as title">
  <h1 class="typo-heading-1 color-primary-blue" [class.title-with-tag-list]="showTabList">
      {{ title }}
    <olm-blacklist></olm-blacklist>
  </h1>
  <div *ngIf="showTabList" class="tab-list-wrap">
    <olm-tag-list></olm-tag-list>
  </div>
</ng-container>
</ng-container>

import { Deduction, DeductionDto } from '@project-shared/model/deduction/deduction.model';
import { Attachment, AttachmentDto } from '../attachment/attachment.model';

export interface TenderTemplateDto {
  uid?: string;
  discount?: number;
  vat: number;
  deductions: DeductionDto[];
  attachments: AttachmentDto[];
}

export class TenderTemplate implements TenderTemplateDto {
  uid?: string;
  discount?: number;
  vat: number;
  deductions: Deduction[];
  attachments: Attachment[];

  constructor(data?: TenderTemplateDto) {
    if (data) {
      Object.assign(this, data);

      if (this.deductions) {
        this.deductions = data.deductions.map(deduction => new Deduction(deduction));
      }

      if (this.attachments) {
        this.attachments = data.attachments.map(attachment => new Attachment(attachment));
      }
    }
  }
}

<div class="date-time-wrapper" [ngClass]="customClass">
  <olm-datepicker
    class="date-picker-wrapper"
    data-testid="test-date"
    (onBlur)="onDateInputBlur($event)"
    (dateChanged)="onDateInputChanged($event)"
    [formControl]="dateControl"
    [placeholder]="placeholder"
    [translatePlaceholder]="translatePlaceholder"
    [min]="min"
    [max]="max"
    tclass="test-date"
    [useAutocomplete]="useAutocomplete"
    [isLuxonDate]="isLuxonDate"
  ></olm-datepicker>

  <olm-time-input
    #timeInputComponent
    class="time-input-container"
    [formControl]="timeControl"
    placeholder="23:59">
  </olm-time-input>
</div>

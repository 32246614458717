import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from '@olmero/shared-core';
import { HelpService } from '@project-shared/services/help.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'olm-help-menu-item',
  templateUrl: './help-menu-item.component.html',
})
export class HelpMenuItemComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();

  helpPageLink: string;

  constructor(
    private languageService: LanguageService,
    private helpService: HelpService
  ) { }

  ngOnInit(): void {
    this.languageService.detectLanguage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lang => this.helpPageLink = this.helpService.getHelpPage(lang));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

}

export interface BidderContactDto {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  fax: string;
}

export class BidderContact implements BidderContactDto {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  fax: string;

  constructor(data?: BidderContactDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

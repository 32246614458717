import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProjectOption } from '@project-shared/model/project/option.model';
import { ProjectDetails } from '@project-shared/model/project/project-details.model';
import { TenderTemplate } from '@project-shared/model/tender/tender-template.model';
import { ProjectService } from '@project-shared/services/project.service';
import { forkJoin, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { ProjectDetailsWithOptions } from './model/project-details-with-options.model';

@Injectable()
export class ProjectResolver {
  constructor(private projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectDetailsWithOptions> {
    return this.projectService.getProject(route.params.projectUid).pipe(
      flatMap(project => forkJoin(
        of(project),
        this.getTenderTemplate(project)
      )),
      map(result => {
        return { projectDetails: result[0], template: result[1][0] };
      })
    );
  }

  private getTenderTemplate(project: ProjectDetails): Observable<TenderTemplate[]> {
    if (project.options && project.options.findIndex(projectOption => projectOption.value === ProjectOption.publisher) > -1) {
      return this.projectService.getProjectTenderTemplates(project.uid);
    }
    return of([]);
  }
}

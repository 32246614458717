import { ContactData, ContactDataDto } from '@project-shared/model/contact.model';
import { DateTime } from 'luxon';


export interface ListProjectDetailsDto {
  project_uid: string;
  project_number: string;
  project_name: string;
  project_status: string;
  project_created: DateTime;
  project_street: string;
  project_zip: string;
  project_city: string;
  client_uid: string;
  client_name: string;
  client_company_name: string;
  client_department: string;
  client_street: string;
  client_city: string;
  client_zip: string;
  client_country_code: string;
  client_kind: string;
  project_pp_url: string;
  project_product: string;
  is_favorite: boolean;
  picture_id?: string;
  thumbnail?: Blob;
  construction_start: DateTime;
  construction_end: DateTime;
  contact: ContactDataDto;
  contract_volume: string;
  project_budget?:number;
}

export class ListProjectDetails implements ListProjectDetailsDto {
  project_uid: string;
  project_number: string;
  project_name: string;
  project_status: string;
  project_created: DateTime;
  project_street: string;
  project_zip: string;
  project_city: string;
  client_uid: string;
  client_name: string;
  client_company_name: string;
  client_department: string;
  client_street: string;
  client_city: string;
  client_zip: string;
  client_country_code: string;
  client_kind: string;
  project_pp_url: string;
  project_product: string;
  is_favorite: boolean;
  construction_start: DateTime;
  construction_end: DateTime;
  contact: ContactData;
  contract_volume: string;
  picture_id?: string;
  thumbnail?: Blob;
  opened?: boolean;
  project_budget?: number;

  constructor(data?: ListProjectDetailsDto) {
    if (data) {
      Object.assign(this, data);

      if (this.project_created) {
        this.project_created = DateTime.fromISO(this.project_created as any);
      }

      if (this.construction_start) {
        this.construction_start = DateTime.fromISO(this.construction_start as any);
      }

      if (this.construction_end) {
        this.construction_end = DateTime.fromISO(this.construction_end as any);
      }

      if (data.contact) {
        this.contact = new ContactData(data.contact);
      }
      this.opened = false;
    }
  }

  getCompanyInfo(): string {
    return [
      [
        this.client_name,
        this.client_department,
      ]
        .filter(Boolean)
        .join(', '),
      [
        this.client_zip,
        this.client_city,
      ]
        .filter(Boolean)
        .join(' '),
    ]
      .filter(Boolean)
      .join(', ');
  }

  displayProjectName(): string {
    return [[this.project_name, this.project_zip].filter(s => !!s).join(', '), this.project_city].filter(s => !!s).join(' ');
  }
}

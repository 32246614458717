import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TenderConfig } from '@project-shared/model/tender-config/tender-config.model';
import { ProjectService } from '@project-shared/services/project.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ClientService } from '../services/client.service';

@Injectable()
export class OlmTenderConfigResolver {

  constructor(private api: ApiService, private clientService: ClientService, private projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderConfig> {
    return this.projectService.getProject(route.params.projectUid).pipe(
      switchMap(project => this.clientService.getClient(project.client_uid)),
      switchMap(client => this.api.getTenderConfig(client.details.uid))
    );
  }

}

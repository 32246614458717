import { ProjectGeometryLevel } from './project-geometry-level';
import { ProjectGeometrySubObject } from './project-geometry-sub-object';

export interface ProjectGeometryObjectDto {
  label: string;
  description: string;
  sub_objects: ProjectGeometrySubObject[];
}

export class ProjectGeometryObject implements ProjectGeometryObjectDto {
  label: string;
  description: string;
  sub_objects: ProjectGeometrySubObject[];
  private VISIBLE? = false;

  get visible(): boolean {
    return this.VISIBLE;
  }

  set visible(value: boolean) {
    this.VISIBLE = value;
    if (this.sub_objects && this.sub_objects.length) {
      for (const [index, val] of this.sub_objects.entries()) {
        val.visible = !value ? value : index === 0;
      }
    }
  }

  constructor(json?: ProjectGeometryObjectDto) {
    if (json) {
      Object.assign(this, json);
      this.deserializeSubObjects(json);
    }
  }

  private deserializeSubObjects(json: ProjectGeometryObjectDto): ProjectGeometrySubObject | void {
    if (json.sub_objects && json.sub_objects.length) {
      this.sub_objects = json.sub_objects.map(subObject => {
        const levels: ProjectGeometryLevel[] = [];
        if (subObject.levels && subObject.levels.length) {
          subObject.levels.forEach(level => {
            levels.push(new ProjectGeometryLevel(level.label, level.description));
          });
        }
        return new ProjectGeometrySubObject(subObject.label, subObject.description, levels);
      });
    }
  }
}

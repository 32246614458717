import { BlacklistHandler } from './blacklist-handler';
import { TextService } from '@olmero/shared-core';

export class ProjectBlacklistHanlder implements BlacklistHandler {

  blacklistedTooltip: string;

  constructor(private blacklisted: boolean,
              private textService: TextService) {
    this.blacklistedTooltip = this.textService.get('blacklist_tooltip_blacklisted');
  }

  isBlacklisted(): boolean {
    return this.blacklisted;
  }

  hasBlacklisted(): boolean {
    return true;
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ClientAndDependencies, ClientAndParent } from '@project-shared/model/api.model';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClientService } from '../services/client.service';

@Injectable()
export class ClientDependenciesResolver {

  constructor(private clientService: ClientService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ClientAndDependencies> {
    const clientUid: string = route.params.clientUid;
    return this.clientService.getClientAndParent(clientUid).pipe(
      switchMap((clientAndParent: ClientAndParent) => {
        return forkJoin(
          of(clientAndParent),
          iif(
            () => clientAndParent.client.access.can_view_client_billing_address,
            this.clientService.getClientBillingAddress(clientUid),
            of(undefined)
          ),
          iif(
            () => !!clientAndParent.parent && !clientAndParent.client.details?.is_private,
            of([clientAndParent.parent]),
            this.clientService.getBranches(clientUid, clientAndParent.client.access.can_disable_client)
          ),
          iif(
            () => !clientAndParent.client.details?.is_private,
            this.clientService.getClientDuplicates(clientUid, { key: 'email', value: clientAndParent.clientContactAddress.email }),
            of(undefined)
          )
        );
      }),
      map(result => {
        const clientAndDependencies = new ClientAndDependencies();
        clientAndDependencies.clientAndParent = result[0];
        clientAndDependencies.clientBillingAddress = result[1];
        clientAndDependencies.dependsOnClientList = result[2];
        clientAndDependencies.duplicate = result[3];
        return clientAndDependencies;
      })
    );
  }

}

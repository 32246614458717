import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@olmero/shared-core';
import { Page } from '@project-shared/services/pagination/model/page.model';
import { PaginationService } from '@project-shared/services/pagination/pagination.service';
import { TypeOfWork, TypeOfWorkDto } from '@olmero/shared-ui';

@Injectable({ providedIn: 'root' })
export class TypeOfWorkService {

  constructor(
    private paginationService: PaginationService,
    private appConfigService: AppConfigService
  ) {
  }

  getTypesOfWorkPage(): Observable<Page<TypeOfWork>> {
    return this.paginationService.queryPaginated<TypeOfWorkDto>(`${this.appConfigService.getConfig().apiUrl}/types-of-work`)
      .pipe(
        map(response => {
          response.results = response.results.map(tow => new TypeOfWork(tow));
          return response as Page<TypeOfWork>;
        })
      );
  }

  getTypeOfWorks(): Observable<TypeOfWork[]> {
    return this.getTypesOfWorkPage()
      .pipe(map((typeOfWorkPage: Page<TypeOfWork>) => this.extractTypesOfWork(typeOfWorkPage.results)));
  }

  extractTypesOfWork(typeOfWorks: TypeOfWork[]): TypeOfWork[] {
    const grainyTypeOfWork: TypeOfWork[] = [];
    typeOfWorks.forEach(typeOfWork => {
      grainyTypeOfWork.push(typeOfWork);
      if (typeOfWork.children.length > 0) {
        grainyTypeOfWork.push(...this.extractTypesOfWork(typeOfWork.children));
      }
    });
    return grainyTypeOfWork;
  }
}

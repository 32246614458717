import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TenderService } from '../services/tender.service';
import { TenderPreview } from '@project-shared/model/tender/tender-preview.model';

@Injectable()
export class TendersResolver {
  constructor(private tenderService: TenderService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderPreview[]> {
    return this.tenderService.getTenders(route.params.projectUid);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { SpinnerDirectiveModule } from '../spinner-directive/spinner-directive.module';
import { OlmTextModule } from '@olmero/shared-core';
import { OlmUiModule } from '../ui/ui.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TitleComponent } from './components/title/title.component';
import { BlacklistModule } from '../blacklist/blacklist.module';
import { OlmTooltipModule } from '@project-shared/modules/olm-tooltip/olm-tooltip.module';
import { PipesModule } from '@olmero/shared-ui';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    TitleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SpinnerDirectiveModule,
    OlmUiModule,
    OlmTextModule,
    FlexLayoutModule,
    BlacklistModule,
    OlmTooltipModule,
    PipesModule,
  ],
  exports: [
    BreadcrumbComponent,
    TitleComponent,
  ],
})
export class OlmBreadcrumbModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Attachment } from '../../../model/attachment/attachment.model';
import { Observable } from 'rxjs';
import { TenderAttachmentApiService } from '../services/tender-attachment-api.service';

@Injectable()
export class TenderAttachmentResolver {
  constructor(private tenderAttachmentApiService: TenderAttachmentApiService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Attachment[]> {
    return this.tenderAttachmentApiService.getTenderAttachments(route.params.tenderUid);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListOptions } from '@project-shared/model/list-options.model';
import { ListDto } from '@project-shared/model/list.model';
import { Page } from './model/page.model';
import { FORCE_REQUEST } from '@project-shared/http-interceptors/cache/cache.interceptor';
import { FilterParamsService } from '@project-shared/modules/api/services/filter-params.service';

@Injectable({ providedIn: 'root' })
export class PaginationService {

  private headers = { [FORCE_REQUEST]: '1' };

  constructor(private httpClient: HttpClient, private filterParamsService: FilterParamsService) {
  }

  queryPaginated<T>(baseUrl: string, listOptions?: ListOptions, param?: HttpParams): Observable<Page<T>> {
    let params = param ? param : new HttpParams();
    params = this.setListOptionsParams(params, listOptions);

    return this.httpClient.get<ListDto<T>>(baseUrl, { params, headers: this.headers }).pipe(
      map(data => new Page<T>(data)));
  }

  queryPaginatedPost<T>(baseUrl: string, body: any, listOptions?: ListOptions): Observable<Page<T>> {
    let params = new HttpParams();
    params = this.setListOptionsParams(params, listOptions);

    return this.httpClient.post<ListDto<T>>(baseUrl, body, { params }).pipe(
      map(data => new Page<T>(data))
    );
  }

  queryPaginatedTenders<T>(baseUrl: string, listOptions?: ListOptions, param?: HttpParams): Observable<Page<T>> {
    let params = param ? param : new HttpParams();
    params = this.setListOptionsParams(params, listOptions);
    return this.httpClient.post<ListDto<T>>(baseUrl, listOptions.dashboardFilter.filterCriteria, {
      params,
      headers: this.headers,
    }).pipe(
      map(data => new Page<T>(data)));
  }

  private setListOptionsParams(params: HttpParams, listOptions?: ListOptions): HttpParams {
    if (listOptions) {
      if (listOptions.sort) {
        params = params.set('sort', `${listOptions.sort.active},${listOptions.sort.direction}`);
      }
      if (listOptions.sortList) {
        listOptions.sortList.forEach(sort => {
          params = params.append('sort', `${sort.active},${sort.direction}`);
        });
      }
      if (listOptions.size) {
        params = params.set('size', listOptions.size.toString());
      }
      if (listOptions.page) {
        params = params.set('page', listOptions.page.toString());
      }
      if (listOptions.filter) {
        params = this.filterParamsService.append(params, listOptions.filter);
      }
      if (listOptions.additionalFilters) {
        for (const key in listOptions.additionalFilters) {
          if (listOptions.additionalFilters.hasOwnProperty(key)) {
            listOptions.additionalFilters[key].forEach(filterValue => {
              params = params.append(key, filterValue);
            });
          }
        }
      }
    }

    return params;
  }
}

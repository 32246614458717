export interface PlanDto {
  project_uid: string;
  file_uid: string;
  file_name: string;
  _links: {
    self: { href: string },
    file: { href: string },
  };
}

export class Plan implements PlanDto {
  project_uid: string;
  file_uid: string;
  file_name: string;
  /* eslint-disable-next-line */
  _links: {
    self: { href: string },
    file: { href: string },
  };

  constructor(data?: PlanDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
}


import { Injectable } from '@angular/core';
import { Attachment } from '@project-shared/model/attachment/attachment.model';
import { AppConfigService } from '@olmero/shared-core';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { AttachmentDetail } from '@project-shared/model/attachment/attachment-detail.model';
import { ApiOauthTokenService } from '@olmero/shared-core';
import { Observable, from } from 'rxjs';
@Injectable()
export class ApiConverterService {

  constructor(private tokenService: ApiOauthTokenService, private urlHelper: UrlHelper, private appConfigService: AppConfigService) { }

  convertAttachmentsToAttachmentsDetails(attachments: Attachment[]): Observable<AttachmentDetail[]> {
    return from(this.tokenService.getAccessToken().then(token => {
      return attachments.map(attachment => {
        const attachmentDetail = new AttachmentDetail();
        attachmentDetail.name = attachment.name;
        attachmentDetail.url = this.calculateUrlWithToken(attachment.file.href, attachment.name, token);
        attachmentDetail.date = attachment.file.createdAt;
        attachmentDetail.category = attachment?.category;
        attachmentDetail.description = attachment.description;
        attachmentDetail.size = attachment.file.length;
        attachmentDetail.sia = attachment.sia ?
          this.urlHelper.putParams(attachment.sia, { at: token }) :
          attachment.sia;
        attachmentDetail.work_description_attachment_id = attachment.work_description_attachment_id;
        return attachmentDetail;
      });
    }));

  }

  public calculateUrl(relativeHref: string, name: string): Observable<string> {
    return from(this.tokenService.getAccessToken().then(token => {
      return this.buildUrl(relativeHref, name, token);
    }));
  }

  public calculateUrlForAttachments(attachments: Attachment[]): Observable<Attachment[]> {
    return from(this.tokenService.getAccessToken().then(token => {
      return attachments.map(attachment => {
        attachment.file.href = this.buildUrl(attachment.file.href, attachment.name, token);
        return attachment;
      });
    }));
  }

  public calculateUrlWithToken(relativeHref: string, name: string, token: string): string {
    return this.buildUrl(relativeHref, name, token);
  }

  private buildUrl(relativeHref: string, name: string, token: string): string {

    if (relativeHref.includes('go?jwt')) {
      return this.urlHelper.putParams(relativeHref,
                                      { at: token });
    } else {
      let host = location.protocol + '//' + window.location.hostname;

      // Dev only
      if (host.includes('localhost')) {
        host = host.concat(`:${window.location.port}`);
      }

      const apiUrl = this.appConfigService.getConfig().apiUrl;
      const href = relativeHref.startsWith('http') ? relativeHref : host + apiUrl + relativeHref;

      if (href.includes('force_name=') || name == null || name.trim().length === 0) {
        return href;
      } else if (href.includes('?')) {
        return href + '&force_name=' + encodeURIComponent(name);
      } else {
        return href + '?force_name=' + encodeURIComponent(name);
      }
    }

  }

}

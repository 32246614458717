/* eslint-disable @typescript-eslint/naming-convention */
export const typeOfWorksTexts: Record<string, any> = {
  'type-of-work-B0000': $localize`:@@type-of-work-B0000:Developers`,
  'type-of-work-B1000': $localize`:@@type-of-work-B1000:Developers`,
  'type-of-work-B1020': $localize`:@@type-of-work-B1020:Building contractor`,
  'type-of-work-B1021': $localize`:@@type-of-work-B1021:Total contractor`,
  'type-of-work-B1022': $localize`:@@type-of-work-B1022:General Contractors`,
  'type-of-work-B2000': $localize`:@@type-of-work-B2000:Planner`,
  'type-of-work-B2010': $localize`:@@type-of-work-B2010:Project manager`,
  'type-of-work-B2012': $localize`:@@type-of-work-B2012:Total planner`,
  'type-of-work-B2030': $localize`:@@type-of-work-B2030:Plan manager`,
  'type-of-work-B2040': $localize`:@@type-of-work-B2040:Planning permission Arc.`,
  'type-of-work-B2050': $localize`:@@type-of-work-B2050:BIM coordinator`,
  'type-of-work-K0000': $localize`:@@type-of-work-K0000:Consulting services`,
  'type-of-work-K1000': $localize`:@@type-of-work-K1000:Architectural services`,
  'type-of-work-K1010': $localize`:@@type-of-work-K1010:Architectural services for buildings`,
  'type-of-work-K1011': $localize`:@@type-of-work-K1011:Interior design services`,
  'type-of-work-K1012': $localize`:@@type-of-work-K1012:Landscape architectural services`,
  'type-of-work-K2000': $localize`:@@type-of-work-K2000:Consulting services ground`,
  'type-of-work-K2010': $localize`:@@type-of-work-K2010:Ground consultants`,
  'type-of-work-K2011': $localize`:@@type-of-work-K2011:Geologics engineer`,
  'type-of-work-K2012': $localize`:@@type-of-work-K2012:Road planner`,
  'type-of-work-K2013': $localize`:@@type-of-work-K2013:Bridge planner`,
  'type-of-work-K2020': $localize`:@@type-of-work-K2020:Railway planner`,
  'type-of-work-K2030': $localize`:@@type-of-work-K2030:District heating engineer`,
  'type-of-work-K2090': $localize`:@@type-of-work-K2090:Other ground planners`,
  'type-of-work-K3000': $localize`:@@type-of-work-K3000:Consulting services`,
  'type-of-work-K3010': $localize`:@@type-of-work-K3010:Structural engineering consultancy services`,
  'type-of-work-K3011': $localize`:@@type-of-work-K3011:Building consultant`,
  'type-of-work-K3012': $localize`:@@type-of-work-K3012:Investigations consultant`,
  'type-of-work-K3013': $localize`:@@type-of-work-K3013:Calculation consultant`,
  'type-of-work-K3014': $localize`:@@type-of-work-K3014:Description consultant`,
  'type-of-work-K3040': $localize`:@@type-of-work-K3040:Sustainability consultant`,
  'type-of-work-K3050': $localize`:@@type-of-work-K3050:Consulting engineer building physics`,
  'type-of-work-K3060': $localize`:@@type-of-work-K3060:Inspection consultant`,
  'type-of-work-K3090': $localize`:@@type-of-work-K3090:Other construction consults`,
  'type-of-work-K4000': $localize`:@@type-of-work-K4000:Installation services of steam generators`,
  'type-of-work-K4010': $localize`:@@type-of-work-K4010:Heating-system design services`,
  'type-of-work-K4011': $localize`:@@type-of-work-K4011:Fire sprinkler consultant`,
  'type-of-work-K4020': $localize`:@@type-of-work-K4020:Electrical engineer`,
  'type-of-work-K4021': $localize`:@@type-of-work-K4021:Energy consultant`,
  'type-of-work-K4022': $localize`:@@type-of-work-K4022:Telecom consultant`,
  'type-of-work-K4023': $localize`:@@type-of-work-K4023:Lighting consultant`,
  'type-of-work-K4040': $localize`:@@type-of-work-K4040:Ventilation consultancy services`,
  'type-of-work-K4050': $localize`:@@type-of-work-K4050:Control & regulation consultant`,
  'type-of-work-K4060': $localize`:@@type-of-work-K4060:Lift engineer`,
  'type-of-work-K4070': $localize`:@@type-of-work-K4070:Cooling engineer`,
  'type-of-work-K4090': $localize`:@@type-of-work-K4090:Other installation consultants`,
  'type-of-work-M0000': $localize`:@@type-of-work-M0000:Material`,
  'type-of-work-M0100': $localize`:@@type-of-work-M0100:Ground`,
  'type-of-work-M0110': $localize`:@@type-of-work-M0110:Filler`,
  'type-of-work-M0120': $localize`:@@type-of-work-M0120:Gravels`,
  'type-of-work-M0130': $localize`:@@type-of-work-M0130:Boulders`,
  'type-of-work-M0140': $localize`:@@type-of-work-M0140:Crushed Basalt`,
  'type-of-work-M0150': $localize`:@@type-of-work-M0150:Concrete Pipes Flanges Rings and Cones`,
  'type-of-work-M0160': $localize`:@@type-of-work-M0160:Geotextiles and Drainage Blankets`,
  'type-of-work-M0500': $localize`:@@type-of-work-M0500:Construction`,
  'type-of-work-M0510': $localize`:@@type-of-work-M0510:Construction/Home improvement`,
  'type-of-work-M1200': $localize`:@@type-of-work-M1200:Concrete`,
  'type-of-work-M1210': $localize`:@@type-of-work-M1210:Ready-Mixed Concrete`,
  'type-of-work-M1220': $localize`:@@type-of-work-M1220:Dry Mortars`,
  'type-of-work-M1230': $localize`:@@type-of-work-M1230:Concrete Floor Stone`,
  'type-of-work-M1250': $localize`:@@type-of-work-M1250:Prefabricated Pillars Girders and Slabs`,
  'type-of-work-M1260': $localize`:@@type-of-work-M1260:Concrete Blocks`,
  'type-of-work-M1300': $localize`:@@type-of-work-M1300:Stone and Fire brick`,
  'type-of-work-M1310': $localize`:@@type-of-work-M1310:Concrete Blocks with Expanded Clay`,
  'type-of-work-M1320': $localize`:@@type-of-work-M1320:Fire Brick`,
  'type-of-work-M1330': $localize`:@@type-of-work-M1330:Slate`,
  'type-of-work-M1350': $localize`:@@type-of-work-M1350:Stone in Mosaic or by the Piece`,
  'type-of-work-M1700': $localize`:@@type-of-work-M1700:Wooden materials`,
  'type-of-work-M1710': $localize`:@@type-of-work-M1710:Wooden materials`,
  'type-of-work-M1720': $localize`:@@type-of-work-M1720:Skirting boards`,
  'type-of-work-M1900': $localize`:@@type-of-work-M1900:Plastic materials`,
  'type-of-work-M1910': $localize`:@@type-of-work-M1910:Plastic sheeting`,
  'type-of-work-M1930': $localize`:@@type-of-work-M1930:PVC Pipes`,
  'type-of-work-M1940': $localize`:@@type-of-work-M1940:Expanded Polystyrene`,
  'type-of-work-M2200': $localize`:@@type-of-work-M2200:Roofing felt`,
  'type-of-work-M2210': $localize`:@@type-of-work-M2210:Roofing felt`,
  'type-of-work-M2220': $localize`:@@type-of-work-M2220:Gutters and Drainpipes`,
  'type-of-work-M2230': $localize`:@@type-of-work-M2230:Steel Sheets`,
  'type-of-work-M2240': $localize`:@@type-of-work-M2240:Clay Tiles and Accessories`,
  'type-of-work-M2245': $localize`:@@type-of-work-M2245:Rebar for Concrete`,
  'type-of-work-M2300': $localize`:@@type-of-work-M2300:Boards`,
  'type-of-work-M2305': $localize`:@@type-of-work-M2305:Gypsum boards`,
  'type-of-work-M2310': $localize`:@@type-of-work-M2310:Concrete fiber board`,
  'type-of-work-M2320': $localize`:@@type-of-work-M2320:Wood Fibre Panels,`,
  'type-of-work-M2330': $localize`:@@type-of-work-M2330:Wood Particleboard`,
  'type-of-work-M2340': $localize`:@@type-of-work-M2340:Wood and Cement Panels`,
  'type-of-work-M2500': $localize`:@@type-of-work-M2500:Insulation`,
  'type-of-work-M2510': $localize`:@@type-of-work-M2510:Glass Wool`,
  'type-of-work-M2600': $localize`:@@type-of-work-M2600:Chemical`,
  'type-of-work-M2610': $localize`:@@type-of-work-M2610:Chemical Products for Waterproofing`,
  'type-of-work-M2620': $localize`:@@type-of-work-M2620:Bentonite`,
  'type-of-work-M2800': $localize`:@@type-of-work-M2800:Prefabricated`,
  'type-of-work-M2810': $localize`:@@type-of-work-M2810:Prefabricated Wooden Houses and Sheds`,
  'type-of-work-M2815': $localize`:@@type-of-work-M2815:Pre-fabricated wooden elements`,
  'type-of-work-M2821': $localize`:@@type-of-work-M2821:Concrete Panels for Walls`,
  'type-of-work-M2830': $localize`:@@type-of-work-M2830:Steel Pipes for Structures`,
  'type-of-work-M2831': $localize`:@@type-of-work-M2831:Handrails and Balustrades`,
  'type-of-work-M2832': $localize`:@@type-of-work-M2832:Metal Stairs, Ramps and Walkways`,
  'type-of-work-M2840': $localize`:@@type-of-work-M2840:Halls`,
  'type-of-work-M2900': $localize`:@@type-of-work-M2900:Complementary Goods`,
  'type-of-work-M2910': $localize`:@@type-of-work-M2910:Windows`,
  'type-of-work-M2920': $localize`:@@type-of-work-M2920:Steel Profiles`,
  'type-of-work-M2930': $localize`:@@type-of-work-M2930:Roll-up Shutters`,
  'type-of-work-M2940': $localize`:@@type-of-work-M2940:Safety Doors`,
  'type-of-work-M2941': $localize`:@@type-of-work-M2941:Wooden Doors`,
  'type-of-work-M2942': $localize`:@@type-of-work-M2942:Plastic Doors`,
  'type-of-work-M2943': $localize`:@@type-of-work-M2943:Wooden Frames (Doors Windows ...)`,
  'type-of-work-M2944': $localize`:@@type-of-work-M2944:Metal Doors`,
  'type-of-work-M2950': $localize`:@@type-of-work-M2950:Balconies`,
  'type-of-work-M2960': $localize`:@@type-of-work-M2960:Rolling Shutters`,
  'type-of-work-M2970': $localize`:@@type-of-work-M2970:Ventilation shafts`,
  'type-of-work-M3000': $localize`:@@type-of-work-M3000:Room complementary goods`,
  'type-of-work-M3005': $localize`:@@type-of-work-M3005:Commercial kitchen fittings`,
  'type-of-work-M3010': $localize`:@@type-of-work-M3010:Kitchen Fittings and Furniture`,
  'type-of-work-M3011': $localize`:@@type-of-work-M3011:General Kitchen Appliances`,
  'type-of-work-M3022': $localize`:@@type-of-work-M3022:Safes and safe deposit boxes`,
  'type-of-work-M5000': $localize`:@@type-of-work-M5000:Coating`,
  'type-of-work-M5010': $localize`:@@type-of-work-M5010:Stone for Walls (Granite, Marble, ...)`,
  'type-of-work-M5011': $localize`:@@type-of-work-M5011:Glazed Tiles, Brick Tiles, Slabs and Glazed Ceramic or Porcelain Stoneware Mosaics`,
  'type-of-work-M5020': $localize`:@@type-of-work-M5020:Tiles, Parquet, Wood, Floor Boards, Skirting Boards`,
  'type-of-work-M5021': $localize`:@@type-of-work-M5021:Linoleum`,
  'type-of-work-M5030': $localize`:@@type-of-work-M5030:Acoustic Ceilings`,
  'type-of-work-M5040': $localize`:@@type-of-work-M5040:Additives / Chemicals for Paints`,
  'type-of-work-M6000': $localize`:@@type-of-work-M6000:Installation products`,
  'type-of-work-M6010': $localize`:@@type-of-work-M6010:Central Heating Equipment`,
  'type-of-work-M6011': $localize`:@@type-of-work-M6011:Passive Fire Protection`,
  'type-of-work-M6020': $localize`:@@type-of-work-M6020:Air Conditioning and Ventilation Equipment`,
  'type-of-work-M6030': $localize`:@@type-of-work-M6030:Appliances and Lighting`,
  'type-of-work-M6040': $localize`:@@type-of-work-M6040:Other HVAC Equipment`,
  'type-of-work-S0000': $localize`:@@type-of-work-S0000:Services / Works`,
  'type-of-work-S1000': $localize`:@@type-of-work-S1000:Ground services`,
  'type-of-work-S1010': $localize`:@@type-of-work-S1010:Earth Levelling Works`,
  'type-of-work-S1011': $localize`:@@type-of-work-S1011:Pile Driving`,
  'type-of-work-S1020': $localize`:@@type-of-work-S1020:Felling/Tree felling`,
  'type-of-work-S1021': $localize`:@@type-of-work-S1021:Environmental protection`,
  'type-of-work-S1022': $localize`:@@type-of-work-S1022:Earth Moving Works`,
  'type-of-work-S1023': $localize`:@@type-of-work-S1023:Water and Drainage Pumps`,
  'type-of-work-S1025': $localize`:@@type-of-work-S1025:Mining contractor`,
  'type-of-work-S1026': $localize`:@@type-of-work-S1026:Drilling`,
  'type-of-work-S1030': $localize`:@@type-of-work-S1030:Hot Bitumen Paving`,
  'type-of-work-S1031': $localize`:@@type-of-work-S1031:Horizontal Road Signs`,
  'type-of-work-S1032': $localize`:@@type-of-work-S1032:Metal Guards (Rails)`,
  'type-of-work-S1033': $localize`:@@type-of-work-S1033:Protection and Fencing`,
  'type-of-work-S1034': $localize`:@@type-of-work-S1034:Wooden Acoustic Barriers`,
  'type-of-work-S1040': $localize`:@@type-of-work-S1040:Building up Roads Using Rock Blocks`,
  'type-of-work-S1050': $localize`:@@type-of-work-S1050:General Outdoor Landscaping`,
  'type-of-work-S1060': $localize`:@@type-of-work-S1060:Complete Engineering Works (Bridges Viaducts)`,
  'type-of-work-S1070': $localize`:@@type-of-work-S1070:Implementing Geo-drains`,
  'type-of-work-S1080': $localize`:@@type-of-work-S1080:Formwork, Shoring, Concreting and Removal of Formwork from Concrete Elements`,
  'type-of-work-S1090': $localize`:@@type-of-work-S1090:Boulders and Stones for Pavements`,
  'type-of-work-S1100': $localize`:@@type-of-work-S1100:Cutting Shaping and Placing Steel for Reinforced Concrete`,
  'type-of-work-S1200': $localize`:@@type-of-work-S1200:Concrete`,
  'type-of-work-S1300': $localize`:@@type-of-work-S1300:Stone works`,
  'type-of-work-S1400': $localize`:@@type-of-work-S1400:Reinforcements`,
  'type-of-work-S2700': $localize`:@@type-of-work-S2700:Frame contractors`,
  'type-of-work-S2710': $localize`:@@type-of-work-S2710:Complete Prefabricated Constructions`,
  'type-of-work-S2720': $localize`:@@type-of-work-S2720:Complete Reinforced Concrete Structures`,
  'type-of-work-S2730': $localize`:@@type-of-work-S2730:Wooden Structures (Solid, Laminate, ...)`,
  'type-of-work-S2740': $localize`:@@type-of-work-S2740:Metal Structures for Buildings and Others`,
  'type-of-work-S2751': $localize`:@@type-of-work-S2751:Waterproofing with Bentonite Fabric`,
  'type-of-work-S2760': $localize`:@@type-of-work-S2760:Slate`,
  'type-of-work-S2761': $localize`:@@type-of-work-S2761:Sprayed Gypsum Spreading and Finishing`,
  'type-of-work-S2765': $localize`:@@type-of-work-S2765:Scaffolding contract`,
  'type-of-work-S2800': $localize`:@@type-of-work-S2800:Complementary services`,
  'type-of-work-S2810': $localize`:@@type-of-work-S2810:Acoustic Doors and Windows`,
  'type-of-work-S2821': $localize`:@@type-of-work-S2821:Safety Doors`,
  'type-of-work-S2822': $localize`:@@type-of-work-S2822:Sectioned Doors`,
  'type-of-work-S2825': $localize`:@@type-of-work-S2825:Glazing contractor`,
  'type-of-work-S2830': $localize`:@@type-of-work-S2830:Ferrous Metals (Iron, Steel, Stainless Steel, ...)`,
  'type-of-work-S2831': $localize`:@@type-of-work-S2831:Aluminium Working,`,
  'type-of-work-S2832': $localize`:@@type-of-work-S2832:Pre-lacquered Metal Sheet Roofs`,
  'type-of-work-S2840': $localize`:@@type-of-work-S2840:Balcony contractors`,
  'type-of-work-S2850': $localize`:@@type-of-work-S2850:Curtains, Venetian Blinds`,
  'type-of-work-S2860': $localize`:@@type-of-work-S2860:False Floors`,
  'type-of-work-S2870': $localize`:@@type-of-work-S2870:Self-Levelling Floors`,
  'type-of-work-S2880': $localize`:@@type-of-work-S2880:Plasterboard Walls or Drywalls`,
  'type-of-work-S2885': $localize`:@@type-of-work-S2885:Insulation contracting`,
  'type-of-work-S2890': $localize`:@@type-of-work-S2890:Concrete Stairs Ramps and Walkways`,
  'type-of-work-S2900': $localize`:@@type-of-work-S2900:Room complementary services`,
  'type-of-work-S2905': $localize`:@@type-of-work-S2905:Large kitchen interior`,
  'type-of-work-S2906': $localize`:@@type-of-work-S2906:Refrigeration Contractor`,
  'type-of-work-S2910': $localize`:@@type-of-work-S2910:Kitchen Furniture and Fittings`,
  'type-of-work-S2920': $localize`:@@type-of-work-S2920:Fine carpentry`,
  'type-of-work-S2930': $localize`:@@type-of-work-S2930:Security contractor`,
  'type-of-work-S3000': $localize`:@@type-of-work-S3000:Surface layer`,
  'type-of-work-S3010': $localize`:@@type-of-work-S3010:Granite in Mosaic or by the Piece`,
  'type-of-work-S3020': $localize`:@@type-of-work-S3020:Ceramic Coverings (Mosaic, Tiles, Glazed Tiles)`,
  'type-of-work-S3030': $localize`:@@type-of-work-S3030:Textiles`,
  'type-of-work-S3035': $localize`:@@type-of-work-S3035:Mass floor contracting`,
  'type-of-work-S3040': $localize`:@@type-of-work-S3040:Textile flooring contractor`,
  'type-of-work-S3045': $localize`:@@type-of-work-S3045:Wooden floor contractor`,
  'type-of-work-S3050': $localize`:@@type-of-work-S3050:Metal Ceilings`,
  'type-of-work-S3060': $localize`:@@type-of-work-S3060:General Painting`,
  'type-of-work-S6000': $localize`:@@type-of-work-S6000:Installations`,
  'type-of-work-S6010': $localize`:@@type-of-work-S6010:Installation services water and heating`,
  'type-of-work-S6011': $localize`:@@type-of-work-S6011:Water Curtains and Sprinklers`,
  'type-of-work-S6012': $localize`:@@type-of-work-S6012:Domestic Water Fittings and Equipment`,
  'type-of-work-S6020': $localize`:@@type-of-work-S6020:HVAC Systems and Installation`,
  'type-of-work-S6030': $localize`:@@type-of-work-S6030:Electrical Installations and Lighting`,
  'type-of-work-S6035': $localize`:@@type-of-work-S6035:Solar cell contractor`,
  'type-of-work-S6040': $localize`:@@type-of-work-S6040:HVAC Automation and Control Systems`,
  'type-of-work-S6050': $localize`:@@type-of-work-S6050:Lifts, Moving Staircases, and Moving Walkways`,
  'type-of-work-S7000': $localize`:@@type-of-work-S7000:Demolition work`,
  'type-of-work-S7010': $localize`:@@type-of-work-S7010:Demolition Work (Reinforced Concrete, Masonry, ...)`,

};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListOptions } from '@project-shared/model/list-options.model';
import { AppConfigService } from '@olmero/shared-core';
import {
  ListProjectDetails,
  ListProjectDetailsDto
} from '@project-shared/model/project-list/list-project-details.model';
import { Member } from '@project-shared/model/member.model';
import { ProjectListFilter } from '@project-shared/modules/api/services/project-list.service';
import { Page } from './pagination/model/page.model';
import { PaginationService } from './pagination/pagination.service';
import { MemberClient, MemberClientDto } from '@project-shared/model/member/member-client.model';
import { MemberResponseDto } from '@project-shared/model/member/member-response.model';

@Injectable({ providedIn: 'root' })
export class MemberService {

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService,
              private paginationService: PaginationService) {
  }

  getMember(memberUid: string): Observable<Member> {
    return this.http.get<MemberResponseDto>(`${this.appConfigService.getConfig().apiUrl}/members/${memberUid}`)
      .pipe(
        map(response => new Member(response.details, response.access, response.last_login))
      );
  }

  getMemberClients(memberUid: string): Observable<MemberClient[]> {
    return this.http.get<MemberClientDto[]>(`${this.appConfigService.getConfig().apiUrl}/members/${memberUid}/clients`).pipe(
      map(response => response.map(value => new MemberClient(value)))
    );
  }

  updateMemberClient(clientUid: string, memberUid: string): Observable<void> {
    return this.http.put<void>(`${this.appConfigService.getConfig().apiUrl}/members/${memberUid}/client`, { clientUid });
  }

  updateMember(member: Member): Observable<Member> {
    const memberObj: Member = this.prepareData(member);
    return this.http.put<MemberResponseDto>(`${this.appConfigService.getConfig().apiUrl}/members/${memberObj.uid}`, memberObj).pipe(
      map(response => new Member(response.details, response.access))
    );
  }

  getFavoriteProjects(clientUid: string, filter: ProjectListFilter, listOptions: ListOptions): Observable<Page<ListProjectDetails>> {
    return this.paginationService.queryPaginated<ListProjectDetailsDto>(
      `${this.appConfigService.getConfig().apiUrl}/v4/projects${filter ?
        `?${filter.filter}=${filter.value}` : ''}${clientUid ? `&filter-client-uid=${clientUid}` : ''}`,
      listOptions)
      .pipe(
        map(page => {
          page.results = page.results.map(value => new ListProjectDetails(value));
          return page as Page<ListProjectDetails>;
        })
      );
  }

  private prepareData(member: Member): Member {
    if (member.salutation) {
      if (member.salutation === 'm') {
        member.salutation = 'Anrede-Herr';
      } else if (member.salutation === 'w') {
        member.salutation = 'Anrede-Frau';
      }
    }

    return member;
  }
}

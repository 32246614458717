import { ClientAccess } from '@olmero/shared-core';

export interface MemberClientDto {
  uid: string;
  name: string;
  department: string;
  city: string;
  state: string;
  clientAccess: ClientAccess;
}

export class MemberClient implements MemberClientDto {
  uid: string;
  name: string;
  department: string;
  city: string;
  state: string;
  clientAccess: ClientAccess;

  constructor(data?: MemberClient) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

import { default as moment } from 'moment';
import { Moment } from 'moment';

export interface OpenTenderDto {
  name: string;
  submission_date: Moment;
  offers: number;
  will_offer: number;
  unsubscribed: number;
  invited: number;
  uid: string;
}

export class OpenTender implements OpenTenderDto {
  name: string;
  submission_date: Moment;
  offers: number;
  will_offer: number;
  unsubscribed: number;
  invited: number;
  uid: string;

  constructor(data?: OpenTenderDto) {
    if (data) {
      Object.assign(this, data);

      if (this.submission_date) {
        this.submission_date = moment(this.submission_date);
      }
    }
  }
}

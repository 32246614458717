export interface TenderContactPersonDto {
  firstName?: string;
  lastName?: string;
}

export class TenderContactPerson {
  firstName?: string;
  lastName?: string;

  constructor(json: TenderContactPersonDto) {
    if (json) {
      Object.assign(this, json);
    }
  }
}
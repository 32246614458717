import { ProjectAndClientAndParent } from '@project-shared/model/api.model';
import { TenderConfig } from '@project-shared/model/tender-config/tender-config.model';
import { TypeOfWork } from '@olmero/shared-ui';

export class TenderCreateData {
  tenderConfig: TenderConfig;
  projectAndClient: ProjectAndClientAndParent;
  typesOfWork: TypeOfWork[];

  constructor(
    projectAndClient: ProjectAndClientAndParent,
    tenderConfig: TenderConfig,
    typesOfWork: TypeOfWork[]
  ) {
    this.projectAndClient = projectAndClient;
    this.tenderConfig = tenderConfig;
    this.typesOfWork = typesOfWork;

  }


}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerDirective } from './spinner.directive';
import { ButtonSpinnerDirective } from './button-spinner.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SpinnerDirective,
    ButtonSpinnerDirective,
  ],
  exports: [
    SpinnerDirective,
    ButtonSpinnerDirective,
  ],
})
export class SpinnerDirectiveModule {
}

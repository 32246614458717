import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as HttpStatus from 'http-status-codes';
import { AppConfigService } from '@olmero/shared-core';
import { LoggerService } from './logger.service';
import { appRoutesNames } from '../../app.routes.names';
import { ErrorToastProxyService } from './error-proxy/error-toast-proxy.service';
import { ForbiddenToastOpener } from './error-proxy/forbidden-toast-opener';
import { ServerErrorToastOpener } from './error-proxy/server-error-toast-opener';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  private static LAST_CHUNK_ERROR = 'lastChunkError';

  constructor(private injector: Injector,
              private appConfigService: AppConfigService,
              private errorSnackBarProxyService: ErrorToastProxyService,
              private loggerService: LoggerService,
              private ngZone: NgZone) {
    super();
  }

  handleError(error: any): void {
    // checking if the chunk error
    if (error.message && error.message.includes('Error: Loading chunk')) {
      // Check if is the first time the error happend
      if (localStorage.getItem(ErrorHandlerService.LAST_CHUNK_ERROR) !== error.message) {
        // Save the last error to avoid an infinite reload loop if the chunk really does not exists after reload
        localStorage.setItem(ErrorHandlerService.LAST_CHUNK_ERROR, error.message);
        location.reload();
      }
    }

    // handle with default error handler...
    if (this.appConfigService.getConfig().consoleLoggingEnabled) {
      super.handleError(error);
    }

    const originalError = this.unwrapError(error);
    if (originalError instanceof HttpErrorResponse) {
      const router = this.injector.get(Router);
      if (originalError.status === HttpStatus.NOT_FOUND && router.url !== `/${HttpStatus.NOT_FOUND}`) {
        this.ngZone.run(() => router.navigate([appRoutesNames.CODE_404], { skipLocationChange: true }));
      }

      if (originalError.status === HttpStatus.FORBIDDEN && router.url !== `/${HttpStatus.FORBIDDEN}`) {
        this.ngZone.run(() => this.errorSnackBarProxyService.throwError(new ForbiddenToastOpener()));
      }

      if (originalError.status >= HttpStatus.INTERNAL_SERVER_ERROR && router.url !== `/${HttpStatus.INTERNAL_SERVER_ERROR}`) {
        // opening the snackbar inside an ngZone is needed because of a bug in angular
        // see https://github.com/angular/angular/issues/20290 for more details
        // if it's not inside ngZone, there are bugs like the user is not able to close the snackbar
        // or routing from the snackbar to an error page results in only halfway routing to it (content of page where
        // routing started stays and half of the new page is prepended)
        // apparently, handleError is run outside ngZone (https://blog.ninja-squad.com/2018/10/18/what-is-new-angular-7/)
        // see https://github.com/angular/angular/blob/5296c04f61bd370e7031ee7072ced586faadcfba/packages/core/src/application_ref.ts#L651
        // (there are other places inside that class that show the same behavior)
        // Maybe it's the fact that it's a problem with BrowserAnimationsModule/NoopAnimationsModule (as mentioned in the github issues above)
        // that is used by angular material and therefore our snackbar which requires it to be run inside an ngZone again until they fix it
        this.ngZone.run(() => this.errorSnackBarProxyService.throwError(new ServerErrorToastOpener()));
      }
    } else {
      // api request errors are already logged on interceptor levels, here we log all the others
      this.loggerService.logError(error);
    }
  }

  private unwrapError(error: any): Error {
    return error.rejection ? error.rejection : error;
  }
}

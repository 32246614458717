import { Injectable } from '@angular/core';
import { default as moment } from 'moment';
import { Moment } from 'moment';
import { DateConfig } from './date-config.service';
import { DateValidationService } from './date-validation.service';
import { DateTime } from 'luxon';

@Injectable()
export class DateInterpretationService {

  private currentDate: Moment;
  private luxonCurrentDate: DateTime;

  constructor(private dateConfig: DateConfig) {
    this.currentDate = dateConfig.getDate();
    this.luxonCurrentDate = DateTime.local();
  }

  interpretInput(input: string, useLuxon?: boolean): Moment | DateTime {
    const possibleDay = input.match(/\d{1,2}/);
    if (possibleDay === null || possibleDay.length === 0) {
      return useLuxon ? this.luxonCurrentDate : this.currentDate;
    }
    let indexOfDay = input.indexOf(possibleDay[0]) + 1;
    if (possibleDay[0].length === 2) {
      indexOfDay++;
    }

    const partTwo = input.substring(indexOfDay, input.length);
    const possibleMonth = partTwo.match(/\d{1,2}/);
    if (possibleMonth === null || possibleMonth.length === 0) {
      return this.calculateDate(parseInt(possibleDay[0], 10));
    }
    let indexOfMonth = partTwo.indexOf(possibleMonth[0]) + 1;
    if (possibleMonth[0].length === 2) {
      indexOfMonth++;
    }

    const possibleYear = partTwo.substring(indexOfMonth, partTwo.length).match(/(\d{1,4})/);
    if (possibleYear === null) {
      return this.calculateDate(parseInt(possibleDay[0], 10), parseInt(possibleMonth[0], 10), undefined, useLuxon);
    }

    return this.calculateDate(parseInt(possibleDay[0], 10), parseInt(possibleMonth[0], 10), parseInt(possibleYear[0], 10), useLuxon);
  }

  private calculateDate(possibleDay: number, possibleMonth?: number, possibleYear?: number, useLuxon?: boolean): Moment | DateTime {
    possibleMonth = possibleMonth || this.currentDate.get('month') + 1;
    possibleYear = possibleYear || this.currentDate.get('year');

    if (('' + possibleYear).length === 2) {
      possibleYear = Math.floor(this.currentDate.get('year') / 100) * 100 + possibleYear;
    }

    if (DateValidationService.isDateValid(possibleDay, possibleMonth, possibleYear)) {
      if (useLuxon) {
        return DateTime.fromObject({
          day: possibleDay,
          month: possibleMonth,
          year: possibleYear,
        });
      }

      return moment().set({ date: possibleDay, month: possibleMonth - 1, year: possibleYear });
    } else {
      return this.currentDate;
    }
  }
}

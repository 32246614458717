import { NgModule } from '@angular/core';
import { OlmToastModule } from '@project-shared/modules/toast/toast.module';
import { OlmTextModule } from '@olmero/shared-core';
import { CommonModule } from '@angular/common';
import { SpinnerDirectiveModule } from '../spinner-directive/spinner-directive.module';
import { BlacklistComponent } from './components/blacklist/blacklist.component';
import { FeatureFlagModule } from '../feature-flag/feature-flag.module';
import { BlacklistService } from './services/blacklist.service';
import { OlmTooltipModule } from '@project-shared/modules/olm-tooltip/olm-tooltip.module';

@NgModule({
  imports: [
    OlmToastModule,
    OlmTextModule,
    CommonModule,
    SpinnerDirectiveModule,
    FeatureFlagModule,
    OlmTooltipModule,
  ],
  declarations: [BlacklistComponent],
  providers: [BlacklistService],
  exports: [BlacklistComponent],
})
export class BlacklistModule {

}

import { default as moment, Moment } from 'moment';
import { MemberAccess, MemberAccessDto } from './member/member-access.model';
import { DateTime } from 'luxon';

export interface MemberDto {
  client_uid: string;
  company: string;
  created: Moment | DateTime;
  email: string;
  fax_number: string;
  first_name: string;
  is_active: boolean;
  is_administrator: boolean;
  language_code: string;
  last_name: string;
  mobile_number: string;
  phone_number: string;
  position: string;
  salutation: string;
  uid: string;
  username: string;
  last_login?: Moment;
  legacy_olmero_id?: string;

}

export class Member implements MemberDto {
  client_uid: string;
  company: string;
  created: Moment;
  email: string;
  fax_number: string;
  first_name: string;
  is_active: boolean;
  is_administrator: boolean;
  language_code: string;
  last_name: string;
  mobile_number: string;
  phone_number: string;
  position: string;
  salutation: string;
  uid: string;
  username: string;
  legacy_olmero_id?: string;
  access: MemberAccess;
  last_login?: Moment;

  constructor(data?: MemberDto, access?: MemberAccessDto, lastLogin?: string) {
    if (data) {
      Object.assign(this, data);

      if (this.created) {
        this.created = moment(this.created);
      }

      if (lastLogin) {
        this.last_login = moment(lastLogin);
      }

      if (data.salutation) {
        if (data.salutation === 'MR') {
          this.salutation = 'm';
        } else if (data.salutation === 'MRS') {
          this.salutation = 'w';
        }
      }
    }
    this.access = new MemberAccess(access);
  }

  getBreadcrumbName(): string {
    const name = [this.first_name, this.last_name].filter(value => !!value).join(' ');
    return name || this.uid || 'unknown';
  }

}

import { ProjectGeometryObject, ProjectGeometryObjectDto } from './project-geometry-object';

export interface ProjectGeometryDto {
  objects: ProjectGeometryObjectDto[];
}

export class ProjectGeometry implements ProjectGeometryDto {
  objects: ProjectGeometryObject[];

  constructor(json?: ProjectGeometryDto) {
    if (json) {
      this.deserializeObjects(json);
    }
  }

  private deserializeObjects(json?: ProjectGeometryDto): ProjectGeometryObject | void {
    if (json.objects && json.objects.length) {
      this.objects = json.objects.map(object => {
        return new ProjectGeometryObject(object);
      });
    }
  }
}

export interface ProjectLogPayloadDto {
  participant_first_name: string;
  participant_last_name: string;
}

export class ProjectLogPayload implements ProjectLogPayloadDto{
  participant_first_name: string;
  participant_last_name: string;

  constructor(data?: ProjectLogPayloadDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

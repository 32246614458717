import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { LanguageService } from '@olmero/shared-core';

@Injectable()
export class ApiLanguageInterceptor implements HttpInterceptor {

  private language: string;

  constructor(private urlHelper: UrlHelper, languageService: LanguageService) {
    this.language = languageService.getLocale().substr(0, 2);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({ headers: req.headers.set('Accept-Language', this.language) }));
  }

}

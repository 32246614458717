import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TagListService } from '@project-shared/services/tag-list.service';
import { takeUntil } from 'rxjs/operators';
import { TitleService } from '@project-shared/services/title.service';

@Component({
  selector: 'olm-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit, OnDestroy {

  title$: Observable<string>;
  showTabList = false;
  private unsubscribe = new Subject<void>();
  showTitle$: BehaviorSubject<boolean>;

  constructor(private titleService: TitleService, private tagListService: TagListService) { }

  ngOnInit(): void {
    this.title$ = this.titleService.title$;
    this.showTitle$ = this.titleService.showTitle$;

    this.tagListService.tags$.pipe(takeUntil(this.unsubscribe)).subscribe(tags => {
      this.showTabList = tags && tags.length > 0;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}

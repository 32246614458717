import { Participant, ParticipantDto } from '../participants/participant.model';
import { ProjectLogPayload, ProjectLogPayloadDto } from './project-log-payload.model';
import { DateTime } from 'luxon';

export interface ChangedProjectField {
  field_name: string;
  old_value: any;
  new_value: any;
}

export interface ProjectLogDto {
  type: ProjectLogType;
  created_date: DateTime;
  created_by: ParticipantDto;
  payload: ProjectLogPayloadDto;
  changed_fields?: ChangedProjectField[];
}

export class ProjectLog implements ProjectLogDto {
  type: ProjectLogType;
  created_date: DateTime;
  created_by: Participant;
  payload: ProjectLogPayload;
  changed_fields?: ChangedProjectField[];
  id?: number;

  constructor(json?: ProjectLogDto) {
    if (json) {
      Object.assign(this, json);
      this.deserializeDates(json);
      this.deserializePayload(json);
    }
  }

  getCompanyDisplay(): string {
    return [this.created_by.company?.name, this.created_by.company?.department, this.created_by.company?.city].filter(Boolean).join(', ');
  }

  getUserDisplay(): string {
    return `${this.created_by?.first_name ? this.created_by?.first_name : ''} ${this.created_by?.last_name ? this.created_by?.last_name : ''}`.trim();
  }

  private deserializePayload(json: ProjectLogDto): void {
    if (json.payload) {
      this.payload = new ProjectLogPayload(json.payload);
    }
  }

  private deserializeDates(json: ProjectLogDto): void {
    if (json.created_date) {
      this.created_date = DateTime.fromISO(json.created_date as any);
    }
  }
}

export enum ProjectLogType {
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_MODIFIED = 'PROJECT_MODIFIED',
  PROJECT_TENDER_TEMPLATE_CREATED = 'PROJECT_TENDER_TEMPLATE_CREATED',
  PROJECT_TENDER_TEMPLATE_MODIFIED = 'PROJECT_TENDER_TEMPLATE_MODIFIED',
  PROJECT_PARTICIPANT_ADDED = 'PROJECT_PARTICIPANT_ADDED',
  PROJECT_PARTICIPANT_REMOVED = 'PROJECT_PARTICIPANT_REMOVED'
}

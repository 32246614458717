import { Component, HostListener } from '@angular/core';
import { ApiOauthTokenService } from '@olmero/shared-core';
import { GtmService } from '@olmero/shared-core';
import { LoggerService } from '@project-shared/services/logger.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'olm-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  readonly IS_LOGGEDIN: string = 'isLoggedIn';

  @HostListener('window:storage', ['$event'])
  checkLoggedIn (event: Storage): void {
    const isLoggedin = JSON.parse(localStorage.getItem(this.IS_LOGGEDIN));
    if (event.storageArea == localStorage && !isLoggedin) {
      this.apiOauthTokenService.logout();
    }
  }

  constructor(private gtmService: GtmService,
              private keycloak: KeycloakService,
              private apiOauthTokenService: ApiOauthTokenService,
              private loggerService: LoggerService
  ) {
    this.gtmService.startCustomPageViewTracking();
    this.keycloak.isLoggedIn()
      .then(isLoggedIn => localStorage.setItem(this.IS_LOGGEDIN, isLoggedIn.toString()))
      .catch(err => this.loggerService.logError(err));
  }

}

export enum SkeletonType {
  LINE = '',
  CIRCLE = 'circle',
  SQUARE = 'square',
}

export enum SkeletonAnimationType {
  PROGRESS = 'progress',
  PULSE = 'pulse',
  DISABLED = 'false',
}

export enum SkeletonElement {
  PROJECT_LIST = 'project_list',
}

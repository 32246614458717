export enum BidderState {
  INVITED = 'invited',
  WILL_OFFER = 'will_offer',
  SIGNED_OFF = 'signed_off',
  REJECTED = 'rejected',
  APPLIED = 'applied',
  OFFERED = 'offered',
  BIDDING_ROUND = 'bidding_round',
  RECEIVED = 'received',
  OFFER_REJECTED = 'offer_rejected',
  APPLICATION_REJECTED = 'application_rejected'
}

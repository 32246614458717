import { WorkDescriptionType } from '../work-description/work-description-type.enum';
import { File, FileDto } from './file.model';
import { Link, LinkDto } from '@olmero/shared-core';

export interface AttachmentDto {
  uid: string;
  id?: string;
  category: string;
  description?: string;
  name: string;
  file: FileDto;
  sia?: string;
  work_description_attachment_id?: number;
  work_description_valid?: boolean;
  new_since_offered?: boolean;
  work_description_type?: WorkDescriptionType;
  _links?: {
    download_url?: LinkDto,
    get_work_description?: LinkDto,
    save_work_description?: Link,
    work_description_copy?: Link,
  };
}

export class Attachment implements AttachmentDto {
  uid: string;
  id?: string;
  category: string;
  description?: string;
  name: string;
  file: File;
  sia?: string;
  work_description_attachment_id?: number;
  work_description_edited?: boolean;
  work_description_valid?: boolean;
  new_since_offered?: boolean;
  work_description_type?: WorkDescriptionType;
  defective?: boolean;

  _links?: {
    download_url?: Link,
    get_work_description?: Link,
    save_work_description?: Link,
    work_description_copy?: Link,
  };

  constructor(data?: AttachmentDto) {
    if (data) {
      Object.assign(this, data);

      if (this.file) {
        this.file = new File(data.file);
      }
    }
  }

  get isViewable(): boolean {
    return !!this.work_description_attachment_id;
  }

  get isNPC(): boolean {
    return this.isViewable && this.work_description_type === WorkDescriptionType.NPC;
  }

  get isFreeForm(): boolean {
    return this.isViewable && this.work_description_type === WorkDescriptionType.FREE_FORM;
  }

  get fileIcon(): string {
    if (this.isViewable) {
      return this.isNPC ? 'icon-file-crbx' : 'icon-file-ff';
    }

    return this.file.icon;
  }
}

export enum BidderStateParam {
  INVITED = 'invited',
  WILL_OFFER = 'will-offer',
  SIGNED_OFF = 'signed_off',
  REJECTED = 'rejected',
  APPLIED = 'applied',
  OFFERED = 'offered',
  BIDDING_ROUND = 'bidding_round',
  OFFER_REJECTED = 'offer_rejected'
}

import { BuildingTypeDto, BuildingType } from '@project-shared/modules/form-sections/project-details/models/building-type.model';
import { DateConverterService } from '@project-shared/modules/locale/services/date-converter.service';
import { LinkDto, Link } from '@olmero/shared-core';
import { ContactDto } from '../tender/deprecated-contact.model';
import { Option, OptionDto } from './option.model';
import { ProjectAccess, ProjectAccessDto } from './project-access.model';
import { DateTime } from 'luxon';
import { ContactPerson, ContactPersonDTO } from '@project-shared/model/contact-person.model';
import { ContactPersonMapper } from '@project-shared/services/contact-person-mapper.service';
export enum ProjectPictureType {
  ORIGINAL_PICTURE = 'ORIGINAL_PICTURE',
}


export interface ProjectPictureDto {
  id: string;
  size?: number;
  name?: string;
  upload_date?: DateTime;
  type?: ProjectPictureType;
}

export class ProjectPicture implements ProjectPictureDto {
  id: string;
  size?: number;
  name?: string;
  upload_date?: DateTime;
  type?: ProjectPictureType;

  constructor(data?: ProjectPictureDto) {
    if (data) {
      Object.assign(this, data);
    }

    if (this.upload_date) {
      this.upload_date = DateTime.fromISO(this.upload_date as any);
    }
  }
}

export interface ProjectDetailsDto {
  uid: string;
  client_uid: string;
  number: string;
  order_reference_number: string;
  name: string;
  status: string;
  created: DateTime;
  zip: string;
  city: string;
  country: string;
  is_favorite: boolean;
  is_blacklist?: boolean;
  project_street: string;
  project_id: number;
  no: string;
  phase: string;
  street: string;
  object_type: string;
  building_types: BuildingTypeDto[];
  description: string;
  owner_name: string;
  owner_department: string;
  owner_street: string;
  owner_city: string;
  owner_zip: string;
  owner_country: string;
  tender_calc_visible: boolean;
  tender_info_allowed: boolean;
  construction_start: DateTime;
  construction_end: DateTime;
  contract_volume: string;
  debitor_uid: string;
  pictures?: ProjectPicture[];
  access: ProjectAccessDto;
  _links: {
    debitor: LinkDto,
  };
  options: OptionDto[];
  contacts: ContactPersonDTO[] | ContactDto[];
  project_budget?: number;
}

export class ProjectDetails implements ProjectDetailsDto {

  uid: string;
  client_uid: string;
  number: string;
  order_reference_number: string;
  name: string;
  status: string;
  created: DateTime;
  zip: string;
  city: string;
  country: string;
  is_favorite: boolean;
  is_blacklisted?: boolean;
  project_street: string;
  project_id: number;
  no: string;
  phase: string;
  street: string;
  object_type: string;
  building_types: BuildingType[] = [];
  description: string;
  owner_name: string;
  owner_department: string;
  owner_street: string;
  owner_city: string;
  owner_zip: string;
  owner_country: string;
  tender_calc_visible: boolean;
  tender_info_allowed: boolean;
  construction_start: DateTime;
  construction_end: DateTime;
  contract_volume: string;
  debitor_uid: string;
  pictures?: ProjectPicture[];
  access: ProjectAccess;
  _links: {
    debitor: Link,
  };
  options: Option[] = [];
  contacts: ContactPerson[] = [];
  project_budget?: number;

  constructor(data?: ProjectDetailsDto) {
    if (data) {
      Object.assign(this, data);

      if (this.construction_start) {
        this.construction_start = DateTime.fromISO(this.construction_start as any);
      }
      if (this.construction_start) {
        this.construction_end = DateTime.fromISO(this.construction_end as any);
      }

      this.pictures = data.pictures ? data.pictures.map(picture => new ProjectPicture(picture)) : [];
      this.building_types = data.building_types ? data.building_types.map(buildingType => new BuildingType(buildingType)) : [];
      this.options = data.options ? data.options.map(option => new Option(option)) : [];
      this.contacts = data.contacts ? data.contacts.map(contact => ContactPersonMapper.transform(contact)) : [];
    }
  }

  toJSON(): any {
    const overrides = {};
    if (this.construction_start) {
      overrides['construction_start'] = DateConverterService.luxonToEuropeZurichDateString(this.construction_start);
    }
    if (this.construction_end) {
      overrides['construction_end'] = DateConverterService.luxonToEuropeZurichDateString(this.construction_end);
    }
    return Object.assign({}, this, overrides);
  }
}

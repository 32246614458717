import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { LoggerService } from '@project-shared/services/logger.service';

// TTL in seconds (when set to 0 or below the cache will not be used)
export const CACHE_TTL = 'cache-ttl';

/**
 * if set with any value, it will ignore the cached response from the cache service and force a request to the api.
 * @deprecated use FORCE_REQUEST_HEADER instead for better usage
 */
export const FORCE_REQUEST = 'force-request';

/**
 * if set with any value, it will ignore the cached response from the cache service and force a request to the api.
 * add this (e.g. using the spread operator) to your http request headers
 */

export const FORCE_REQUEST_HEADER = { [FORCE_REQUEST]: FORCE_REQUEST };

@Injectable({ providedIn: 'root' })
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cacheService: CacheService, private loggerService: LoggerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let newReq = req;

    let ttl = 3; // seconds

    if (req.headers.has(CACHE_TTL)) {
      const tempTTL = parseInt(req.headers.get(CACHE_TTL), 10);
      ttl = tempTTL > 0 ? tempTTL : 0;
      newReq = req.clone({ headers: req.headers.delete(CACHE_TTL) });
    }

    const url = req.urlWithParams;
    if (req.method !== 'GET' || ttl === 0) {
      // invalidate cache
      this.cacheService.delete(url);
      return next.handle(newReq);
    }


    const cachedResponse = this.cacheService.get(url);
    if (cachedResponse && !req.headers.has(FORCE_REQUEST)) {
      this.loggerService.logInfo('Returning cached response for ' + req.url);
      return cachedResponse;
    }

    if (req.headers.has(FORCE_REQUEST)) {
      newReq = req.clone({ headers: req.headers.delete(FORCE_REQUEST) });
    }

    const observable = next.handle(newReq).pipe(
      publishReplay(1),
      refCount()
    );

    this.cacheService.set(url, observable, ttl);

    return observable;
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@olmero/shared-core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Attachment, AttachmentDto } from '../../../model/attachment/attachment.model';
import { ApiConverterService } from './api-converter.service';

@Injectable({ providedIn: 'root' })
export class TenderAttachmentApiService {

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private apiConverter: ApiConverterService
  ) { }

  getTenderAttachments(tenderUid: string, offerUid?: string): Observable<Attachment[]> {
    const params = offerUid ? new HttpParams().set('newSinceOfferUid', offerUid) : new HttpParams();
    return this.http.get<AttachmentDto[]>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/attachments`, { params })
      .pipe(
        switchMap((tenderAttachmentsDto: AttachmentDto[]) => {
          const tenderAttachments = tenderAttachmentsDto.map(tenderAttachment => new Attachment(tenderAttachment));
          return this.apiConverter.calculateUrlForAttachments(tenderAttachments);
        }));
  }

}

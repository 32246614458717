import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormatterService } from './formatter.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [
    FormatterService,
  ],
})
export class OlmFormatterModule {

}

export interface TenderAccessDto {
  can_add_tender_member: boolean;
  can_edit_tender: boolean;
  can_edit_tender_rights: boolean;
  can_publish_tender: boolean;
  can_remove_tender_member: boolean;
  can_view_tender: boolean;
}

export class TenderAccess implements TenderAccessDto {
  can_add_tender_member: boolean;
  can_edit_tender: boolean;
  can_edit_tender_rights: boolean;
  can_publish_tender: boolean;
  can_remove_tender_member: boolean;
  can_view_tender: boolean;

  constructor(data?: TenderAccessDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

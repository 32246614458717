import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProjectAndClientAndParent } from '@project-shared/model/api.model';
import { forkJoin, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { ClientService } from '../services/client.service';
import { ProjectResolver } from './project.resolver';

@Injectable()
export class ProjectAndClientResolver {

  constructor(private clientService: ClientService,
              private projectResolver: ProjectResolver) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectAndClientAndParent> {
    return this.projectResolver.resolve(route).pipe(
      flatMap(project => forkJoin([
        of(project),
        this.clientService.getClientAndParent(project.projectDetails.client_uid)])),
      map(result => {
        return {
          project: result[0],
          client: result[1].client,
          clientContactAddress: result[1].clientContactAddress,
          parent: result[1].parent,
          parentContactAddress: result[1].parentContactAddress,
        };
      })
    );
  }
}

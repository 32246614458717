import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { TenderService } from '../services/tender.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Tender } from '@project-shared/model/tender/tender.model';
import { Member } from '@project-shared/model/member.model';
import { MemberService } from '@project-shared/services/member.service';

export interface EditTenderData {
  tender: Tender;
  members: Member[];
}

@Injectable()
export class EditTenderResolver {
  constructor(private tenderService: TenderService, private memberService: MemberService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<EditTenderData> {
    return this.tenderService.getTender(route.params.tenderUid)
      .pipe(
        mergeMap(tender =>
          forkJoin(tender.contacts.map(contact =>
            this.memberService.getMember(contact.uid)
              .pipe(
                catchError(ignored => of(undefined))
              )))
            .pipe(
              map(result => ({
                tender,
                members: result ? result.filter(Boolean) : [],
              }))
            )
        )
      );
  }
}

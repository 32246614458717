<button tclass="test-help-menu-icon" class="button-header" [matMenuTriggerFor]="helpMenu">
  <span class="icon-help"></span>
  <span class="icon-down"></span>
</button>
<mat-menu class="action-dropdown"  xPosition="before" tclass="help-menu-dropdown-list" #helpMenu="matMenu">
  <a href="tel:+410848656376" class="action-dropdown-item" [style.height.px]="64">
    <span class="icon-phone"></span>
    <div fxLayout="column">
      <div class="phone" i18n="@@home_hotline_number">+41 848 656 376</div>
      <div class="phone" i18n="@@pp_core_working_hours">Mo-Fr 8-12 | 13-17</div>
    </div>
  </a>
  <a class="action-dropdown-item" href="mailto:info@olmero.ch" target="_blank">
    <span class="icon-envelope"></span>
    <p>info@olmero.ch</p>
  </a>
  <a tclass="test-help-menu-dropdown-item-help"
     class="action-dropdown-item"
     [href]="helpPageLink"
     target="_blank"
     rel="noopener"
  >
    <span class="icon-help"></span>
    <p i18n="@@pp_core_help_page">Help page</p>
    <span class="icon-external-link"></span>
  </a>
  <a tclass="test-help-menu-dropitem-tutorials"
    class="action-dropdown-item"
    href="https://www.youtube.com/channel/UCgxQ-f3lwNzXftYMI-NhaNQ/featured"
    target="_blank"
    rel="noopener"
  >
    <span class="icon-play-button"></span>
    <p i18n="@@pp_core_tutorials">Tutorials</p>
    <span class="icon-external-link"></span>
  </a>
</mat-menu>

import { ProjectReference } from '@project-shared/modules/project-references/model/project-reference.model';
import { ContactPerson } from '../contact-person.model';

export interface PrivateBidderClientDto {
  uid?: string;
  name: string;
  department?: string;
  city?: string;
  country_code?: string;
  street?: string;
  country?: string;
  zip?: string;
  kind?: string;
  new?: boolean;
  link?: string;
  premium?: boolean;
  isPremium?: boolean;
  profile?: string;
  is_favorite?: boolean;
  languageCode?: string;
  is_blacklist?: boolean;
  blacklisted?: boolean;
  is_suggested?: boolean;
  selected?: boolean;
  enabled?: boolean;
  email?: string;
  fax?: string;
  phone?: string;
  contactAddressLoaded?: boolean;
  deleted?: boolean;
  list_logo_url?: string;
  referencProjectsLoaded?: boolean;
  referenceProjectsList?: ProjectReference[];
  project_reference_count?: number;
  project_reference_details_link?: string;
}
export interface BidderClientDto extends PrivateBidderClientDto{
  uid: string;
  phone_number?: string;
  contact_person?: ContactPerson;
}
export class PrivateBidderClient implements PrivateBidderClientDto {
  uid?: string;
  name: string;
  department?: string;
  city?: string;
  country_code?: string;
  street?: string;
  country?: string;
  zip?: string;
  kind?: string;
  new?: boolean;
  link?: string;
  premium?: boolean;
  isPremium?: boolean;
  profile?: string;
  is_favorite?: boolean;
  languageCode?: string;
  is_blacklist?: boolean;
  blacklisted?: boolean;
  is_suggested?: boolean;
  selected?: boolean;
  enabled?: boolean;
  email?: string;
  fax?: string;
  phone?: string;
  contactAddressLoaded?: boolean;
  deleted?: boolean;
  list_logo_url?: string;
  referencProjectsLoaded?: boolean;
  referenceProjectsList?: ProjectReference[];
  project_reference_count?: number;
  project_reference_details_link?: string;

  constructor(data?: PrivateBidderClientDto) {
    if (data) {
      Object.assign(this, data);
    }
  }


  getCompanyInfo(): string {
    return [
      [
        this.name,
        this.department,
      ]
        .filter(Boolean)
        .join(', '),
      [
        this.zip,
        this.city,
      ]
        .filter(Boolean)
        .join(' '),
    ]
      .filter(Boolean)
      .join(', ');
  }
}

export class BidderClient extends PrivateBidderClient implements BidderClientDto {
  uid: string;
  phone_number?: string;
  contact_person?: ContactPerson;
  constructor(data?: BidderClientDto){
    super();
    if (data) {
      Object.assign(this, data);
      if (data.contact_person){
        this.contact_person = new ContactPerson(data.contact_person);
      }
    }
  }

  formatAddress(): string{
    let address = '';
    if (this.country_code){
      address += this.country_code.toUpperCase();
      if (this.zip){
        address += '-';
      }
    }
    if (this.zip){
      address += this.zip;
    }
    if (this.city){
      address += (address != '' ? ' ' : '') + this.city;
    }
    return address;
  }

  getDisplayNameAndDepartment(): string {
    return ([this.name, this.department]).filter(property => !!property).join(', ');
  }
}

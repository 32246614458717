import { NgModule } from '@angular/core';
import { OlmPpWebModule } from '@issuer-shared/ppweb/ppweb.module';
import { ApiOauthTokenService } from '@olmero/shared-core';

@NgModule({
  imports: [
    OlmPpWebModule,
  ],
  providers: [
    ApiOauthTokenService,
  ],
})
export class OlmOauthModule {}


export enum ApiErrorCode {
  CONSTRAINT_VIOLATION = 'CONSTRAINT_VIOLATION',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export abstract class ApiException {

  protected constructor(public readonly message: string) {}

}

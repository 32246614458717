<button tclass="test-user-button" [disabled]="apiRoot.client_details.is_private" class="button-header" [matMenuTriggerFor]="profilesMenu">
    <span class="icon-user"></span>
    <p>{{ apiRoot.member_details.getFullName() }} <span *ngIf="hasImpersonation">( {{ adminFullName }} )</span></p>
    <span class="icon-down"></span>
</button>
<mat-menu tclass="test-profiles-dropdown" class="action-dropdown" xPosition="before" #profilesMenu="matMenu">
    <a tclass="test-user-profile"
       class="action-dropdown-item" [routerLink]="['/clients', apiRoot.client_details.uid, 'members', apiRoot.member_details.uid]">
        <p i18n="@@navigation_user_profile">User Profile</p>
    </a>
    <a tclass="test-company-profile"
       class="action-dropdown-item" [routerLink]="['/clients', apiRoot.client_details.uid]">
        <p i18n="@@navigation_company_profile">Company Profile</p>
    </a>
</mat-menu>

import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { default as moment } from 'moment';

@Injectable({ providedIn: 'root' })
export class DateFormatterService {

  static formatDay(day: number): string {
    return this.padDatePart(day);
  }

  static formatMonth(month: number): string {
    return this.padDatePart(month);
  }

  private static padDatePart(timePart: number): string {
    return ('00' + timePart).slice(-2);
  }

  formatDateAndTime(date: Moment | string): string {
    return moment(date).format('DD.MM.YY HH:mm');
  }

  formatLuxonDateAndTime(date: DateTime): string {
    return date?.toFormat('dd.MM.yy HH:mm');
  }
  formatLuxonDate(date: DateTime): string {
    return date?.toFormat('dd.MM.yy');
  }

  formatDate(date: Moment | string): string {
    return moment(date).format('DD.MM.YY');
  }

  formatTime(date: Moment | string): string {
    return moment(date).format('HH:mm');
  }
}

import { Offer } from './offer.model';

export interface OffersDto {
  export_offers_link: string;
  compare_offers_link: string;
  offers: Offer[];
}

export class Offers implements OffersDto {
  public export_offers_link: string;
  public compare_offers_link: string;
  public offers: Offer[];

  constructor(init?: OffersDto) {
    if (init) {
      Object.assign(this, init);
      this.offers = [];
      if (!init.offers) {
        return;
      }
      init.offers.forEach(offerData => {
        this.offers.push(new Offer(offerData));
      });
    }
  }
}

import { Routes } from '@angular/router';
import { ForbiddenComponent } from '@project-shared/modules/frame/components/view/403/forbidden.component';
import { ServerErrorComponent } from '@project-shared/modules/frame/components/view/500/server-error.component';
import { NotFoundComponent } from '@project-shared/modules/frame/components/view/not-found/not-found.component';
import { appRoutesNames } from './app.routes.names';
import { ExternallyTriggeredLogoutComponent } from '@project-shared/modules/logout/logout/externally-triggered-logout.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: appRoutesNames.BIDDERS,
    loadChildren: () => import('./bidder/bidder.module').then(m => m.BidderModule),
  },
  {
    path: appRoutesNames.SUPPLIERS,
    loadChildren: () => import('./supplier/supplier.routes').then(m => m.SUPPLIER_ROUTES),
  },
  {
    path: appRoutesNames.CLIENTS,
    loadChildren: () => import('./admin/clients/clients.module').then(m => m.OlmClientsModule),
  },
  {
    path: appRoutesNames.ELEMENTS,
    loadChildren: () => import('./issuer/+elements/elements.module').then(m => m.OlmElementsModule),
  },
  {
    path: appRoutesNames.ADMIN,
    loadChildren: () => import('./admin/admin.module').then(m => m.OlmAdminModule),
  },
  {
    path: appRoutesNames.PROJECTS,
    loadChildren: () => import('./issuer/+projects/projects.module').then(m => m.OlmProjectsModule),
  },
  {
    path: appRoutesNames.DIRECTORY,
    loadChildren: () => import('./issuer/directory/directory.module').then(m => m.OlmDirectoryModule),
  },
  /**
   * This path is called by AP post-logout to also end the session in NAP.
   */
  {
    path: 'logout',
    component: ExternallyTriggeredLogoutComponent,
  },
  {
    path: appRoutesNames.CODE_403,
    component: ForbiddenComponent,
  },
  {
    path: appRoutesNames.CODE_404,
    component: NotFoundComponent,
  },
  {
    path: appRoutesNames.CODE_500,
    component: ServerErrorComponent,
  },
  {
    path: appRoutesNames.SUPLIER_REQUEST,
    redirectTo: '',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

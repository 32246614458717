import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import locale_DE_CH from '@angular/common/locales/de-CH';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, TRANSLATIONS_FORMAT } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ResolveEnd, Router, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppFrameComponent } from './app-frame.component';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { httpInterceptorProviders } from './shared/http-interceptors/http-interceptors';
import { OlmToastModule } from '@project-shared/modules/toast/toast.module';
import { OlmApiModule } from '@project-shared/modules/api/api.module';
import { OlmUserModule } from './shared/modules/api/user.module';
import {
  ApiRoot,
  AppConfigService,
  CurrentUserService,
  GtmGa4LoginEvent,
  GtmService,
  ImpersonationService,
  KeycloakReadyService,
  TextService,
  WINDOW_PROVIDERS
} from '@olmero/shared-core';
import { reducers } from './shared/reducers';
import { OlmBreadcrumbModule } from './shared/modules/breadcrumb/breadcrumb.module';
import { BreadcrumbService } from './shared/modules/breadcrumb/services/breadcrumb.service';
import { OlmTestClassModule, SharedDirectivesModule } from '@olmero/shared-ui';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OlmFrameModule } from '@project-shared/modules/frame/frame.module';
import { ErrorHandlerService } from '@project-shared/services/error-handler.service';
import { rollbarFactory, RollbarService } from '@project-shared/services/rollbar.service';
import { OlmOauthModule } from '@project-shared/modules/api/oauth.module';
import { KeycloakAngularModule, KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { HeaderModule } from './header/header.module';
import { first } from 'rxjs/operators';
import { LoggerService } from '@project-shared/services/logger.service';
import { ToastService } from '@project-shared/modules/toast/services/toast.service';
import { ApLinkService } from '@project-shared/services/ap-link.service';
import { DefaultTextsService } from '@project-shared/services/default-texts.service';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Settings } from 'luxon';

registerLocaleData(locale_DE_CH);

const initializeLuxonLocale = (locale: string) => {
  return () => Settings.defaultLocale = locale;
};

const initializeLuxonTimezone = () => {
  return () => Settings.defaultZone = 'Europe/Zurich';
};

export const MY_FORMATS = {
  parse: { dateInput: 'DD.MM.YYYY' },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MY_LUXON_FORMATS = {
  parse: { dateInput: 'dd.LL.yyyy' },
  display: {
    dateInput: 'dd.LL.yyyy',
    monthYearLabel: 'LLLL yyyy',
    dateA11yLabel: 'dd.LL.yyyy',
    monthYearA11yLabel: 'LLLL yyyy',
  },
};

function bookedPlan(apiRoot: ApiRoot): string {
  if (apiRoot.client_details.is_private) {
    return 'free';
  }

  return apiRoot.client_details.premium ? 'premium' : 'standard';
}

function initializeKeycloak(
  keycloak: KeycloakService,
  appConfig: AppConfigService,
  keycloakReadyService: KeycloakReadyService,
  currentUserService: CurrentUserService,
  gtmService: GtmService,
  loggerService: LoggerService
) {
  return () => {
    keycloak.init({
      config: {
        url: appConfig.getConfig().apUrl,
        realm: 'olmero',
        clientId: 'olmero-frontend',
      },
      initOptions: {
        onLoad: 'check-sso',
        // needs to be query in order to pickup keycloak params e.g. for password change flow
        responseMode: 'query',
        checkLoginIframe: false,
        redirectUri: window.location.origin + window.location.pathname,
      },
      shouldAddToken: () => {
        // avoid interfering with our custom interceptor
        // we can activate the token adding here when we switch to keycloak completely
        return false;
      },
    }).then(
      () => keycloakReadyService.setKeycloakReady(),
      err => loggerService.logError(err)
    );

    keycloak.keycloakEvents$.subscribe((keycloakEvent: KeycloakEvent) => {
      if (keycloakEvent.type === KeycloakEventType.OnAuthSuccess) {
        currentUserService.getApiRoot()
          .pipe(first())
          .subscribe((apiRoot: ApiRoot) => {
            if (apiRoot !== null) {
              const event: GtmGa4LoginEvent =
            {
              event: 'login',
              loginMethod: 'number',
              bookedPlan: bookedPlan(apiRoot),
              bookedModules: apiRoot.client_details.client_options.toString(),
            };
              gtmService.pushCustomEvent(event);
            }
          });
      }
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppFrameComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgProgressModule,
    NgProgressHttpModule.withConfig({
      silentApis:
        ['https://securepubads.g.doubleclick.net/gampad/ads',
          'https://securepubads.g.doubleclick.net/pcs/view',
          'https://pagead2.googlesyndication.com/pcs/activeview'],
    }),
    OlmFrameModule,
    OlmTestClassModule,
    OlmOauthModule,
    OlmApiModule,
    OlmUserModule,
    NoopAnimationsModule,
    OlmToastModule,
    OlmBreadcrumbModule,
    MatMomentDateModule,
    SharedDirectivesModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    RouterModule.forRoot(APP_ROUTES, {
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    }),
    KeycloakAngularModule,
    NgxSkeletonLoaderModule.forRoot(),
    HeaderModule,
  ],
  providers: [
    httpInterceptorProviders,
    ImpersonationService,
    KeycloakReadyService,
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
      deps: [AppConfigService],
    },
    {
      provide: TRANSLATIONS_FORMAT,
      useValue: 'xlf',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppConfigService, KeycloakReadyService, CurrentUserService, GtmService, LoggerService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLuxonLocale,
      deps: [LOCALE_ID],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLuxonTimezone,
      multi: true,
    },
    BreadcrumbService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
    {
      provide: 'ToastService',
      useClass: ToastService,
    },
    {
      provide: 'AppConfigService',
      useClass: AppConfigService,
    },
    {
      provide: 'TextService',
      useClass: TextService,
    },
    {
      provide: 'DefaultTextsService',
      useClass: DefaultTextsService,
    },
    {
      provide: 'ApLinkService',
      useClass: ApLinkService,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: '600px',
        hasBackdrop: true,
        panelClass: 'olm-dialog-content',
        backdropClass: 'overlay-backdrop',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(router: Router, breadcrumbService: BreadcrumbService) {
    router.events.subscribe(event => {
      if (event instanceof ResolveEnd) {
        breadcrumbService.buildBreadcrumb(event.state.root.firstChild);
      }
    });

  }

}

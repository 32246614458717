import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface InjectableComponent {
  data: any;
}

@Injectable({ providedIn: 'root' })
export class SidenavService {
  component$ = new BehaviorSubject<{component: Type<any>, data: any}>(undefined);

  injectComponent(component: Type<any>, data: any): void {
    this.component$.next({ component, data });
  }

  hasComponent(): boolean {
    return !!this.component$.value;
  }

  clearComponent(): void {
    this.component$.next(undefined);
  }
}

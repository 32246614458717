<div
  [class.readonly]="breadcrumbService.readonly"
  class="flex pl-32 pr-32 mt-8 mb-8">
  <div class="fx-layout-row flex-wrap gap-4 ai-center">
    <ng-container *ngIf="!hideHomeBreadcrumb">
      <a
        [routerLink]="'/'"
        class="breadcrumb-text typo-text-button-s">
        {{ 'home_page_breadcrumb' | text }}
      </a>

      <span class="breadcrumb-text typo-text-button-s divider">/</span>
    </ng-container>

    <ng-container *ngFor="let breadcrumb of breadcrumbService.breadcrumbs | async; let last = last">
      <div class="bc">
        <ng-container *ngIf="!last && !breadcrumbService.readonly && !breadcrumb.isLinkHidden; else noLink">
        <a
          tclass="test-breadcrumb-link"
          *ngIf="!breadcrumb.isLinkExternal; else externalLink"
          [routerLink]="[breadcrumb.link]"
          class="breadcrumb-text typo-text-button-s">
          {{ breadcrumb.text }}
        </a>
        </ng-container>
        <ng-template #externalLink>
          <a [href]="breadcrumb.link" class="breadcrumb-text typo-text-button-s">
            {{breadcrumb.text }}
          </a>
        </ng-template>
        <ng-template #noLink>
          <span class="breadcrumb-text typo-text-button-s">
            {{ breadcrumb.text }}
          </span>
        </ng-template>
      </div>
      <span *ngIf="!last && breadcrumb.text" class="breadcrumb-text typo-text-button-s divider">/</span>
    </ng-container>
  </div>
</div>

import { Moment } from 'moment';
import { OfferDto } from '@project-shared/model/offer/offer.model';
import { BidderBiddingRound, BidderBiddingRoundDto } from './bidder-bidding-round.model';

export enum BidState {
  INVITED = 'invited',
  OFFERED = 'offered'
}

export interface BidDto {
  uuid: string;
  bidding_round: BidderBiddingRoundDto;
  status: BidState;
  created: Moment;
  initial_offer: OfferDto;
  offer: OfferDto;
}

export class Bid implements BidDto {
  uuid: string;
  bidding_round: BidderBiddingRound;
  status: BidState;
  created: Moment;
  initial_offer: OfferDto;
  offer: OfferDto;

  constructor(dto: BidDto) {
    Object.assign(this, dto);
    this.bidding_round = new BidderBiddingRound(dto.bidding_round);
  }

  isOffered(): boolean {
    return this.status === BidState.OFFERED;
  }
}

import { TenderPublication, TenderPublicationDto } from './tender-publication.model';
import { TenderState } from './tender-state.enum';
import { DateTime } from 'luxon';
import { Link, LinkDto } from '@olmero/shared-core';

export interface TenderPreviewDto {
  uid: string;
  name: string;
  customer_reference_number: string;
  tender_state: TenderState;
  tender_kind: string;
  creation_date: DateTime;
  application_date: DateTime;
  submission_date: DateTime;
  extended_submission_date: DateTime;
  publication_date: DateTime;
  feedback_date: DateTime;
  publication: TenderPublicationDto;
  stats: {
    number_of_applicants: number,
    number_of_offers: number,
    number_of_will_offer: number,
    number_of_unsubscribed: number,
    number_of_invited: number,
  };
  lowest_offer: number;
  estimated_budget: number;
  bidding_round_deadline_date?: DateTime;
  number_of_bidding_rounds?: number;
  approval_created_date?: DateTime;
  completion_date?: DateTime;
  accepting_date?: DateTime;
  workstart: string;
  _links: {
    self: LinkDto,
    logs: LinkDto,
  };
}

export class TenderPreview implements TenderPreviewDto {
  uid: string;
  name: string;
  customer_reference_number: string;
  tender_state: TenderState;
  tender_kind: string;
  creation_date: DateTime;
  application_date: DateTime;
  submission_date: DateTime;
  extended_submission_date: DateTime;
  publication_date: DateTime;
  feedback_date: DateTime;
  publication: TenderPublicationDto;
  stats: {
    number_of_applicants: number,
    number_of_offers: number,
    number_of_will_offer: number,
    number_of_unsubscribed: number,
    number_of_invited: number,
  };
  lowest_offer: number;
  estimated_budget: number;
  bidding_round_deadline_date?: DateTime;
  number_of_bidding_rounds?: number;
  approval_created_date?: DateTime;
  completion_date?: DateTime;
  accepting_date?: DateTime;
  workstart: string;
  _links: {
    self: Link,
    logs: Link,
  };

  constructor(json?: TenderPreviewDto) {
    if (json) {
      Object.assign(this, json);
      this.deserializePublication(json);
      this.deserializeDates(json);
    }
  }

  private deserializePublication(json: TenderPreviewDto): void {
    if (json.publication) {
      this.publication = new TenderPublication(json.publication);
    }
  }


  private deserializeDates(json: TenderPreviewDto): void {
    if (json.submission_date) {
      this.submission_date = DateTime.fromISO(json.submission_date as any);
    }
    if (json.application_date) {
      this.application_date = DateTime.fromISO(json.application_date as any);
    }
    if (json.feedback_date) {
      this.feedback_date = DateTime.fromISO(json.feedback_date as any);
    }
    if (json.publication_date) {
      this.publication_date = DateTime.fromISO(json.publication_date as any);
    }
    if (json.bidding_round_deadline_date) {
      this.bidding_round_deadline_date = DateTime.fromISO(json.bidding_round_deadline_date as any);
    }
    if (this.approval_created_date) {
      this.approval_created_date = DateTime.fromISO(this.approval_created_date as any);
    }
    if (this.completion_date) {
      this.completion_date = DateTime.fromISO(this.completion_date as any);
    }
    if (this.extended_submission_date) {
      this.extended_submission_date = DateTime.fromISO(this.extended_submission_date as any);
    }
  }

  isTenderOpen(): boolean {
    return this.tender_state === TenderState.open;
  }

  isTenderExpired(): boolean {
    if (this.bidding_round_deadline_date){
      return this.bidding_round_deadline_date < DateTime.local();
    }
    if (this.submission_date){
      return this.submission_date < DateTime.local();
    }
    return false;
  }

  isTenderClosed(): boolean {
    return this.tender_state === TenderState.completed;
  }

  isTenderInDraft(): boolean {
    return this.tender_state === TenderState.draft || this.tender_state === TenderState.saved_to_cache;
  }

  isInDraft(): boolean {
    return this.tender_state === 'saved_to_cache';
  }

  isInBiddingRound(): boolean {
    return this.bidding_round_deadline_date > DateTime.local();
  }

  isInApproval(): boolean {
    return this.tender_state === 'approval';
  }

  isOngoing(): boolean {
    return !this.isInDraft() && this.submission_date > DateTime.local() && !this.isInBiddingRound();
  }

  isExpired(): boolean {
    return !this.isOngoing() && !this.isInBiddingRound() && !this.isInDraft() && !this.isClosed() && !this.isInApproval();
  }

  isClosed(): boolean {
    return this.tender_state === 'completed';
  }
}

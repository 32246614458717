export interface BidderAccessDto {
  can_view_yellow_pages: boolean;
  can_send_invitation: boolean;
  can_reinvite: boolean;
  can_revoke_bidder: boolean;
  can_extend_submission_date: boolean;
  can_will_not_offer: boolean;
  can_will_offer: boolean;
  can_reject: boolean;
  can_accept: boolean;
  can_view_offers: boolean;
  can_view_application: boolean;
}

export class BidderAccess implements BidderAccessDto {
  can_accept: boolean;
  can_extend_submission_date: boolean;
  can_reinvite: boolean;
  can_reject: boolean;
  can_revoke_bidder: boolean;
  can_send_invitation: boolean;
  can_view_application: boolean;
  can_view_offers: boolean;
  can_view_yellow_pages: boolean;
  can_will_not_offer: boolean;
  can_will_offer: boolean;

  constructor(data?: BidderAccessDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

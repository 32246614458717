import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TextService } from '@olmero/shared-core';
import { BREADCRUMB, Breadcrumb } from '../models/breadcrumb';
import { BreadcrumbDataService } from './breadcrumb-data.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbTextService {

  private breadcrumbTokens: Map<string, string>;

  constructor(private text: TextService, private breadcrumbDataService: BreadcrumbDataService) {
    this.breadcrumbTokens = this.createBreadcrumbTokens();
  }

  public getBreadcrumbTextForRoute(route: ActivatedRouteSnapshot): Observable<string> {
    return this.getTextForRoute(route, 'breadcrumb');
  }

  public getTitleTextForRoute(route: ActivatedRouteSnapshot): Observable<string> {
    return this.getTextForRoute(route, 'title');
  }

  private getTextForRoute(route: ActivatedRouteSnapshot, textType: 'breadcrumb' | 'title'): Observable<string> {
    const data: any = route.data[textType] !== undefined ? route.data[textType] : route.data['breadcrumb'];
    const type: string = typeof data;
    switch (type) {
      case 'object':
        if (data instanceof Breadcrumb) {
          return of(data.text);
        } else {
          throw new Error('unknown object instance of breadcrumb');
        }
      case 'string':
        if (this.breadcrumbTokens.has(data)) {
          return textType === 'title' ? this.resolveTitleToken(data, route) : this.resolveBreadcrumbToken(data, route);
        } else {
          return of(this.text.get(data));
        }
      case 'boolean':
        if (data && route.routeConfig.path) {
          return of(route.routeConfig.path);
        }
        return of('');
      default:
        throw new Error(`unknown breadcrumb type: ${type}`);
    }
  }

  private resolveBreadcrumbToken(token: string, route: ActivatedRouteSnapshot): Observable<string> {
    switch (token) {
      case BREADCRUMB.CLIENT:
        return this.breadcrumbDataService.getClient(route.paramMap.get('clientUid'));
      case BREADCRUMB.PROJECT:
        return this.breadcrumbDataService.getProject(route.paramMap.get('projectUid'));
      case BREADCRUMB.TENDER:
        return this.breadcrumbDataService.getTender(route.paramMap.get('tenderUid'));
      case BREADCRUMB.BIDDER_TENDER:
        return this.breadcrumbDataService.getBidderTender(route.paramMap.get('tenderUid'));
      case BREADCRUMB.BIDDER:
        return this.breadcrumbDataService.getClient(route.paramMap.get('bidderUid'));
      case BREADCRUMB.MEMBER:
        return this.breadcrumbDataService.getMember(route.paramMap.get('memberUid'));
      case BREADCRUMB.BIDDER_TENDER_DASHBOARD:
        return this.breadcrumbDataService.checkPrivateBidder().pipe(
          take(1),
          map(data => data ? '' : this.text.get('bidders_tender_dashboard'))
        );
      default:
        return of('unknown breadcrumb token:' + token);
    }
  }

  private resolveTitleToken(token: string, route: ActivatedRouteSnapshot): Observable<string> {
    switch (token) {
      case BREADCRUMB.CLIENT:
        return this.breadcrumbDataService.getClientTitle(route.paramMap.get('clientUid'));
      case BREADCRUMB.PROJECT:
        return this.breadcrumbDataService.getProjectTitle(route.paramMap.get('projectUid'));
      case BREADCRUMB.TENDER:
        return this.breadcrumbDataService.getTender(route.paramMap.get('tenderUid'));
      case BREADCRUMB.BIDDER:
        return this.breadcrumbDataService.getClientTitle(route.paramMap.get('bidderUid'));
      case BREADCRUMB.BIDDER_TENDER:
        return this.breadcrumbDataService.getBidderTender(route.paramMap.get('tenderUid'));
      case BREADCRUMB.MEMBER:
        return this.breadcrumbDataService.getMember(route.paramMap.get('memberUid'));
      case BREADCRUMB.BIDDER_TENDER_DASHBOARD:
        return of(this.text.get('bidders_tender_dashboard'));
      default:
        return of('unknown title token:' + token);
    }
  }

  private createBreadcrumbTokens(): Map<string, string> {
    const tokens = new Map<string, string>();
    for (const tokenName in BREADCRUMB) {
      if (BREADCRUMB.hasOwnProperty(tokenName)) {
        tokens.set(BREADCRUMB[tokenName], tokenName);
      }
    }
    return tokens;
  }

}

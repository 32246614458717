import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectService } from '@project-shared/services/project.service';
import { BidderAddressResolver } from './resolver/bidder-address.resolver';
import { BidderClientResolver } from './resolver/bidder-client.resolver';
import { BidderOffersResolver } from './resolver/bidder-offers.resolver';
import { BidderResolver } from './resolver/bidder.resolver';
import { CandidateBiddersResolver } from './resolver/candidate-bidders.resolver';
import { ClientDependenciesResolver } from './resolver/client-dependencies.resolver';
import { ClientAndParentResolver } from './resolver/client-parent.resolver';
import { ClientTenderTemplateResolver } from './resolver/client-tender-template.resolver';
import { CreateProjectTenderTemplateResolver } from './resolver/create-project-tender-template.resolver';
import { EditProjectTenderTemplateResolver } from './resolver/edit-project-tender-template.resolver';
import { EditProjectResolver } from './resolver/edit-project.resolver';
import { InvitedBiddersResolver } from './resolver/invited-bidders.resolver';
import { OffersResolver } from './resolver/offers.resolver';
import { OlmTenderConfigResolver } from './resolver/olm-tender-config.resolver';
import { ProjectAndClientResolver } from './resolver/project-and-client.resolver';
import { ProjectResolver } from './resolver/project.resolver';
import { RejectedBiddersResolver } from './resolver/rejected-bidders.resolver';
import { SkillsResolver } from './resolver/skills.resolver';
import { TenderCreateResolver } from './resolver/tender-create.resolver';
import { TenderEditResolver } from './resolver/tender-edit.resolver';
import { TenderResolver } from './resolver/tender.resolver';
import { TendersResolver } from './resolver/tenders.resolver';
import { ApiConverterService } from './services/api-converter.service';
import { ApiService } from './services/api.service';
import { BidderService } from './services/bidder.service';
import { ClientService } from './services/client.service';
import { TenderTemplateService } from './services/tender-template.service';
import { TenderService } from './services/tender.service';
import { EditTenderResolver } from './resolver/edit-tender.resolver';
import { ActiveTendersResolver } from './resolver/active-tenders.resolver';
import { BiddingRoundResolver } from '@project-shared/modules/api/resolver/bidding-round.resolver';
import { OlmFormatterModule } from '../formatter/formatter.module';
import { OlmOauthModule } from './oauth.module';
import { TenderAttachmentResolver } from './resolver/tender-attachment.resolver';
import { IssuerLatestBidResolver } from '@project-shared/modules/api/resolver/issuer-latest-bid.resolver';

@NgModule({
  imports: [CommonModule, OlmFormatterModule, OlmOauthModule],
  declarations: [],
  exports: [],
  providers: [
    ApiService,
    ApiConverterService,
    BidderOffersResolver,
    ClientDependenciesResolver,
    ClientService,
    ClientTenderTemplateResolver,
    OffersResolver,
    ProjectResolver,
    ProjectAndClientResolver,
    ProjectResolver,
    ClientAndParentResolver,
    TenderResolver,
    TendersResolver,
    RejectedBiddersResolver,
    CandidateBiddersResolver,
    InvitedBiddersResolver,
    SkillsResolver,
    BidderClientResolver,
    BidderResolver,
    BidderAddressResolver,
    OlmTenderConfigResolver,
    EditProjectResolver,
    ProjectService,
    TenderTemplateService,
    CreateProjectTenderTemplateResolver,
    EditProjectTenderTemplateResolver,
    TenderCreateResolver,
    TenderEditResolver,
    TenderService,
    BidderService,
    EditTenderResolver,
    ActiveTendersResolver,
    BiddingRoundResolver,
    TenderAttachmentResolver,
    IssuerLatestBidResolver,
  ],
})
export class OlmApiModule {

}

export interface ParticipantLogDto {
  firstName: string;
  lastName: string;
}

export class ParticipantLog {
  firstName: string;
  lastName: string;

  constructor(json?: ParticipantLogDto) {
    if (json) {
      Object.assign(this, json);
    }
  }
}

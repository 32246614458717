import {
  ApplicationRef, ComponentRef, createComponent, Directive, ElementRef, EmbeddedViewRef, HostListener, Input, OnDestroy
} from '@angular/core';
import { OlmTooltipComponent } from './olm-tooltip.component';
import { TextService } from '@olmero/shared-core';

@Directive({ selector: '[olm-tooltip]' })
export class OlmTooltipDirective implements OnDestroy {
  @Input('olm-tooltip') tooltipText: string;
  @Input('olm-tooltip-translate') translate = true;
  @Input('olm-tooltip-inside-mat-menu') insideMatMenu = false;

  private componentRef: ComponentRef<OlmTooltipComponent> = null;

  constructor(
    private elementRef: ElementRef,
    private textService: TextService,
    private appRef: ApplicationRef
  ) { }

  @HostListener('mouseover')
  mouseover(): void {
    if (this.componentRef === null && this.tooltipText) {
      this.componentRef = createComponent(OlmTooltipComponent, { environmentInjector: this.appRef.injector });
      this.appRef.attachView(this.componentRef.hostView);
      document.body.append((<EmbeddedViewRef<any>> this.componentRef.hostView).rootNodes[0]);
      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties(): void {
    if (this.componentRef !== null) {
      this.componentRef.instance.text = this.translate ? this.textService.get(this.tooltipText) : this.tooltipText;
      const { left, right, top } = this.elementRef.nativeElement.getBoundingClientRect();
      this.componentRef.instance.parentLeft = left;
      this.componentRef.instance.parentRight = right;
      this.componentRef.instance.parentTop = top;
      this.componentRef.instance.insideMatMenu = this.insideMatMenu;
    }
  }

  @HostListener('mouseleave')
  mouseleave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}

import { NgModule } from '@angular/core';
import { DateConverterService } from './services/date-converter.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    DateConverterService,
  ],
})
export class OlmLocaleServiceModule {

}

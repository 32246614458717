import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as HttpStatus from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { LoggerService } from '@project-shared/services/logger.service';

class ResponseFilter {
  regExp: RegExp;
  httpMethod: string;
  statusCode: number;
  error?: string[];
}

@Injectable()
export class ApiHttpErrorInterceptor implements HttpInterceptor {

  private readonly responseFilter: ResponseFilter[] = [
    {
      regExp: new RegExp('projects\\/[\\d\\w]+\\/geometry$'),
      httpMethod: 'GET',
      statusCode: HttpStatus.NOT_FOUND,
    },
    {
      regExp: new RegExp('clients\\/[\\d\\w]+\\/projects$'),
      httpMethod: 'POST',
      statusCode: HttpStatus.BAD_REQUEST,
      error: ['At least one contact needs to belong to the company'],
    },
    {
      regExp: new RegExp('projects\\/[\\d\\w]+$'),
      httpMethod: 'PUT',
      statusCode: HttpStatus.BAD_REQUEST,
      error: ['At least one contact needs to belong to the company'],
    },
  ];

  constructor(
    private urlHelper: UrlHelper,
    private loggerService: LoggerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.urlHelper.isApiUrl(req.url)) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (this.shouldLogError(err, req)) {
              this.loggerService.logError(err, req);
            }
          }
          return throwError(err);
        })
      );
  }

  private shouldLogError(err: HttpErrorResponse, req: HttpRequest<any>): boolean {
    return !this.responseFilter.find(filter => this.doesErrorMatchToFilter(err, filter, req));
  }

  private doesErrorMatchToFilter(err: HttpErrorResponse, we: ResponseFilter, req: HttpRequest<any>): boolean {
    return err.url.match(we.regExp) &&
      req.method === we.httpMethod &&
      err.status === we.statusCode &&
      (we.error ? JSON.stringify(err.error) === JSON.stringify(we.error) : true);
  }
}

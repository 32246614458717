export const BREADCRUMB = {
  CLIENT: '@client@',
  PROJECT: '@project@',
  TENDER: '@tender@',
  BIDDER_TENDER: '@biddertender@',
  BIDDER: '@bidder@',
  MEMBER: '@member@',
  BIDDER_TENDER_DASHBOARD: '@bidder_tender_dashboard@',
};

export class Breadcrumb {
  readonly text: string;
  readonly link: string;
  readonly isLinkExternal: boolean;
  readonly isLinkHidden: boolean;

  constructor(text: string, link: string, isLinkExternal = false, isLinkHidden = false) {
    this.text = text;
    this.link = link;
    this.isLinkExternal = isLinkExternal;
    this.isLinkHidden = isLinkHidden;
  }
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject, forkJoin, iif, Observable, of, ReplaySubject, Subject
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Page } from '@project-shared/services/pagination/model/page.model';
import { PaginationService } from '@project-shared/services/pagination/pagination.service';
import { ListOptions } from '@project-shared/model/list-options.model';
import { AppConfigService } from '@olmero/shared-core';
import { BidderTender, BidderTenderDto } from '../model/bidder/bidder-tender.model';
import { BidderTenderApplicationTabDto, BidderTenderDeregisterTabDto } from '../../bidder/model/bidder-tender-tab.model';
import { AttachmentTranslation } from '../../bidder/model/attachment-translation.model';
import { CreateOffer } from '../../bidder/components/bidder-tender-create-offer/models/create-offer.model';
import { BidderTenderActionType } from '../model/bidder/bidder-tender-action-type.enum';
import { DashboardFilter, DashboardSettingsDto } from '../../shared/model/bidder/bidder-tender-filter.model';
import { TenderDetailTabs } from '../../bidder/model/tender-detail-tabs.enum';
import { Offer } from '@project-shared/model/offer/offer.model';
import { Bid, BidDto } from '@project-shared/model/bidding-round/bid.model';
import { FORCE_REQUEST } from '@project-shared/http-interceptors/cache/cache.interceptor';
import { TypeOfWork } from '@project-shared/model/bidder/type-of-work.model';

@Injectable({ providedIn: 'root' })
export class BidderTenderService {
  private bidderData$ = new BehaviorSubject<BidderTender>(null);
  private applicationTabActive$ = new BehaviorSubject<boolean>(false);
  private bidderQuestionsAllowed$ = new ReplaySubject<boolean>(1);
  private appliedForTender$ = new Subject<{ link: string, loadingSubject: Subject<boolean> }>();
  private deregistrationTabActive$ = new BehaviorSubject<boolean>(false);
  private supplierTabActive$ = new BehaviorSubject<boolean>(false);
  private createOfferTabActive$ = new BehaviorSubject<boolean>(false);
  private tenderDetailsTabActive$ = new BehaviorSubject<boolean>(false);
  private biddingRoundTabActive$ = new BehaviorSubject<boolean>(false);

  private readonly BIDDER_TENDER_TAB = 'bidder_tender_tab';
  private readonly BIDDER_TENDER_PAGE = 'bidder_tender_page';

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private paginationService: PaginationService) {
  }

  getAllOpenOrSelectiveTenders(listOptions: ListOptions = { size: 10 }): Observable<Page<BidderTender>> {
    return this.paginationService.queryPaginated<BidderTenderDto>(
      `${this.appConfigService.getConfig().apiUrl}/tenders/search?${listOptions.size}`, listOptions)
      .pipe(
        map(page => {
          page.results = page.results.map(value => new BidderTender(value));
          return page as Page<BidderTender>;
        })
      );
  }

  getTabLink(action: BidderTenderActionType): string {
    switch (action) {
      case BidderTenderActionType.CURRENT:
        return `${this.appConfigService.getConfig().apiUrl}/tenders/search`;
      case BidderTenderActionType.ARCHIVE:
        return `${this.appConfigService.getConfig().apiUrl}/tenders/search/archived`;
      default:
        return `${this.appConfigService.getConfig().apiUrl}/tenders/search`;
    }
  }

  getTypesOfWork(): Observable<TypeOfWork[]> {
    return this.http.get<TypeOfWork[]>(`${this.appConfigService.getConfig().apiUrl}/tenders/search-metadata/types-of-work`);
  }

  getTenderSearchSettings(): Observable<DashboardFilter> {
    return this.http.get<DashboardSettingsDto[]>(`${this.appConfigService.getConfig().apiUrl}/tenders/search/settings`)
      .pipe(map(response => new DashboardFilter(response[0])));
  }

  getAllOpenOrSelectiveTendersByType(listOptions: ListOptions = { size: 10 }, actionType: BidderTenderActionType): Observable<Page<BidderTender>> {
    const apiUrl = this.getTabLink(actionType);
    return this.paginationService.queryPaginatedTenders<BidderTenderDto>(apiUrl, listOptions)
      .pipe(
        map(page => {
          page.results = page.results.map(value => new BidderTender(value));
          return page as Page<BidderTender>;
        })
      );
  }

  getTenderDetails(tenderUid: string, forceRequest?: boolean): Observable<BidderTender> {
    const headers = new HttpHeaders({ [FORCE_REQUEST]: '1' });
    const request = forceRequest ?
      this.http.get<BidderTenderDto>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/details`, { headers }) :
      this.http.get<BidderTenderDto>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/details`);

    return request
      .pipe(
        switchMap(response => {
          return forkJoin(
            [of(response),
              iif(
                () => Boolean(response._links?.deregister_information),
                this.getDeregisterInformation(response._links?.deregister_information?.href),
                of(undefined)
              ),
              iif(
                () => Boolean(response._links?.application_information),
                this.getApplicationInformation(response._links?.application_information?.href),
                of(undefined)
              ),
              iif(
                () => Boolean(response._links?.offers),
                this.getOffersOfTender(response._links?.offers?.href),
                of(undefined)
              ),
              iif(
                () => Boolean(response._links?.latest_bid),
                this.getLatestBidsOfTender(response._links?.latest_bid?.href),
                of(undefined)
              )]
          );
        }),
        map(result => {
          let tenderData = result[0];
          if (result[1]) {
            tenderData = {
              ...tenderData,
              deregisterTab: result[1],
            };
          }
          if (result[2]) {
            tenderData = {
              ...tenderData,
              applicationTab: result[2],
            };
          }
          if (result[3]) {
            tenderData = {
              ...tenderData,
              offersTab: result[3],
            };
          }
          if (result[4]) {
            tenderData = {
              ...tenderData,
              latestBidTab: result[4],
            };
          }
          return new BidderTender(tenderData);
        })
      );
  }

  getDeregisterInformation(link: string): Observable<BidderTenderDeregisterTabDto[]> {
    return this.http.get<BidderTenderDeregisterTabDto[]>(link).pipe(
      map(
        result => result
      ),
      catchError(() => {
        return of([]);
      })
    );
  }

  getApplicationInformation(link: string): Observable<BidderTenderApplicationTabDto[]> {
    return this.http.get<BidderTenderApplicationTabDto[]>(link).pipe(
      map(
        result => result
      ),
      catchError(() => {
        return of([]);
      })
    );
  }

  createInspectionDate(tenderUid: string): Observable<void> {
    return this.http.put<void>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/create-inspection-date`, {});
  }

  createOffersInspectionDate(offers: Offer[]): Observable<void> {
    const payload = offers.map((offer: Offer) => {
      return offer.uid;
    });

    return this.http.put<void>(`${this.appConfigService.getConfig().apiUrl}/tenders/offers/update-issuer-inspection-date`, payload);
  }

  favorTender(tenderUid: string): Observable<void> {
    return this.http.put<void>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/favorite`, {});
  }

  unFavorTender(tenderUid: string): Observable<void> {
    return this.http.delete<void>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/favorite`, {});
  }

  acknowledgeChanges(tenderUid: string): Observable<void>{
    return this.http.post<void>(`${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/changes-acknowledged`, {});
  }
  
  deregister(link: string, body: { signed_off_reason: string, signed_off_text: string }): Observable<void> {
    return this.http.post<void>(link, body);
  }

  willSubmitOffer(link: string, inform_suppliers: boolean): Observable<void> {
    return this.http.post<void>(link, { inform_suppliers });
  }

  apply(link: string, application_text: string, projectReferencesIds?: number[]): Observable<void> {
    return this.http.post<void>(link, { application_text, project_references: projectReferencesIds });
  }

  setBidderTenderData(response: BidderTender): void {
    this.bidderData$.next(response);
  }

  getBidderTenderData(): Observable<BidderTender> {
    return this.bidderData$.asObservable();
  }

  setApplicationTabState(active: boolean): void {
    if (!active) {
      this.setTabsStatus(TenderDetailTabs.APPLICATION_TAB);
    }
    this.applicationTabActive$.next(active);
  }

  getApplicationTabState(): BehaviorSubject<boolean> {
    return this.applicationTabActive$;
  }

  setBiddingRoundTabState(active: boolean): void {
    if (!active) {
      this.setTabsStatus(TenderDetailTabs.BIDDING_ROUND_TAB);
    }
    this.biddingRoundTabActive$.next(active);
  }

  getBiddingRoundTabState(): BehaviorSubject<boolean> {
    return this.biddingRoundTabActive$;
  }

  setBidderQuestionsAllowed(active: boolean): void {
    this.bidderQuestionsAllowed$.next(active);
  }

  getBidderQuestionsAllowed(): Observable<boolean> {
    return this.bidderQuestionsAllowed$.asObservable();
  }

  hasApplied(): Observable<{ link: string, loadingSubject: Subject<boolean> }> {
    return this.appliedForTender$.asObservable();
  }

  applied(link: string, loadingSubject: Subject<boolean>): void {
    this.appliedForTender$.next({
      link,
      loadingSubject,
    });
  }

  cancelApplication(link: string): Observable<void> {
    return this.http.post<void>(link, undefined);
  }

  setDeregistrationTabState(active: boolean): void {
    if (active) {
      this.setTabsStatus(TenderDetailTabs.DEREGISTRATION_TAB);
    }
    this.deregistrationTabActive$.next(active);
  }

  getDeregistrationTabState(): BehaviorSubject<boolean> {
    return this.deregistrationTabActive$;
  }

  downloadAllFiles(tenderUid: string, attachmentTranslations: AttachmentTranslation[]): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/attachments`,
      { 'translations': attachmentTranslations }, {
        headers: { 'Accept': 'application/octet-stream' },
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map(blob => {
          return blob;
        }),
        catchError(error => {
          return of(error);
        })
      );
  }

  getOffersOfTender(link: string): Observable<any> {
    return this.http.get(link).pipe(
      map(
        result => result
      ),
      catchError(() => {
        return of([]);
      })
    );
  }

  public getLatestBidsOfTender(link: string): Observable<Bid> {
    return this.http.get<BidDto>(link)
      .pipe(map(json => new Bid(json)));
  }

  setSupplierTabStatus(active: boolean): void {
    if (active) {
      this.setTabsStatus(TenderDetailTabs.SUPPLIER_TAB);
    }
    this.supplierTabActive$.next(active);
  }

  getSupplierStatus(): BehaviorSubject<boolean> {
    return this.supplierTabActive$;
  }

  setMyOffersTab(active: boolean): void {
    if (active) {
      this.setTabsStatus(TenderDetailTabs.MY_OFFERS_TAB);
    }
    this.createOfferTabActive$.next(active);
  }

  getMyOffersTab(): BehaviorSubject<boolean> {
    return this.createOfferTabActive$;
  }

  deleteOfferFromTender(link, offer: CreateOffer): Observable<any> {
    return this.http.put(link, offer).pipe(
      map(
        result => result
      )
    );
  }

  setTenderDetailsTab(active: boolean): void {
    if (active) {
      this.setTabsStatus(TenderDetailTabs.DETAILS_TAB);
    }
    this.tenderDetailsTabActive$.next(active);
  }

  getTenderDetailsTab(): BehaviorSubject<boolean> {
    return this.tenderDetailsTabActive$;
  }

  setTabsStatus(tab: TenderDetailTabs): void {
    switch (tab) {
      case TenderDetailTabs.APPLICATION_TAB:
        this.tenderDetailsTabActive$.next(false);
        this.supplierTabActive$.next(false);
        this.createOfferTabActive$.next(false);
        this.deregistrationTabActive$.next(false);
        this.biddingRoundTabActive$.next(false);
        break;
      case TenderDetailTabs.DEREGISTRATION_TAB:
        this.tenderDetailsTabActive$.next(false);
        this.supplierTabActive$.next(false);
        this.createOfferTabActive$.next(false);
        this.applicationTabActive$.next(false);
        this.biddingRoundTabActive$.next(false);
        break;
      case TenderDetailTabs.DETAILS_TAB:
        this.supplierTabActive$.next(false);
        this.createOfferTabActive$.next(false);
        this.applicationTabActive$.next(false);
        this.deregistrationTabActive$.next(false);
        this.biddingRoundTabActive$.next(false);
        break;
      case TenderDetailTabs.MY_OFFERS_TAB:
        this.supplierTabActive$.next(false);
        this.tenderDetailsTabActive$.next(false);
        this.applicationTabActive$.next(false);
        this.deregistrationTabActive$.next(false);
        this.biddingRoundTabActive$.next(false);
        break;
      case TenderDetailTabs.SUPPLIER_TAB:
        this.createOfferTabActive$.next(false);
        this.tenderDetailsTabActive$.next(false);
        this.applicationTabActive$.next(false);
        this.deregistrationTabActive$.next(false);
        this.biddingRoundTabActive$.next(false);
        break;
      case TenderDetailTabs.BIDDING_ROUND_TAB:
        this.supplierTabActive$.next(false);
        this.createOfferTabActive$.next(false);
        this.tenderDetailsTabActive$.next(false);
        this.applicationTabActive$.next(false);
        this.deregistrationTabActive$.next(false);
        break;
    }
  }

  setTenderDashboardTab(tab: BidderTenderActionType): void {
    sessionStorage.setItem(this.BIDDER_TENDER_TAB, tab);
  }

  getTenderDashboardTab(): BidderTenderActionType {
    return sessionStorage.getItem(this.BIDDER_TENDER_TAB) as BidderTenderActionType;
  }

  clearTenderDashboardTab(): void {
    sessionStorage.removeItem(this.BIDDER_TENDER_TAB);
  }

  setTenderDashboardPage(type: BidderTenderActionType, page: string): void {
    sessionStorage.setItem(`${type}_${this.BIDDER_TENDER_PAGE}`, page);
  }

  getTenderDashboardPage(type: BidderTenderActionType): number {
    return Number(sessionStorage.getItem(`${type}_${this.BIDDER_TENDER_PAGE}`));
  }

  clearTenderDashboardPage(): void {
    sessionStorage.removeItem(this.BIDDER_TENDER_PAGE);
  }

  setNotInterested(link: string): Observable<void> {
    return this.http.post<void>(link, undefined);
  }
}

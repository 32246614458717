import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Tender } from '@project-shared/model/tender/tender.model';
import { Observable } from 'rxjs';
import { TenderService } from '../services/tender.service';
import { FORCE_REQUEST } from '@project-shared/http-interceptors/cache/cache.interceptor';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class TenderResolver {
  constructor(private tenderService: TenderService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Tender> {
    return this.tenderService.getTender(route.params.tenderUid, new HttpHeaders({ [FORCE_REQUEST]: '1' }));
  }
}

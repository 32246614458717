import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ApiOauthTokenService } from '@olmero/shared-core';

@Component({
  selector: 'olm-logout',
  templateUrl: './externally-triggered-logout.component.html',
})
export class ExternallyTriggeredLogoutComponent implements OnInit {

  constructor(private keycloakService: KeycloakService, private oauthTokenService: ApiOauthTokenService) { }

  /**
   * This component is called by AP post-logout to also end the session in NAP.
   */
  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        this.oauthTokenService.logout();
      } else {
        window.location.href = window.location.origin;
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@olmero/shared-core';
import { IssuerBiddingRound, IssuerBiddingRoundDto } from '@project-shared/model/bidding-round/bidding-round.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CreateBiddingRound } from '@project-shared/model/bidding-round/create-bidding-round.model';
import { Offer, OfferDto } from '@project-shared/model/offer/offer.model';
import { Bid, BidDto } from '@project-shared/model/bidding-round/bid.model';

@Injectable()
export class IssuerBiddingRoundService {

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {
  }

  createNewBiddingRound(tenderUid: string, biddingRound: CreateBiddingRound): Observable<IssuerBiddingRound> {
    return this.http.post<IssuerBiddingRoundDto>(
      this.appConfigService.getConfig().apiUrl + '/tenders/' + tenderUid + '/bidding-rounds', biddingRound)
      .pipe(map((receivedBiddingRound: IssuerBiddingRoundDto) => new IssuerBiddingRound(receivedBiddingRound)));
  }

  getLatestBiddingRoundForTender(tenderUid: string): Observable<IssuerBiddingRound> {
    return this.http.get<IssuerBiddingRoundDto>(
      `${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/bidding-rounds/latest`
    ).pipe(map(issuerBiddingRoundDto => new IssuerBiddingRound(issuerBiddingRoundDto)));
  }

  getBiddingRound(tenderUid: string, biddingRoundUuid: string): Observable<IssuerBiddingRound> {
    return this.http.get<IssuerBiddingRoundDto>(
      `${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/bidding-rounds/${biddingRoundUuid}`
    ).pipe(map(issuerBiddingRoundDto => new IssuerBiddingRound(issuerBiddingRoundDto)));
  }

  getOffersByBiddingRound(tenderUid: string, biddingRound: IssuerBiddingRound): Observable<Offer[]> {
    return this.http.get<{ offers: OfferDto[] }>(
      `${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/bidding-rounds/${biddingRound.uuid}/offers`
    ).pipe(map((biddingRoundOffers: { offers: OfferDto[] }) => biddingRoundOffers?.offers.map(dto => new Offer(dto))));
  }

  getLatestBidsOfTender(tenderUid: string, bidderUid: string): Observable<Bid> {
    return this.getLatestBiddingRoundForTender(tenderUid).pipe(
      switchMap(latestBiddingRound => {
        const url = `${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/bidders/` +
          `${bidderUid}/bidding-rounds/${latestBiddingRound.uuid}/offer-bids/latest`;

        return this.http.get<BidDto>(url).pipe(
          map(json => new Bid(json))
        );
      })
    );
  }

  getBiddingRoundsAvailableForExternalBid(tenderUid: string, offerUid: string): Observable<IssuerBiddingRound[]> {
    const url = `${this.appConfigService.getConfig().apiUrl}/tenders/${tenderUid}/offers/${offerUid}/bidding-rounds-invited`;

    return this.http.get<IssuerBiddingRoundDto[]>(url).pipe(
      map(issuerBiddingRounds =>
        issuerBiddingRounds.map(biddingRound => new IssuerBiddingRound(biddingRound))
      )
    );
  }
}

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { ApiConstraintViolation } from '@project-shared/model/api-exceptions/api-constraint-violation';
import { ApiErrorCode } from '@project-shared/model/api-exceptions/api-exception';
import { ApiInternalServerError } from '@project-shared/model/api-exceptions/api-internal-server-error';

@Injectable()
export class ApiExceptionHandlerInterceptor implements HttpInterceptor {

  constructor(private urlHelper: UrlHelper) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isHandledUrl(req.url)) {
      console.log('ignore url - handled url:', req.url);
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(response => {
        if (response instanceof Error) {
          // handle exceptions which are not caused by a response, but our code
          return throwError(response);
        }
        if (!this.isSupportingUnifiedErrorHandlingMode(response)) {
          console.log('ignore url - unified error handler:', response.url);
          return throwError(response);
        }
        console.log('type of error:', typeof response, 'value:', response);

        if (response instanceof HttpErrorResponse && !response.ok) {

          const codeField = 'code';
          const messageField = 'message';

          // 400 constraint violation
          if (response.status === 400 && codeField in response.error &&
            response.error[codeField] === ApiErrorCode.CONSTRAINT_VIOLATION) {
            return throwError(new ApiConstraintViolation(response.error[messageField], response.error['causes']));
          }

          // 500 internal server error
          if (response.status === 500 && codeField in response.error &&
            response.error[codeField] === ApiErrorCode.INTERNAL_SERVER_ERROR) {
            return throwError(new ApiInternalServerError(response.error[messageField]));
          }

        }

        return throwError(response);
      })
    );
  }

  private isHandledUrl(url: string): boolean {
    // ignore non api requests
    if (!this.urlHelper.isApiUrl(url)) {
      return false;
    }

    return true;
  }

  private isSupportingUnifiedErrorHandlingMode(response: HttpResponse<any>): boolean {
    // ignore request which does not have this property

    if (response.headers.get('error-handling-mode') === 'unified' &&
      (response.url.endsWith('/masterplans') || response.url.includes('/masterplan/') ||
        response.url.endsWith('/plans'))) {

      return true;
    }

    return false;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tag } from '@project-shared/modules/tag-list/tag.model';

@Injectable({ providedIn: 'root' })
export class TagListService {

  public tags$: Observable<Tag[]>;
  private tagSubject = new BehaviorSubject<Tag[]>([]);

  constructor() {
    this.tags$ = this.tagSubject.asObservable();
  }

  addTag(tag: Tag): void {
    const tags = this.tagSubject.getValue();
    this.tagSubject.next([...tags, tag]);
  }

  setTags(tags: Tag[]): void {
    this.tagSubject.next(tags);
  }

  clear(): void {
    this.tagSubject.next([]);
  }
}

import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CacheService {

  private cache = new Map<string, Observable<HttpEvent<any>>>();

  get(key: string): Observable<HttpEvent<any>> | undefined {
    return this.cache.get(key);
  }

  set(key: string, response: Observable<HttpEvent<any>>, ttl: number | null = null): void {
    this.cache.set(key, response);
    if (ttl) {
      timer(ttl * 1000).subscribe(() => {
        this.cache.delete(key);
      });
    }
  }

  delete(key: string): void {
    this.cache.delete(key);
  }

  clear(): void {
    this.cache.clear();
  }

}

import { Injectable } from '@angular/core';

import { TypeOfWork } from '@olmero/shared-ui';
import { TypeOfWorkService } from '@project-shared/services/type-of-work.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SkillsResolver {
  constructor(private typeOfWorkService: TypeOfWorkService) {
  }

  resolve(): Observable<TypeOfWork[]> {
    return this.typeOfWorkService.getTypesOfWorkPage()
      .pipe(
        map(result => result.results.map(typeOfWork => new TypeOfWork(typeOfWork)))
      );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { OlmFormsModule } from '@project-shared/modules/forms/forms.module';
import { OlmTestClassModule, PipesModule } from '@olmero/shared-ui';
import { OlmTextModule } from '@olmero/shared-core';
import { PhoneComponent } from './components/input/phone/phone.component';
import { ButtonLoadingSpinnerComponent } from './components/loading-spinner/button-loading-spinner.component';
import { LabelledContentComponent } from './components/text/labelled-content/labelled-content.component';
import { MatSelectModule } from '@angular/material/select';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonListComponent } from '@project-shared/modules/ui/components/skeleton-loader/skeleton-list/skeleton-list.component';
import { ProgressDialogComponent } from './components/progress-dialog/progress-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OlmTextModule,
    OlmTestClassModule,
    OlmFormsModule,
    ExtendedModule,
    FlexModule,
    MatMenuModule,
    MatSelectModule,
    NgxSkeletonLoaderModule,
    MatDialogModule,
    PipesModule,
  ],
  declarations: [
    LabelledContentComponent,
    ButtonLoadingSpinnerComponent,
    PhoneComponent,
    SkeletonLoaderComponent,
    SkeletonListComponent,
    ProgressDialogComponent,
  ],
  exports: [
    LabelledContentComponent,
    ButtonLoadingSpinnerComponent,
    PhoneComponent,
    SkeletonLoaderComponent,
    SkeletonListComponent,
  ],
})
export class OlmUiModule {
}

import { default as moment, Moment } from 'moment';
import { Attachment, AttachmentDto } from '../attachment/attachment.model';
import { BidderAccess, BidderAccessDto } from './bidder-access.model';
import { BidderClient, BidderClientDto } from './bidder-client.model';
import { BidderContact, BidderContactDto } from './bidder-contact.model';
import { Link, LinkDto } from './link.model';
import { BidderState } from '@project-shared/model/bidder/bidder-state.enum';

export enum BIDDER_INSPECTION_STATE {
  INSPECTED = 'INSPECTED',
  NOT_INSPECTED = 'NOT_INSPECTED',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export interface BidderInspection {
  date: Moment | null;
  state: BIDDER_INSPECTION_STATE;
}

export enum BidderInitialContact {
  INVITED = 'invited',
  ACCEPTED = 'accepted'
}

export interface BidderDetailDto {
  tenderUid: string;
  state: string;
  client: BidderClientDto;
  applicationText?: string;
  applicationAttachments?: AttachmentDto[];
  applicationDate?: Moment;
  applicationWithdrawnDate?: Moment;
  submissionDate?: Moment;
  signedOffDate?: Moment;
  signedOffText?: string;
  signedOffReason?: string;
  inspection?: BidderInspection;
  rejectedDate?: Moment;
  rejectionMessage?: string;
  invitation_date?: Moment;
  initialContact?: BidderInitialContact;
  offerConfirmationDate?: Moment;
  link: string;
  activeAndHasBidderModule: boolean;
  contact?: BidderContactDto;
  access: BidderAccessDto;
  links: LinkDto[];
  email_id?: string;
  is_blacklisted?: boolean;
  private?: boolean;
  reference_project_details_link?: string;
  reference_projects?: number[];
  offered_date ?: Moment;
  bid_received_date ?: Moment;
  will_offer_date ?: Moment;
  offer_rejection_date ?: Moment;
  number_of_comments: number;
}

export class BidderDetail implements BidderDetailDto {
  tenderUid: string;
  state: string;
  client: BidderClient;
  applicationText?: string;
  applicationAttachments?: Attachment[] = [];
  applicationDate?: Moment;
  applicationWithdrawnDate?: Moment;
  submissionDate?: Moment;
  signedOffDate?: Moment;
  signedOffText?: string;
  signedOffReason?: string;
  inspection?: BidderInspection;
  rejectedDate?: Moment;
  rejectionMessage?: string;
  invitation_date?: Moment;
  initialContact?: BidderInitialContact;
  offerConfirmationDate?: Moment;
  link: string;
  activeAndHasBidderModule: boolean;
  contact?: BidderContact;
  access: BidderAccess = new BidderAccess();
  links: Link[] = [];
  email_id?: string;
  is_blacklisted?: boolean;
  isLatestBiddingRoundOngoing?:boolean;
  loadingIndicator? = false;
  alreadyProcessed?: boolean;
  emailNotificationStatus?: string;
  private?: boolean;
  reference_project_details_link?: string;
  reference_projects?: number[];
  offered_date ?: Moment;
  bid_received_date ?: Moment;
  will_offer_date ?: Moment;
  offer_rejection_date ?: Moment;
  number_of_comments: number;

  constructor(data?: BidderDetailDto) {
    if (data) {
      Object.assign(this, data);
      this.deserializeDates(data);
      if (data.access) {
        this.access = new BidderAccess(data.access);
      }
      if (data.client) {
        this.client = new BidderClient(data.client);
      }
      if (data.contact) {
        this.contact = new BidderContact(data.contact);
      }
      if (data.links) {
        this.links = data.links.map(link => link);
      }
    }
  }

  get listName(): string {
    return this.client.getCompanyInfo();
  }

  isStateOffered(): boolean {
    return this.state === BidderState.OFFERED || this.state === BidderState.OFFER_REJECTED || this.state === BidderState.BIDDING_ROUND;
  }

  private deserializeDates(json: BidderDetailDto): void {
    if (json.submissionDate) {
      this.submissionDate = moment(json.submissionDate);
    }
    if (json.signedOffDate) {
      this.signedOffDate = moment(json.signedOffDate);
    }
    if (json.applicationDate) {
      this.applicationDate = moment(json.applicationDate);
    }
    if (json.inspection && json.inspection.date && !(json.inspection.date instanceof moment)) {
      this.inspection.date = moment(json.inspection.date);
    }
    if (json.rejectedDate) {
      this.rejectedDate = moment(json.rejectedDate);
    }
    if (json.invitation_date) {
      this.invitation_date = moment(json.invitation_date);
    }
    if (json.applicationWithdrawnDate) {
      this.applicationWithdrawnDate = moment(json.applicationWithdrawnDate);
    }
    if (json.offerConfirmationDate) {
      this.offerConfirmationDate = moment(json.offerConfirmationDate);
    }
    if (json.offered_date) {
      this.offered_date = moment(json.offered_date);
    }
    if (json.bid_received_date) {
      this.bid_received_date = moment(json.bid_received_date);
    }
    if (json.will_offer_date) {
      this.will_offer_date = moment(json.will_offer_date);
    }

    if (json.offer_rejection_date) {
      this.offer_rejection_date = moment(json.offer_rejection_date);
    }
  }

  get readCommentsLink(): Link {
    return this.links.find(link => link.rel === 'read-comments');
  }

  get createCommentLink(): Link {
    return this.links.find(link => link.rel === 'create-comment');
  }

  get offersInvitedWithoutBidLink(): string | undefined {
    return this.get_link('offers-invited-without-bid');
  }

  private get_link(rel: string): string | undefined {
    return this.links.find(link => link.rel === rel)?.href;
  }
}

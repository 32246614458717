import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IssuerBiddingRoundService } from '../../../../issuer/bidding-round/services/issuer-bidding-round.service';
import { Bid } from '@project-shared/model/bidding-round/bid.model';

@Injectable()
export class IssuerLatestBidResolver {
  constructor(private biddingRoundService: IssuerBiddingRoundService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Bid> {
    return this.biddingRoundService.getLatestBidsOfTender(route.params.tenderUid, route.params.bidderUid);
  }
}

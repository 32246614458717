import 'whatwg-fetch';
import { AppConfig } from '@olmero/shared-core';

declare const require;

export class LoadConfig {

  run(): Promise<any> {
    return this.getEnvironment()
      .then(environment => this.getAppConfig(environment))
      .then(appConfig => this.saveAppConfigInWindow(appConfig));
  }

  getEnvironment(): Promise<string> {
    return window.fetch('assets/environment.json')
      .then(response => response.json())
      .then(config => {
        if (!config || !config['environment']) {
          throw new Error('invalid environment');
        }
        return config['environment'];
      });
  }

  getAppConfig(environment: string): AppConfig {
    try {
      return require(`./config/app-config.${environment}.json`);
    } catch (e) {
      throw new Error(`Could not load config file for environment: ${environment}`);
    }
  }

  saveAppConfigInWindow(appConfig: AppConfig): AppConfig {
    window['tempConfigStorage'] = appConfig;
    return appConfig;
  }
}

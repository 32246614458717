import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { DateInterpretationService } from '@project-shared/modules/forms/services/date/date-interpretation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { TimeInputComponent } from '@olmero/shared-ui';
import { DateTime } from 'luxon';
import { Moment } from 'moment';

@Component({
  selector: 'olm-date-time-picker',
  standalone: true,
  imports: [CommonModule, DatepickerComponent, TimeInputComponent, ReactiveFormsModule],
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    }],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  @ViewChild('timeInputComponent') timeInputComponent: TimeInputComponent;
  @Output() onTimeBlur = new EventEmitter<void>();
  @Output() onDateBlur = new EventEmitter<any>();
  @Output() timeChanged = new EventEmitter<void>();
  @Output() dateChanged = new EventEmitter<any>();

  @Input() dateControl: UntypedFormControl;
  @Input() timeControl: UntypedFormControl;
  @Input() useAutocomplete = false;
  @Input() customClass: string;
  @Input() placeholder = '';
  @Input() translatePlaceholder = true;
  @Input() min: DateTime | Moment;
  @Input() max: DateTime | Moment;
  @Input() minErrorCodeOverride: string;
  @Input() watchValueChangesEvent = true;
  @Input() isLuxonDate = false;

  value: any;
  onTouched: any = () => {};
  onChange: (value: any) => void;
  touched = false;
  disabled = false;

  private unsubscribe = new Subject<void>();

  constructor(private dateInterpreter: DateInterpretationService) { }

  ngOnInit(): void {
    if (!this.dateControl) this.dateControl = new UntypedFormControl();
    if (!this.timeControl) this.timeControl = new UntypedFormControl('23:59');

    this.timeControl.disable();

    if (this.watchValueChangesEvent) {
      this.dateControl.valueChanges.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(value => {
        if (typeof value === 'string' && value !== '') {
          const interpretedInput = this.dateInterpreter.interpretInput(value, true);
          this.dateControl.setValue(interpretedInput);
        } else {
          this.timeControl.enable();
          if (this.timeControl.value === '') {
            this.timeControl.setValue('23:59');
          }
          this.updateDateValue();
        }
      });

      this.timeControl.valueChanges.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(() => {
        this.updateDateValue();
      });
    } else {
      if (this.dateControl.value) {
        this.timeControl.enable();
      }
    }
  }

  onDateInputChanged(value: any): void {
    this.onTouched();
    if (this.watchValueChangesEvent) return;

    if (value) {
      this.timeControl.enable();
    }

    this.dateControl.setValue(value, { emitEvent: false });
    this.dateChanged.emit(value);
  }

  onDateInputBlur(value: any): void {
    this.onTouched();
    if (this.watchValueChangesEvent) return;

    if (value) {
      this.timeControl.enable();
    }

    this.dateControl.setValue(value, { emitEvent: false });
    this.onDateBlur.emit(value);
  }

  ngAfterViewInit(): void {
    if (!this.watchValueChangesEvent) {
      const defaultTimeInputBlurEffect = this.timeInputComponent.onTimeBlur.bind(this.timeInputComponent);
      const defaultTimeInputIncreaseEffect = this.timeInputComponent.increaseTime.bind(this.timeInputComponent);
      const defaultTimeInputDecreaseEffect = this.timeInputComponent.decreaseTime.bind(this.timeInputComponent);

      this.timeInputComponent.onTimeBlur = () => {
        defaultTimeInputBlurEffect();
        this.onTimeBlur.emit();
      };

      this.timeInputComponent.increaseTime = () => {
        defaultTimeInputIncreaseEffect();
        this.timeChanged.emit();
      };

      this.timeInputComponent.decreaseTime = () => {
        defaultTimeInputDecreaseEffect();
        this.timeChanged.emit();
      };
    }
  }

  updateDateValue(): void {
    const [hour, minutes] = this.timeControl.value.split(':');
    const dateValue = this.dateControl.value;
    if (dateValue) {
      dateValue?.set({
        'hour': hour,
        'minute': minutes,
      });

      this.markAsTouched();
      this.onChange(dateValue);
    }
  }

  writeValue(value: Moment): void {
    this.value = value;
    if (this.watchValueChangesEvent && value) {
      this.timeControl.setValue(value?.format('HH:mm'), { emitEvent: false });
      this.timeControl.enable();
      this.dateControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    this.onTouched();
    if (!this.touched) {
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

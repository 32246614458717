import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenderTemplate, TenderTemplateDto } from '@project-shared/model/tender/tender-template.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@olmero/shared-core';
import { HttpRequestHelper } from '@project-shared/helpers/http/request.helper';

@Injectable()
export class TenderTemplateService {

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {
  }

  createProjectTenderTemplate(projectUid: string, tenderTemplate: TenderTemplate): Observable<TenderTemplate> {
    return this.http.post<TenderTemplateDto>(`${this.appConfigService.getConfig().apiUrl}/projects/${projectUid}/tender-templates`,
                                             tenderTemplate, HttpRequestHelper.getRequestOptionsJson()
    ).pipe(map(response => new TenderTemplate(response)));
  }

  updateClientTenderTemplate(clientUid: string, tenderTemplate: TenderTemplate): Observable<TenderTemplate> {
    return this.http.put<TenderTemplate>(`${this.appConfigService.getConfig().apiUrl}/clients/${clientUid}/tender-templates/${tenderTemplate.uid}`,
                                         tenderTemplate, HttpRequestHelper.getRequestOptionsJson()
    ).pipe(
      map(response => new TenderTemplate(response))
    );
  }

  updateProjectTenderTemplate(projectUid: string, tenderTemplate: TenderTemplate): Observable<TenderTemplate> {
    return this.http.put<TenderTemplate>(`${this.appConfigService.getConfig().apiUrl}/projects/${projectUid}/tender-templates/${tenderTemplate.uid}`,
                                         tenderTemplate, HttpRequestHelper.getRequestOptionsJson()
    ).pipe(
      map(response => new TenderTemplate(response))
    );
  }
}

<olm-static-alert
  *ngIf="toastType !== ToastType.DANGER_SUPPORT; else supportSnackbar"
  (alertClosed)="close()"
  [showFadeInAnimation]="true"
  [type]="alertType"
  [closeable]="closable"
  [text]="message">
</olm-static-alert>
<ng-template #supportSnackbar>
  <olm-alert
    (alertClosed)="close()"
    [showFadeInAnimation]="true"
    [type]="AlertType.ERROR"
    [closeable]="closable">
    <div tclass="test-snack-content" class="snack-content">
      <div class="fx-layout-column gap-16">
        <span class="typo-text-m">{{message}}</span>
        <button
          [style.width]="'fit-content'"
          class="button-secondary"
          tclass="test-support-button"
          (click)="openErrorPage()"
          i18n="@@snackbar_support_label">
          Support
        </button>
      </div>
    </div>
  </olm-alert>
</ng-template>

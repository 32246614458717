import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ClientService } from '@project-shared/modules/api/services/client.service';
import { BlacklistHandler } from '@project-shared/modules/blacklist/services/blacklist-handler';
import { BlacklistService } from '@project-shared/modules/blacklist/services/blacklist.service';
import { NoopBlacklistHandler } from '@project-shared/modules/blacklist/services/noop-blacklist-handler';
import { ProjectBlacklistHanlder } from '@project-shared/modules/blacklist/services/project-blacklist-handler';
import { CurrentUserService, TextService } from '@olmero/shared-core';
import { ProjectService } from '@project-shared/services/project.service';
import { TitleService } from '@project-shared/services/title.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breadcrumb, BREADCRUMB } from '../models/breadcrumb';
import { BreadcrumbDataService } from './breadcrumb-data.service';
import { BreadcrumbTextService } from './breadcrumb-text.service';
import { appRoutesNames } from '../../../../app.routes.names';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  breadcrumbs: Observable<Breadcrumb[]>;
  isAdmin: boolean;

  readonly = false;

  private breadcrumbsSubject: BehaviorSubject<Breadcrumb[]>;
  private noopBlacklistHandler = new NoopBlacklistHandler();

  constructor(
    private breadcrumbTextService: BreadcrumbTextService,
    private breadcrumbDataService: BreadcrumbDataService,
    private titleService: TitleService,
    private currentUserService: CurrentUserService,
    private projectService: ProjectService,
    private clientService: ClientService,
    private textService: TextService,
    private blacklistService: BlacklistService
  ) {
    this.breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
    this.breadcrumbs = this.breadcrumbsSubject.asObservable();
    this.currentUserService
      .getApiRoot()
      .pipe(map(apiRoot => apiRoot?.admin_access.can_view_clients))
      .subscribe((isAdmin: boolean) => {
        this.isAdmin = isAdmin;
      });
  }

  public buildBreadcrumb(rootRoute: ActivatedRouteSnapshot): void {
    this.breadcrumbDataService.resetData();
    const routes = this.walkDownRouteTree(rootRoute);

    const breadcrumbObservables: Observable<string>[] = routes.map(route => {
      return this.breadcrumbTextService.getBreadcrumbTextForRoute(route.route);
    });

    const titleObservable = routes.length > 0 ? this.breadcrumbTextService.getTitleTextForRoute(routes[routes.length - 1].route) : of('');
    breadcrumbObservables.push(titleObservable);

    const lastRoute = routes[routes.length - 1];

    forkJoin({
      blacklistHandler: this.getBlacklistHandler(lastRoute),
      breadcrumbs: forkJoin(breadcrumbObservables),
    }).subscribe(({ blacklistHandler, breadcrumbs }) => {
      const title = breadcrumbs.pop();
      this.titleService.setTitle(title);

      if (blacklistHandler) {
        this.blacklistService.setBlacklist(blacklistHandler);
      }

      const newBreadcrumbs = breadcrumbs.map((breadcrumbText, index) => new Breadcrumb(breadcrumbText, routes[index].url));
      this.breadcrumbsSubject.next(newBreadcrumbs);
    });
  }

  private getBlacklistHandler(lastRoute: { url: string, route: ActivatedRouteSnapshot }): Observable<BlacklistHandler> {
    if (lastRoute?.route.data && lastRoute.route.data.breadcrumb === BREADCRUMB.BIDDER) {
      const bidderUid = lastRoute.route.paramMap.get('bidderUid');
      return this.breadcrumbDataService.isBidderBlacklisted(bidderUid)
        .pipe(
          map(({ isBlacklisted }) => new ProjectBlacklistHanlder(isBlacklisted, this.textService))
        );
    }

    return of(this.noopBlacklistHandler);
  }

  private walkDownRouteTree(
    route: ActivatedRouteSnapshot,
    url = '/'
  ): { url: string, route: ActivatedRouteSnapshot }[] {
    const routes: { url: string, route: ActivatedRouteSnapshot }[] = [];

    let newUrl = url;

    if (route.url.length > 0) {
      if (url === '/') {
        newUrl += route.url[0].path;
      } else {
        newUrl += '/' + route.url[0].path;
      }
    }

    //  TODO check if this can be applied to all routes
    if (route.url.length > 0 && route.data.breadcrumb === BREADCRUMB.BIDDER_TENDER) {
      for (let i = 1; i < route.url.length; i++) {
        newUrl += '/' + route?.url[i]?.path;
      }
    }

    if (this.isAdmin && route.url.length > 0 && route.url[0].path === appRoutesNames.CLIENTS){
      route.data = route.firstChild?.params['clientUid'] ? { breadcrumb: 'pp_core_clients' } : {};
      if (route.data?.breadcrumb){
        routes.push({ url: newUrl, route });
      }
    }
    else {
      const skipRoute = !route.data.breadcrumb;

      if (!skipRoute) {
        routes.push({ url: newUrl, route });
      }
    }

    if (route.firstChild) {
      routes.push(...this.walkDownRouteTree(route.firstChild, newUrl));
    }

    return routes;
  }

}

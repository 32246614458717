import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastOpener } from './toast-opener';

@Injectable({ providedIn: 'root' })
export class ErrorToastProxyService {
  private errorToastProxySubject: Subject<ToastOpener> = new Subject<ToastOpener>();

  public throwError(snackBarOpener: ToastOpener): void {
    this.errorToastProxySubject.next(snackBarOpener);
  }

  public catchError(): Observable<ToastOpener> {
    return this.errorToastProxySubject.asObservable();
  }
}

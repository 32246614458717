import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CurrentUserService } from '@olmero/shared-core';
import { takeUntil } from 'rxjs/operators';
import { ApiRoot } from '@olmero/shared-core';
import { TextService } from '@olmero/shared-core';
import { ApiOauthTokenService } from '@olmero/shared-core';

@Component({
  selector: 'olm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  apiRoot: ApiRoot;

  private unsubscribe = new Subject<void>();
  constructor(private currentUserService: CurrentUserService, private textService: TextService, private apiOauthTokenService: ApiOauthTokenService) {}

  ngOnInit(): void {
    this.currentUserService.getApiRoot()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => this.apiRoot = data);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  logout(): void {
    this.apiOauthTokenService.logout();
  }
  openRegisterPage(): void {
    window.open(this.textService.get('private_bidder_signup_link'), '_blank');
  }
}

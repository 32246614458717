export const appRoutesNames = {
  ADMIN: 'admin',
  SIGNUP: 'signup',
  CLIENTS: 'clients',
  BIDDERS: 'bidders',
  SUPPLIERS: 'suppliers',
  ELEMENTS: 'elements',
  PROJECTS: 'projects',
  ADMIN_PROJECTS: 'projects/admin/dashboard',
  DIRECTORY: 'directory',
  CODE_403: '403',
  CODE_404: '404',
  CODE_500: '500',
  SUPLIER_REQUEST: 'supplier_request_for_tender',
};

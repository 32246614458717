import { ContactCompany, ContactCompanyDto, ContactData, ContactDataDto } from '../contact.model';

/**
 * @deprecated This interface is deprecated.
 *
 * This is used for modeling data received from Backend
 * into the standard ContactPerson and when it's sent back
 * we convert to ContactDto.
 */
export interface ContactDto {
  member_uid: string;
  member_function: string;
  member_email: string;
  member_firstname: string;
  member_lastname: string;
  member_name?: string;
  main_contact?: boolean;
  member_fax?: string;
  member_phone_number?: string;
  member_mobile_phone_number?: string;
  member_client_name?: string;
  member_client_zip?: string;
  member_client_city?: string;
  member_client_street?: string;
  member_client_department?: string;
  member_logo_url?: string;
  client?: ContactCompanyDto;
}

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use ContactPerson instead.
 */
export class Contact implements ContactDto {
  uid?: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  function?: string;
  email: string;
  fax?: string;
  mobile?: string;
  company?: ContactCompany;
  member_uid: string;
  member_function: string;
  member_email: string;
  member_firstname: string;
  member_lastname: string;
  member_name?: string;
  main_contact?: boolean;
  member_fax?: string;
  member_phone_number?: string;
  member_mobile_phone_number?: string;
  member_client_name?: string;
  member_client_zip?: string;
  member_client_city?: string;
  member_client_street?: string;
  member_client_department?: string;
  member_logo_url?: string;
  client?: ContactCompany;

  constructor(data?: ContactDto) {
    if (data) {
      Object.assign(this, data);
      const formatted: ContactDataDto = {
        uid: data.member_uid,
        first_name: data.member_firstname,
        last_name: data.member_lastname,
        email: data.member_email,
        phone_number: data.member_phone_number,
        mobile: data.member_mobile_phone_number,
        function: data.member_function,
        fax: data.member_fax,
        company: data.client,
      };
      Object.assign(this, new ContactData(formatted));
    }
  }

  public getDisplayName(): string {
    return [this.first_name, this.last_name].filter(Boolean).join(' ');
  }
}

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use ContactPersonDTO instead.
 */
export class TenderContactDataDto {
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  fax?: string;
  function?: string;
  mobileNumber?: string;
  department?: string;
  company?: ContactCompanyDto;
}

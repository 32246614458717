export enum ProjectOption {
  publisher = 'publisher'
}

export interface OptionDto {
  value: ProjectOption;
}

export class Option implements OptionDto {
  value: ProjectOption;

  constructor(data?: OptionDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

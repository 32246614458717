import { TextService } from '@olmero/shared-core';
import { ToastType } from '@project-shared/modules/toast/model/toast-type.enum';
import { ToastService } from '@project-shared/modules/toast/services/toast.service';
import { ToastOpener } from './toast-opener';

export class ServerErrorToastOpener implements ToastOpener {

  open(textService: TextService, toastService: ToastService): void {
    toastService.open(textService.get('pp_core_global_unknown_error_snackbar'), ToastType.DANGER_SUPPORT);
  }
}

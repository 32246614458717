import { Component, Input, OnInit } from '@angular/core';
import {
  SkeletonAnimationType,
  SkeletonType
} from '@project-shared/modules/ui/components/skeleton-loader/skeleton-loader.enum';
import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types';

@Component({
  selector: 'olm-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent implements OnInit {

  private readonly DEFAULT_LINE_HEIGHT = 32;
  private readonly DEFAULT_SQUARE_SIZE = 140;
  private readonly DEFAULT_SKELETON_COLOR = '#F7F7F7';
  private readonly DARK_SKELETON_COLOR = '#f2f2f2';

  @Input() type: SkeletonType = SkeletonType.LINE;
  @Input() objectHeight: number;
  @Input() objectWidth: number;
  @Input() quantity = 1;
  @Input() gap = 20;
  @Input() animationType = SkeletonAnimationType.PROGRESS;
  @Input() setParentHeight = false;
  @Input() dark = false;
  public theme: NgxSkeletonLoaderConfigTheme;

  ngOnInit(): void {
    this.objectWidth = this.objectWidth ? this.objectWidth : this.objectHeight;
    this.theme = this.getSkeletonStyles();
  }

  private getSkeletonStyles(): NgxSkeletonLoaderConfigTheme {
    const theme: NgxSkeletonLoaderConfigTheme = {};
    theme['border'] = `1px solid ${this.DEFAULT_SKELETON_COLOR}`;
    theme['background'] = this.dark ? this.DARK_SKELETON_COLOR : this.DEFAULT_SKELETON_COLOR;
    theme['box-shadow'] = 'none';
    theme['border-radius'] = '0';
    theme['margin-bottom'] = '0';

    switch (this.type) {
      case SkeletonType.LINE:
        theme['height.px'] = this.objectHeight || this.DEFAULT_LINE_HEIGHT;
        break;
      case SkeletonType.CIRCLE:
        theme['height.px'] = this.objectHeight || this.DEFAULT_SQUARE_SIZE;
        theme['width.px'] = this.objectHeight || this.DEFAULT_SQUARE_SIZE;
        theme['border-radius'] = '100%';
        break;
      case SkeletonType.SQUARE:
        if (!this.setParentHeight) {
          theme['height.px'] = this.objectHeight || this.DEFAULT_SQUARE_SIZE;
        } else {
          theme['height.%'] = '100';
        }
        theme['width.px'] = this.objectWidth || this.DEFAULT_SQUARE_SIZE;
        break;
    }

    return theme;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Offer } from '@project-shared/model/offer/offer.model';
import { ApiService } from '../services/api.service';

@Injectable()
export class BidderOffersResolver {
  constructor(private api: ApiService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Offer[]> {
    return this.api.getOffersForTenderUidAndBidderUid(route.params.tenderUid, route.params.bidderUid);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UrlHelper } from '@project-shared/helpers/http/url.helper';
import { ApiService } from '../services/api.service';
import { TenderCreateData } from './model/tender-create.model';
import { ProjectAndClientResolver } from './project-and-client.resolver';
import { SkillsResolver } from './skills.resolver';

@Injectable()
export class TenderCreateResolver {

  constructor(
    private projectAndClientResolver: ProjectAndClientResolver,
    private api: ApiService,
    private skillsResolver: SkillsResolver,
    private router: Router,
    private urlHelper: UrlHelper
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TenderCreateData> {
    return this.projectAndClientResolver.resolve(route)
      .pipe(
        switchMap(projectAndClient => {
          if (!projectAndClient.project.template || !projectAndClient.project.projectDetails.access.can_create_tender) {
            this.router.navigate([this.urlHelper.calculateParentUrl(route)]);
            return EMPTY;
          }
          return forkJoin([
            this.skillsResolver.resolve(),
            this.api.getTenderConfig(projectAndClient.client.details.uid),
          ]
          ).pipe(
            map(result => new TenderCreateData(projectAndClient, result[1], result[0]))
          );
        })
      );
  }
}

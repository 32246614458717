export interface DeductionDto {
  name: string;
  percent?: number | undefined | null;
  absolute?: number | undefined | null;
}

export class Deduction implements DeductionDto {
  name = '';
  percent?: number | undefined;
  absolute?: number | undefined;

  constructor(data?: DeductionDto) {
    if (data) {
      this.name = data.name ? data.name : '';

      if (!data.percent && !data.absolute) {
        if (data.percent === 0 && data.absolute === 0) {
          this.percent = 0;
          return;
        }

        if (data.percent === 0) {
          this.percent = 0;
          return;
        }

        if (data.absolute === 0) {
          this.absolute = 0;
          return;
        }
        return;
      }

      if (!data.percent && data.absolute) {
        this.absolute = data.absolute;
        return;
      }

      if (data.percent && !data.absolute) {
        this.percent = data.percent;
        return;
      }

      if (data.percent === 0 && data.absolute > 0) {
        this.absolute = data.absolute;
      } else if (data.percent > 0 && data.absolute === 0) {
        this.percent = data.percent;
      } else if (data.percent > 0 && data.absolute > 0) {
        this.percent = data.percent;
      }
    }
  }
}


